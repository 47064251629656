import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from '@material-ui/core';
import { CheckCircleRounded, Close } from '@material-ui/icons';
import { Autocomplete } from '@mui/material';
import clsx from 'clsx';
import { convertLinkManualToNFTParams } from 'common/helper';
import AddIcon from 'icons/AddIcon';
import ArrowDownIcon from 'icons/ArrowDownIcon';
import BaseMenuItem from 'icons/BaseMenuItem';
import BlastMenuItem from 'icons/BlastMenuItem';
import BNBSymbol from 'icons/BNBSymbol';
import BtcMenuItem from 'icons/BtcMenuItem';
import CloseIcon from 'icons/CloseIcon';
import DeleteIcon from 'icons/DeleteIcon';
import EthIcon from 'icons/EthIcon';
import EthWhiteIcon from 'icons/EthWhiteIcon';
import LinkBoldIcon from 'icons/LinkBoldIcon';
import MaticMenuItem from 'icons/MaticMenuItem';
import OtherMenuItem from 'icons/OtherMenuItem';
import SOLMenuItem from 'icons/SOLMenuItem';
import UploadIcon from 'icons/UploadIcon';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import nftService from 'services/nfts';
import { getNFTPreviewManualAction } from 'store/actions/nftActions';
import { modeTheme } from 'store/selectors';
import { validateAddNFTManual } from 'utils/validateAddNFT';
import * as yup from 'yup';
import { useStyle } from './style';
import ArbitrumMenuItem from 'icons/ArbitrumMenuItem';
import ApeMenuItem from 'icons/ApeMenuItem';

type AddNFTManualForm = {
  originalURL: string;
  collectionName: string;
  nftName: string;
  chain: string;
  price: string;
  file: any;
};

type AddNFTManualProps = {
  parseLinkPress?: (press: boolean) => void;
  setIsManual: (value: boolean) => void;
};

const AddNFTManual = (props: AddNFTManualProps) => {
  const { parseLinkPress, setIsManual } = props;
  //#region State and hooks
  const classes = useStyle();
  const mode = useSelector(modeTheme);
  const theme = useTheme();
  const dispatch = useDispatch();

  const CHAIN_LIST = [
    {
      label: 'ETH',
      value: 'ETH',
      icon:
        mode === 'dark' ? (
          <EthWhiteIcon width={12} height={12} />
        ) : (
          <EthIcon width={12} height={12} />
        ),
    },
    {
      label: 'SOL',
      value: 'SOL',
      icon: <SOLMenuItem width={12} height={12} />,
    },
    {
      label: 'BTC',
      value: 'BTC',
      icon: <BtcMenuItem width={12} height={12} />,
    },
    {
      label: 'BASE',
      value: 'BASE',
      icon: <BaseMenuItem width={12} height={12} />,
    },
    {
      label: 'POL',
      value: 'POL',
      icon: <MaticMenuItem width={12} height={12} />,
    },
    {
      label: 'BNB',
      value: 'BNB',
      icon: <BNBSymbol width={12} height={12} />,
    },
    {
      label: 'ARB',
      value: 'ARB',
      icon: <ArbitrumMenuItem width={12} height={12} />,
    },
    {
      label: 'APE',
      value: 'APE',
      icon: <ApeMenuItem width={12} height={12} />,
    },

    {
      label: 'BLAST',
      value: 'BLAST',
      icon: <BlastMenuItem width={12} height={12} />,
    },
    {
      label: 'OTHER',
      value: 'OTHER',
      icon: <OtherMenuItem width={12} height={12} />,
    },
  ];

  const schema = yup.object().shape({
    originalURL: yup.string().required('Please enter URL.'),
    collectionName: yup.string().required('Please enter collection name.'),
    nftName: yup.string().required('Please enter NFT name.'),
    chain: yup.string().required('Please select chain.'),
    price: yup.string().required('Please enter price.'),
    file: yup.mixed().required('Please select image.'),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    watch,
    setValue,
    clearErrors,
  } = useForm<AddNFTManualForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      originalURL: '',
      collectionName: '',
      nftName: '',
      chain: '',
      price: '',
      file: null,
    },
    mode: 'onChange',
  });

  const [open, setOpen] = useState(false);
  const inputImgNftRef = useRef<HTMLInputElement>(null);
  const [imgNft, setImgNft] = useState<File | null>(null);
  const [collectionList, setCollectionList] = useState<string[]>([]);
  const [isValidUrl, setIsValidUrl] = useState(false);

  const imgNftUrl = imgNft ? URL.createObjectURL(imgNft) : '';
  const originalURLWatch = watch('originalURL');
  const chainWatch = watch('chain');
  const EndAdornmentUrl = useMemo(() => {
    if (!originalURLWatch) return null;
    const handleClearInput = () => {
      setValue('originalURL', '');
    };
    return isValidUrl ? (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingInline: '4px',
          color: theme.colors.primary,
        }}
      >
        <CheckCircleRounded />
      </div>
    ) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingInline: '4px',
          color: theme.colors.crimson,
        }}
        onClick={handleClearInput}
      >
        <Close />
      </div>
    );
  }, [isValidUrl, theme.colors, setValue, originalURLWatch]);
  //#endregion

  //#region Logic
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenSelectImage = () => {
    inputImgNftRef.current?.click();
  };

  const handleChangeNftImg = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImgNft(e.target.files[0]);
      setValue('file', e.target.files[0]);
      clearErrors('file');
    }
  };

  const handleRemoveNftImg = () => {
    inputImgNftRef.current?.value && (inputImgNftRef.current.value = '');
    setImgNft(null);
  };

  const handleAddNFTManual = async (data: AddNFTManualForm) => {
    const { originalURL, collectionName, nftName, chain, price } = data;
    if (!validateAddNFTManual(originalURL)) {
      setError('originalURL', {
        type: 'manual',
        message: 'Domain is not supported.',
      });
      return;
    }
    const paramFromOriginalURL = convertLinkManualToNFTParams(originalURL);
    if (!paramFromOriginalURL) return;
    if (!imgNft) {
      setError('file', {
        type: 'manual',
        message: 'Please select image.',
      });
      return;
    }

    const body = {
      file: imgNft,
      originalURL,
      collectionName,
      nftName,
      marketplace: paramFromOriginalURL.marketplace,
      chain,
      price,
      isProcessPayment: false,
      isCloneFromUser: false,
    };

    dispatch(getNFTPreviewManualAction(body));
    parseLinkPress?.(true);
    setIsManual(true);
    setOpen(false);
  };
  //#endregion

  //#region Effect
  useEffect(() => {
    if (open) {
      nftService.getColectionsName().then((data) => {
        setCollectionList(data);
      });
    }
  }, [open]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.originalURL) {
        validateAddNFTManual(value.originalURL)
          ? setIsValidUrl(true)
          : setIsValidUrl(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  //#endregion

  //#region Template
  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<AddIcon color={theme.colors.primary} />}
        className={classes.addBtn}
        onClick={handleOpenModal}
      >
        Or add nft manually
      </Button>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: classes.dialogPaper,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <div className={classes.dialogTitleWrapper}>
            <h2 className={classes.dialogTitle}>Add NFT manually</h2>
            <div className={classes.dialogCloseBtn} onClick={handleCloseModal}>
              <CloseIcon />
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <form
            className={classes.dialogContent}
            onSubmit={handleSubmit(handleAddNFTManual)}
          >
            {imgNftUrl && (
              <div className={classes.nftImgWrapper}>
                <img src={imgNftUrl} alt="NFT" className={classes.nftImg} />
                <div className={classes.nftImgOverlay}></div>
                <button
                  className={classes.removeNftImgBtn}
                  onClick={handleRemoveNftImg}
                >
                  <DeleteIcon
                    width={40}
                    height={40}
                    color={theme.colors.white}
                  />
                </button>
              </div>
            )}
            {!imgNftUrl && (
              <div
                className={clsx(classes.uploadWrapperDesktop, {
                  [classes.uploadWrapperError]: !!errors.file,
                })}
              >
                <UploadIcon />
                <p className={classes.uploadDescription}>
                  Drag & Drop to Upload File
                  <br />
                  or
                </p>
                <Button
                  variant="contained"
                  className={classes.uploadBtn}
                  onClick={handleOpenSelectImage}
                >
                  Browse file
                </Button>
                {!!errors.file && (
                  <p className={classes.errorMessageInput}>
                    {errors?.file?.message?.toString()}
                  </p>
                )}
              </div>
            )}

            {!imgNftUrl && (
              <div
                className={clsx(classes.uploadWrapperMobile, {
                  [classes.uploadWrapperError]: !!errors.file,
                })}
              >
                <span>Upload file</span>
                <Button
                  variant="contained"
                  className={classes.uploadBtn}
                  onClick={handleOpenSelectImage}
                >
                  Browse file
                </Button>
              </div>
            )}
            <input
              style={{
                display: 'none',
              }}
              ref={inputImgNftRef}
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              alt="NFT-image"
              onChange={handleChangeNftImg}
            />

            <div className={classes.formWrapper}>
              <Controller
                name="originalURL"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    size="small"
                    placeholder="Enter URL"
                    autoComplete="off"
                    className={classes.inputNFTField}
                    error={!!errors.originalURL}
                    helperText={errors.originalURL?.message}
                    InputProps={{
                      startAdornment: (
                        <div
                          style={{
                            marginRight: '8px',
                          }}
                        >
                          <LinkBoldIcon />
                        </div>
                      ),
                      endAdornment: EndAdornmentUrl,
                    }}
                  />
                )}
              />

              <Controller
                name="collectionName"
                control={control}
                render={({ field }) => {
                  const { value, onChange } = field;
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gridColumn: 'span 2 / span 2',
                      }}
                    >
                      <Autocomplete
                        value={
                          value
                            ? collectionList.find((item) => item === value) ??
                              null
                            : null
                        }
                        freeSolo
                        onChange={(e, data) => onChange(data ?? null)}
                        id="combo-box-demo"
                        options={collectionList}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            className={classes.autocompleteNFTItem}
                            key={option}
                          >
                            <span>{option}</span>
                          </li>
                        )}
                        isOptionEqualToValue={(option, value) =>
                          option.valueOf === value.valueOf
                        }
                        className={clsx(classes.autocompleteNFTField, {
                          [classes.autocompleteNFTFieldError]:
                            !!errors.collectionName,
                        })}
                        classes={{
                          paper: classes.autocompleteNFTPaper,
                          popupIndicator: classes.autocompleteNFTPopupIndicator,
                          endAdornment: classes.autocompleteNFTEndAdornment,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            placeholder="Collection Name"
                            className={classes.inputNFTCollectionNameField}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'off', // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                      {errors.collectionName && (
                        <div className={classes.errorMessageInput}>
                          {errors.collectionName?.message}
                        </div>
                      )}
                    </div>
                  );
                }}
              />

              <Controller
                name="nftName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    size="small"
                    placeholder="NFT Name"
                    className={classes.inputNFTField}
                    error={!!errors.nftName}
                    helperText={errors.nftName?.message}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              <Controller
                name="price"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    variant="outlined"
                    size="small"
                    placeholder="Price"
                    className={clsx(
                      classes.inputNFTField,
                      classes.inputNFTPriceField,
                    )}
                    inputProps={{
                      autoComplete: 'off', // disable autocomplete and autofill
                    }}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                  />
                )}
              />

              <Controller
                name="chain"
                control={control}
                render={({ field }) => (
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Select
                      {...field}
                      labelId="label"
                      id="select"
                      variant="outlined"
                      placeholder="Select chain"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: {
                          list: classes.inputNFTSelectField,
                        },
                      }}
                      IconComponent={(props) => {
                        return (
                          <div
                            className={clsx(
                              props.className,
                              classes.inputSelectChainIconWrapper,
                            )}
                          >
                            <ArrowDownIcon width={16} height={8} />
                          </div>
                        );
                      }}
                      className={clsx(
                        classes.inputNFTField,
                        classes.inputSelectChainField,
                      )}
                      classes={{
                        icon: classes.inputSelectChainIcon,
                      }}
                      error={!!errors.chain}
                    >
                      {CHAIN_LIST.map((chain) => (
                        <MenuItem
                          className={classes.inputNFTSelectItem}
                          value={chain.value}
                        >
                          <div className={classes.inputSelectChainMenuItem}>
                            <span>{chain.icon}</span>
                            <span>{chain.label}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                    {!chainWatch && (
                      <div className={classes.inputSelectChainPlaceholder}>
                        Chain
                      </div>
                    )}
                    {errors.chain && (
                      <div className={classes.errorMessageInput}>
                        {errors.chain?.message}
                      </div>
                    )}
                  </div>
                )}
              />

              <Button
                type="submit"
                variant="contained"
                className={classes.submitBtn}
              >
                Submit
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddNFTManual;

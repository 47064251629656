import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return createStyles({
    root: {
      cursor: 'pointer',
      verticalAlign: 'top',
      backgroundColor: theme.colors.white,
      '&:hover': {
        backgroundColor: isDarkMode
          ? theme.colors.backgroundDark
          : theme.colors.backgroundLight,
      },
      '& .MuiTableContainer-root': {
        overflowX: 'hidden',
      },
    },
    noSelect: {
      cursor: 'default',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
    },
    expandRow: {
      cursor: 'default',
      backgroundColor: theme.colors.white,

      '& > td': {
        padding: 0,
      },
      '&:last-child': {
        '& > td': {
          borderBottom: 'none',
        },
      },
    },
    hiddenIcon: {
      color: 'transparent',
      visibility: 'hidden',
    },
    isExpand: {},
    buttonExpand: {
      padding: 0,
    },
    loading: {
      top: 0,
      bottom: 0,
      right: 10,
      margin: 'auto',
      position: 'absolute',
    },
  });
};

export const useStyles = makeStyles(styles);

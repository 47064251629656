import { Box, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import IncomingTransaction from './IncomingTransaction';
import WalletBlocker from './WalletBlocker';
import Volume from './Volume';
import RecentSales from './RecentSales';
import TwelveBlocksExtra from './TwelveBlocksExtra';
import ChangePricePerDay from './ChangePricePerDay';
import Categories from './Categories';
import SponsorAds from './SponsorAds';

interface IData {}

enum IAdminTable {
  INCOMING_TRANSACTION = 'Incoming Transaction',
  WALLET_BLOCKER = 'Wallet Blocker',
  SQUARE_VOLUME = 'Square Volume',
  RECENT_SALES = 'Recent Sales',
  TWELVE_BLOCKS = '12 blocks',
  CATEGORIES = 'Categories',
  SPONSOR_ADS = 'Sponsor ads',
  CHANGE_PRICE_PER_DAY = 'Change price per day',
}

const AdminPage = (props: IData) => {
  const [type, setType] = useState<IAdminTable>(
    IAdminTable.INCOMING_TRANSACTION,
  );

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setType(event.target.value as IAdminTable);
  };

  const renderComponent = useMemo(() => {
    switch (type) {
      case IAdminTable.WALLET_BLOCKER:
        return <WalletBlocker />;
      case IAdminTable.SQUARE_VOLUME:
        return <Volume />;
      case IAdminTable.RECENT_SALES:
        return <RecentSales />;
      case IAdminTable.INCOMING_TRANSACTION:
        return <IncomingTransaction />;
      case IAdminTable.CATEGORIES:
        return <Categories />;
      case IAdminTable.CHANGE_PRICE_PER_DAY:
        return <ChangePricePerDay />;
      case IAdminTable.SPONSOR_ADS:
        return <SponsorAds />;
      default:
        return <TwelveBlocksExtra />;
    }
  }, [type]);

  return (
    <Box className={classes.main}>
      <Box className={classes.select}>
        <Select
          value={type}
          onChange={handleChange}
          classes={{
            root: classes.root,
          }}
          MenuProps={{
            classes: { paper: classes.dropdownStyle },
            variant: 'menu',
          }}
          disableUnderline
        >
          {Object.values(IAdminTable).map((v, i) => (
            <MenuItem key={i} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        mt={8}
        width={
          type !== IAdminTable.SPONSOR_ADS && type !== IAdminTable.TWELVE_BLOCKS
            ? '75%'
            : null
        }
      >
        {renderComponent}
      </Box>
    </Box>
  );
};

export default AdminPage;

const useStyles = makeStyles((theme) => ({
  main: {
    padding: '20px 20px',
  },
  select: {
    width: '100%',
    borderBottom: '1px solid #1b1464',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  root: {
    width: 180,
  },
  dropdownStyle: {
    backgroundColor: '#FFFFFF',
  },
}));

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  main: {},
  wrapper: {
    marginTop: 20,
    padding: 32,
  },
  wrapperItemList: {
    display: 'flex',
    borderRight: '1px solid',
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    '&>p:not(:last-child)': {
      borderRight: '1px solid',
    },
    '&>div:not(:last-child)': {
      borderRight: '1px solid',
    },
  },
  wrapperItem: {
    display: 'flex',
    borderRight: '1px solid',
    borderLeft: '1px solid',
    borderBottom: '1px solid',
    '&>div:not(:last-child)': {
      borderRight: '1px solid',
    },
  },
  itemList: {
    width: 'calc(100%/8)',
    minHeight: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    wordBreak: 'break-all',
    margin: 0,
    padding: '0px 12px',
  },
  item: {
    width: '20%',
    padding: '20px 50px',
    minHeight: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapperAddRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    '& button': {
      minWidth: '100px',
    },
  },
  header: {
    display: 'flex',
    border: '1px solid black',
    '&>p': {
      width: '20%',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center',
      padding: '6px 0px',
    },
    '&>p:not(:last-child)': {
      borderRight: '1px solid',
    },
  },
  headerList: {
    display: 'flex',
    border: '1px solid black',
    '&>p': {
      width: 'calc(100% / 7)',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      textAlign: 'center',
      padding: '6px 0px',
    },
    '&>p:not(:last-child)': {
      borderRight: '1px solid',
    },
  },
  top: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonAdd: {
    border: '1px solid black',
    borderRadius: '20px',
  },
  confirmButton: {
    '& button': {
      border: '1px solid black',
      borderRadius: '20px',
    },
    marginRight: 32,
    marginBottom: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapperDialog: {
    '& .MuiPaper-root': {
      backgroundColor: 'white !important',
    },
  },
  addRowButton: {
    border: '1px solid',
    borderRadius: '20px',
  },
  imgFile: {
    width: 120,
    height: 120,
  },
  deleteIcon: {
    cursor: 'pointer',
    paddingRight: 16,
    borderRight: '1px solid darkgrey',
  },
  editIcon: {
    cursor: 'pointer',
    paddingLeft: 16,
  },
}));

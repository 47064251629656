import React, { ElementType, useCallback, useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Modal,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import CommonDialog from 'components/common/CommonDialog';
import Menu from 'components/Menu';
import MenuModal from 'components/Menu/MenuModal';
import TopBar from 'components/TopBar';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { getDialogState, scrollState } from 'store/selectors';
import clsx from 'clsx';
import AddNFTFooter from 'components/AddNFT/AddNFTFooter';
import SearchModal from 'components/Menu/SearchModal/SearchModal';

export interface DashboardLayoutProps extends RouteProps {
  RenderComponent: ElementType;
  excludeFooter?: boolean;
}

interface LayoutProps {
  routeProps: RouteComponentProps;
  RenderComponent: ElementType;
  path: string;
}

const Layout = (props: LayoutProps) => {
  const { routeProps, path, RenderComponent } = props;
  const dialog = useSelector(getDialogState);
  const [isDisplaySubmenu, setIsDisplaySubmenu] = useState(false);
  const [isDeadzone, setIsDeadzone] = useState(false);
  const isScrolling = useSelector(scrollState);
  const [isDisplaySearchModal, setIsDisplaySearchModal] = useState(false);

  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md')) && !isDesktop;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles({
    isScrolling,
    isDesktop,
    isTablet,
    isMobile,
  });

  const toggleSubmenu = () => {
    setIsDisplaySubmenu(!isDisplaySubmenu);
  };

  const toggleDeadzone = () => {
    setIsDeadzone(!isDeadzone);
  };

  const onSearchModal = useCallback(() => {
    setIsDisplaySearchModal(true);
  }, [setIsDisplaySearchModal]);

  const offSearchModal = useCallback(() => {
    setIsDisplaySearchModal(false);
  }, [setIsDisplaySearchModal]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [path]);

  return (
    <Box className={clsx(classes.container)}>
      <TopBar isDeadzone={isDeadzone} />
      <Menu
        displayModal={toggleSubmenu}
        isDeadzone={isDeadzone}
        onSearchModal={onSearchModal}
        offSearchModal={offSearchModal}
      />
      <div className={classes.main}>
        <RenderComponent
          {...routeProps}
          isDeadzone={isDeadzone}
          toggleDeadzone={toggleDeadzone}
        />
        <AddNFTFooter />
      </div>
      <CommonDialog>
        <dialog.component />
      </CommonDialog>
      <Modal open={isDisplaySubmenu}>
        <MenuModal onClose={toggleSubmenu} />
      </Modal>
      <Modal open={isDisplaySearchModal}>
        <SearchModal onClose={offSearchModal} />
      </Modal>
    </Box>
  );
};

const StaticLayout = ({ RenderComponent, ...rest }: DashboardLayoutProps) => {
  const render = useCallback(
    (routeProps: RouteComponentProps) => {
      return (
        <Layout
          routeProps={routeProps}
          RenderComponent={RenderComponent}
          path={rest.path as string}
        />
      );
    },
    [RenderComponent, rest.path],
  );

  return <Route {...rest} render={render} />;
};

export default StaticLayout;

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
  },
  main: {},
}));

import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      height: 60,
      zIndex: 3,
      backgroundColor: ({ isDeadzone }: { isDeadzone?: boolean }) => {
        if (isDeadzone) return theme.colors.deadzone;
        if (isDarkMode) return theme.colors.backgroundDark;
        else return theme.colors.backgroundLight;
      },
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: isDarkMode
        ? `${theme.colors.blackOlive}`
        : `${theme.colors.primaryDark}${TRANSPARENCY.T30}`,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& button': {
        minWidth: 0,
        marginLeft: 16,
        [theme.breakpoints.down('md')]: {
          marginLeft: 0,
        },
        '& p': {
          fontSize: 16,
          fontWeight: 700,
        },
      },
      '& p': {
        fontSize: 16,
        fontWeight: 700,
      },
    },
    backBtn: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
    },
  };
});

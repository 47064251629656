import React from 'react';
import { useStyle } from './styles';

interface TermContentProps {
  title: string;
  content: string;
  className?: string;
}
function LitepaperContent(props: TermContentProps) {
  const classes = useStyle();
  const { content, title } = props;
  return (
    <div className={classes.container}>
      <p className={classes.title}>{title}</p>

      <div
        className={classes.content}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
}

export default LitepaperContent;

import AppRoutes from 'components/Routes/AppRoute';
import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AdminRoutes from 'components/Routes/AdminRoute';
import StaticRoutes from 'components/Routes/StaticRoute';
import ScrollToTop from 'components/ScrollToTop';
import { clusterApiUrl } from '@solana/web3.js';
import {
  WalletProvider,
  ConnectionProvider,
} from '@solana/wallet-adapter-react';
import { TipLinkWalletAdapter } from '@tiplink/wallet-adapter';
import {
  WalletModalProvider,
  TipLinkWalletAutoConnectV2,
} from '@tiplink/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

require('@tiplink/wallet-adapter-react-ui/styles.css');

const getLibrary = (provider: any) => {
  return new Web3Provider(provider);
};

function App() {
  const endpoint = useMemo(
    () => clusterApiUrl(WalletAdapterNetwork.Devnet),
    [],
  );

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new TipLinkWalletAdapter({
        title: 'Mintedgem',
        clientId: '94b2ecce-85a4-4a6a-ba82-74b95ec4e9d4',
        theme: 'dark',
      }),
    ],
    [],
  );
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>
            <TipLinkWalletAutoConnectV2 isReady query={{}}>
              <BrowserRouter>
                <ScrollToTop />
                <AppRoutes />
                <AdminRoutes />
                <StaticRoutes />
              </BrowserRouter>
            </TipLinkWalletAutoConnectV2>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </Web3ReactProvider>
  );
}

export default App;

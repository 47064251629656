import { createTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { PropsWithChildren } from 'react';
import { getPalette } from './palette';
import { getTypography } from './typography';
import breakpoints from './breakpoints';
import colors from './colors';
import { ThemeColors } from '../types/style';
import { useSelector } from 'react-redux';
import { modeTheme } from 'store/selectors';
import { PaletteOptions } from '@mui/material';
import getOverrides from './overrides';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    colors: ThemeColors;
  }
  interface ThemeOptions {
    colors: ThemeColors;
  }
}

const MaterialThemeProvider = ({ children }: PropsWithChildren) => {
  const mode = useSelector(modeTheme);
  const theme = createTheme({
    colors,
    palette: getPalette(mode) as PaletteOptions,
    typography: getTypography(mode),
    breakpoints,
    overrides: getOverrides(mode),
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default MaterialThemeProvider;

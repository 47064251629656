export const contents = [
  {
    title: '1. Introduction ',
    content: `
      <p>1001 Squares of NFT is a website that allows users to display and share NFT listing links from third-party marketplaces. It provides interaction with the 1001 Squares of NFT smart contract that enables users to pay a selected amount to display and link an NFT on the website for a selected amount of time. During an NFT&rsquo;s time displayed, henceforth referred to as active time, NFTs are displayed by default in descending order of ad price per day (calculated by user&rsquo;s Amount paid &divide; user&rsquo;s selected Time in days).</p>
      <p>In addition to accepting payments in multiple cryptocurrencies (Solana, Polygon, Avalanche, BNB Smart Chain, Ethereum, and Fantom), the platform also accepts payments in the native token, $DONE, a token on the Solana blockchain.</p>
      <p>1001 Squares of NFT is not a wallet provider, NFT marketplace, or exchange. 1001 Squares of NFT provides a service that helps users share and discover NFT-related information sourced from third party NFT marketplace websites. The assets interactable in these third party marketplace websites are available on public blockchains and we do not have custody or control over the assets, NFTs, blockchains, or third-party websites and experience. Our Service does not include the execution of any purchases, transfers, or sales of NFTs.</p>
    `,
  },
  {
    title: '2. Modification of this Agreement ',
    content: `
      <p>We reserve the right to modify this Agreement at any time and in our sole discretion. Modifications to this Agreement become effective when the date at the top of the Agreement is updated.</p>
      <p>You can find the latest terms of service at:</p>
      <p>https://www.1001squaresofnft.com/terms-of-service</p>
    `,
  },
  {
    title: '3. Accessing the Service',
    content: `
      <p>By using a Web3 wallet in connection with the Service, you agree to use the wallet under the terms and conditions of the wallet provider. Wallets are not operated by, maintained by, or affiliated with 1001 Squares of NFT, and 1001 Squares of NFT has no custody or control over your wallet&rsquo;s contents or ability to retrieve or transfer its contents. 1001 Squares of NFT makes no representations or warranties regarding how the Service will operate with any specific wallet and does not accept responsibility for, or liability to you, associated with the use of your wallet. Keeping your wallet secure is solely your responsibility.</p>
      <p>Interrupted use and access of the Service may happen from time to time for several reasons, without limitation including maintenance, equipment malfunction, service updating, or other actions that 1001 Squares of NFT, in its sole discretion, may find necessary.</p>
      <p>We require all users to be at least 18 years old. You warrant that by using the Service you will comply with all applicable laws of your country of residence used to access and use the Service. You also warrant you will not access or use the front-end interface to conduct or facilitate any illegal activity.</p>
    `,
  },
  {
    title: '4. Disclaimers',
    content: `
      <p>We do not represent or warrant that access to the Service will be continuous, timely, or secure. Additionally, we do not represent or warrant that the information contained in the interface will be current, reliable, accurate and complete, or that it will be free from defects, errors, viruses, or other harmful elements.</p>
      <p>The $DONE token is issued on the Solana blockchain, and as with all cryptocurrencies, its value can be volatile and may be influenced by external factors beyond the control of 1001 Squares of NFT. We do not guarantee the stability or value of $DONE and will not be liable for any loss incurred due to fluctuations in its value.</p>
      <p>Your access and use of the service is at your own risk. 1001 Squares of NFT disclaims any kind of warranties or conditions regarding the service expressed or implied. 1001 Squares of NFT will not be liable for any loss from reliance on information or data contained on the service. Additionally, no information or advice obtained from the Service or from 1001 Squares of NFT parties will create any representation or warranty not expressly made herein. We will not be responsible or liable to you for any loss extending from user error, unauthorized access, server failure, data loss, or any unauthorised third party activities including (but not limited to) phishing, viruses, or other means of attack against the Service. You accept the security risks of using the Service over the internet and will not hold 1001 Squares of NFT responsible for any breach in security.</p>
    `,
  },
  {
    title: '5. Ownership',
    content: `
      <p>We own the intellectual property generated by 1001 Squares of NFT contributors for business purposes, including (but not limited to) software, designs, images, text, button icons, page headers, and copyright. Unless otherwise stated, 1001 Squares of NFT reserves exclusive rights to its intellectual property.&nbsp;</p>
    `,
  },
  {
    title: '6. User Conduct',
    content: `
      <p>You agree to not violate any laws, contracts, intellectual property or other third-party rights, and that you are solely responsible for your conduct while accessing or using the Service. You agree to not:</p>
      <ul>
          <li>
              <p>Use $DONE tokens for illicit activities, including money laundering or terrorist financing</p>
          </li>
          <li>
              <p>Engage in fraudulent behavior involving $DONE tokens.</p>
          </li>
          <li>
              <p>Use or attempt to use third party Accounts without such party&rsquo;s authorization</p>
          </li>
          <li>
              <p>Access the Service from an alternate blockchain address if your other blockchain addresses have been blocked from accessing the Service</p>
          </li>
          <li>
              <p>Spam or send unwanted NFTs to other users.</p>
          </li>
          <li>
              <p>Use the Service in a way that could damage or impair the functioning of the Service in any manner</p>
          </li>
          <li>
              <p>Use a robot, spider, crawler, scraper, script, browser extension, or other automated means to interact with the Service</p>
          </li>
          <li>
              <p>Use the Service for money laundering or terrorist financing</p>
          </li>
          <li>
              <p>Use the Service to engage in fraud, deceptive or misleading activities, or price manipulation.</p>
          </li>
          <li>
              <p>Use the Service to promote or facilitate the buying, selling, or transfer of fraudulently obtained or stolen items, and/or any other illegally obtained items</p>
          </li>
          <li>
              <p>Display or promote content which presents suicide, self-harm, hate or violence against others, or that doxes another person</p>
          </li>
          <li>
              <p>Display or promote illegal content, including (but not limited to) content containing child sexual exploitation</p>
          </li>
          <li>
              <p>Violate intellectual property rights or any other rights of others</p>
          </li>
          <li>
              <p>Use the Service in a manner that could interfere with other users fully enjoying the Service</p>
          </li>
      </ul>
      <p>Posting, promoting, and displaying NSFW content is allowed on a case-to-case basis. We reserve the right, at our sole discretion, to take down content from the front page if we believe that it may make users feel uncomfortable, may be offensive, or we believe it interferes negatively with the experience of other users. NSFW content that is allowed may be handled differently than non-NSFW content in several instances of the front end interface. If you would like to check if a certain NSFW content will be allowed, contact us through official channels like the official 1001 Squares of NFT Discord.</p>
    `,
  },
  {
    title: '7. Intellectual Property Rights',
    content: `
      <p>By using the Service to display an NFT, you grant us a non-exclusive worldwide sublicensable royalty-free license to display, copy, modify, or use any content, including but not limited to images, files, data, or text, that you display through the Service for our current and future business purposes. This pertains to any art, digital file, or other material associated with NFTs displayed on the Service. Notwithstanding, 1001 Squares of NFT does not claim that submitting, posting, or displaying content through the Service gives 1001 Squares of NFT any ownership of said content.</p>
      <p>In addition, users may receive rewards in $DONE tokens for their interaction with the platform. By participating in the $DONE token rewards program, you agree that any $DONE tokens you receive will be used for lawful purposes.</p>
      <p>You warrant and represent that you hold, or have obtained, all rights, consents, permissions, licenses necessary, or authorization to allocate the rights granted herein for any content that you, submit, post, promote, or display through the Service. Additionally, you warrant and represent that such content is not subject to intellectual property rights or publicity rights, unless you have the permission required or are in some way legally entitled to post the material and to grant 1001 Squares of NFT the license above described and that said content does not violate any laws.</p>
      <p>You, the user, are solely responsible for your use of the Service and compliance with these Terms, as well as applicable laws and regulations.</p>
    `,
  },
  {
    title: '8. Refund Policy',
    content: `
      <p>Transactions made to 1001 Squares of NFT, through the 1001 Squares of NFT smart contract during access through the front end or otherwise, under all circumstances are non-refundable unless otherwise stated. 1001 Squares of NFT holds the right, at its sole discretion, to take down any NFTs that may be considered fraudulent, counterfeit, offensive, violent, pornographic, or that may negatively impact the experience for other users, and may do so without any notice or refund. 1001 Squares of NFT and its Service do not guarantee the sale of any NFT posted and we do not offer refunds or any kind of compensation in case of a lack of sale.</p>
      <p>For payments made using the $DONE token, users acknowledge that the value of $DONE can fluctuate, and any potential discrepancies in the value of $DONE or any other currency used for payment at the time of transaction will not be eligible for refunds. Rewards earned in $DONE are non-refundable.</p>
      <p>1001 Squares of NFT reserves the right to block you from interacting with the frontend of the Service, smart contract, profile page, and front end controls for reasons of spam, sharing inappropriate content, or any other reason lying at 1001 Squares of NFT&rsquo;s sole discretion, without any notice, refund, or form of compensation to you the user. You accept 1001 Squares of NFT will not be held responsible for any misused or wasted funds that will go unused from posting NFTs that get taken down, and/or accounts that get blocked.</p>
      <p>You accept that there may be a disparity between the time, position, and value inputs when adding an NFT versus the NFT&rsquo;s active time on the front page. The position on the front page that&rsquo;s calculated when adding an NFT is an estimated starting position and you accept that the position, value, conversion of value, and ad price per day may differ from what&rsquo;s shown in the Add NFT settings screen to when the NFT is posted. 1001 Squares of NFT at no point guarantees that a transaction will place the user&rsquo;s NFT on the front page in the user&rsquo;s exact specified position or that the NFT will be displayed for the exact and full amount of time. In case of technical malfunction, bankruptcy, termination, merger, acquisition, human error, natural disaster or another event not here specified, you accept that your purchase order may not be fulfilled in part or in full as initially specified, without holding 1001 Squares of NFT responsible for any compensation or refund to you.</p>
      <p>1001 Squares of NFT may pause the active time remaining on all NFTs whilst servers are down; however in case of this feature&rsquo;s malfunction, or any other malfunction from the Service side, you accept the risk of irregularities in the Service without holding 1001 Squares of NFT responsible for any compensation or refund.</p>
      <p>NFTs are intended to be positioned on the front page according to the user&rsquo;s paid ad price per day in $USD, and unless otherwise specified or due to software error, will be ranked in order of highest to lowest. 1001 Squares of NFT utilizes a third-party API to convert crypto-values to $USD and is not responsible for any disparity, irregularities, or malfunction in the conversion before, during, and after payment. Additionally, you accept that in case of software malfunction, cyber attack, or any other problem that affects the intended ranking of NFTs on the front page, 1001 Squares of NFT is not liable to you for a refund or compensation unless otherwise specified.</p>
      <p>Any transaction fees associated with the smart contract transaction must be covered in full by the user and are at no time refundable by 1001 Squares of NFT unless otherwise specified. When a transaction is confirmed on the front end by a user, when clicking &ldquo;CONFIRM PAYMENT&rdquo;, a successful blockchain transaction by the connected Web3 wallet must take place in the next 20 minutes for an NFT to be successfully posted. You accept that 1001 Squares of NFT is not responsible for refunding transactions made successful after 20 minutes, and that completing a successful transaction after 20 minutes may lead to an NFT not being posted. Additionally, 1001 Squares of NFT is not responsible for user errors, delays, malfunctions, cyberattacks, or software bugs on the wallet, wallet address, website, app, or the blockchain of the transaction in question.</p>
      <p>The transactions of NFT purchases, sales, trades, offers, bids or any other form of interaction made by the user on third party websites linked on 1001 Squares of NFT are not refundable. 1001 Squares of NFT is not responsible or liable for any and all interactions of users on links and data of third party websites linked, posted, or displayed on 1001 Squares of NFT. You warrant and represent that the information displayed on 1001 Squares of NFT which includes but isn&rsquo;t limited to NFT appearance, price, bids, offers, items details, and other information held on third party websites are not guaranteed to be timely, accurate, reliable, valid or complete. You agree that 1001 Squares of NFT will not be held responsible for or liable to you for any inconveniences or damages to you that may arise from displaying invalid or inaccurate information.</p>
      <p>1001 Squares of NFT is not responsible for the user&rsquo;s purchase of any counterfeit, illegal, stolen, or otherwise unauthorized items on third-party websites. Any purchases or sales on third party websites of items linked on 1001 Squares of NFT fall under the third party website user&rsquo;s responsibility and 1001 Squares of NFT is not responsible or liable to you for any inaccurate or misleading information displayed on the 1001 Squares of NFT website which may lead to a wrongful or unlawful purchase or sale.</p>
    `,
  },
  {
    title: '9. $DONE Token Policy',
    content: `
      <p>$DONE is the native token of 1001 Squares of NFT and can be used for NFT ad placement on the platform. The following terms apply to the use of $DONE:</p>
      <ul>
          <li>
              <p>Payments: Users may pay for NFT ad placement with $DONE or other supported cryptocurrencies. $DONE tokens received in transactions are non-refundable, and any fluctuations in its value are not the responsibility of 1001 Squares of NFT.</p>
          </li>
          <li>
              <p>Rewards: $DONE tokens may be distributed as part of platform incentives, including user activity rewards. The distribution of $DONE rewards is not guaranteed and is subject to the availability of tokens within the platform&rsquo;s reserve. We reserve the right to modify or discontinue the rewards program at any time, at our sole discretion.</p>
          </li>
          <li>
              <p>Participation: By using $DONE tokens on the platform, you agree to the terms of this policy. Rewards in $DONE are non-refundable and subject to change based on platform developments.</p>
          </li>
          <li>
              <p>Legal and Tax Obligations: You are responsible for complying with any tax obligations related to the acquisition, use, or sale of $DONE tokens in your jurisdiction.</p>
          </li>
      </ul>
      <p>For more information about the $DONE token&rsquo;s allocation, supply, and rewards system, please refer to our Litepaper.</p>
    `,
  },
  {
    title: '10. Privacy Policy',
    content: `
      <p>At no point during the rendering of the Service do we collect any personal information from users. When accessing and using the front-end interface, the only information collected from you is your blockchain wallet address, completed transaction hashes, and token identifiers. We use Google Analytics which may receive publicly available personal information. We do not take responsibility for any actions you take through the front-end interface and any information you may thereby make public on public blockchains.</p>
    `,
  },
];

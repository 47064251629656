/* eslint-disable */
import { Box, makeStyles, Typography } from '@material-ui/core';
import CommonInput from 'components/common/CommonInput';
import React, { useCallback, useEffect, useState, ChangeEvent } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import {
  blockUserAction,
  getWalletBlockerAction,
  unblockUserAction,
} from 'store/actions/walletBlockerActions';
import TablePagination from '@material-ui/core/TablePagination';
import { getWalletBlocker } from 'store/selectors';
import { hasMore } from 'common/helper';
import debounce from 'lodash/debounce';
import { INPUT_DEBOUNCE_DURATION } from 'common/constant';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import ConfrimPopup from 'components/Popup/ConfirmPopup';

interface IData {}

const WalletBlocker = (props: IData) => {
  const [search, setSearch] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const {
    data: wallerBlockers,
    pageNumber,
    pageSize,
    total,
  } = useSelector(getWalletBlocker);
  const [page, setPage] = React.useState(1);

  const dispatch = useDispatch();

  const classes = useStyles();

  const debouncedOnGetUserByName = debounce((search: string) => {
    setPage(1);
    dispatch(
      getWalletBlockerAction({
        search,
        pageNumber: 1,
        pageSize: rowsPerPage,
      }),
    );
  }, INPUT_DEBOUNCE_DURATION);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      debouncedOnGetUserByName(event.target.value);
    },
    [setSearch, debouncedOnGetUserByName],
  );

  const handleChangePage = useCallback(
    (event: unknown, newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(1);
    },
    [setPage, setRowsPerPage],
  );

  useEffect(() => {
    dispatch(
      getWalletBlockerAction({
        search,
        pageNumber: page,
        pageSize: rowsPerPage,
      }),
    );
  }, [dispatch, page, rowsPerPage]);

  const labelDisplayedRows = useCallback(() => {
    const to = pageNumber * pageSize > total ? total : pageNumber * pageSize;
    return `${(pageNumber - 1) * pageSize + 1}–${to} of ${total}`;
  }, [pageNumber, pageSize, total]);

  const callback = useCallback(() => {
    dispatch(
      getWalletBlockerAction({
        search,
        pageNumber: page,
        pageSize: rowsPerPage,
      }),
    );
  }, [dispatch, page, rowsPerPage]);

  const onBlockUser = useCallback(
    (id: number) => {
      dispatch(blockUserAction(id, callback));
    },
    [dispatch],
  );

  const onUnblockUser = (id: number) => {
    dispatch(unblockUserAction(id, callback));
  };

  const showUnblockConfirm = (id: number) => {
    dispatch(
      updateDialogStateAction({
        open: true,
        component: ConfrimPopup,
        props: {
          callback: () => onUnblockUser(id),
          text: 'Do you want to unblock this user',
          txtBtnLeft: 'No',
          txtBtnRight: 'Yes',
        },
      }),
    );
  };

  const showPopupConfirm = useCallback(
    (id: number) => {
      dispatch(
        updateDialogStateAction({
          open: true,
          component: ConfrimPopup,
          props: {
            callback: () => onBlockUser(id),
            text: 'Do you want to block this user',
            xtBtnLeft: 'No',
            txtBtnRight: 'Yes',
          },
        }),
      );
    },
    [dispatch],
  );

  return (
    <Box>
      <CommonInput
        value={search}
        onChange={handleChange}
        placeholder="Search Wallet or User"
        className={classes.input}
      />
      <Paper className={classes.main}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Wallet Address</TableCell>
                <TableCell align="left">Username</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallerBlockers.map((row) => (
                <TableRow key={row.address} classes={{ root: classes.root }}>
                  <TableCell component="th" scope="row">
                    {row.address}
                  </TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="center">
                    {row.isBlocked ? (
                      <div className={classes.unblockItem}>
                        <div>Blocked / </div>
                        <div
                          className={classes.unblockBtn}
                          onClick={() => showUnblockConfirm(row.id)}
                        >
                          Unblock
                        </div>
                      </div>
                    ) : (
                      <Typography
                        className={classes.block}
                        onClick={() => showPopupConfirm(row.id)}
                      >
                        Block
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            disabled: pageNumber == 1,
          }}
          nextIconButtonProps={{
            disabled: !hasMore({ pageNumber, pageSize, total }),
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          SelectProps={{
            MenuProps: { classes: { paper: classes.selectDropdown } },
          }}
          labelDisplayedRows={labelDisplayedRows}
        />
      </Paper>
    </Box>
  );
};

export default WalletBlocker;

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#FFFFFF',
    '& div': {
      borderRadius: 0,
    },
  },
  input: {
    marginBottom: 16,
  },
  table: {
    minWidth: 650,
    backgroundColor: '#FFFFFF',
    overflow: 'hidden',
    '& th': {
      border: '1px solid rgba(224, 224, 224, 1)',
      padding: '8px 16px',
    },
    '& td': {
      border: '1px solid rgba(224, 224, 224, 1)',
      padding: '8px 16px',
    },
    '& thead th': {
      padding: 16,
    },
  },
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
    },
  },
  block: {
    textDecorationLine: 'underline',
    textDecorationColor: '#1b1464',
    cursor: 'pointer',
  },
  selectDropdown: {
    backgroundColor: '#FFFFFF',
  },
  unblockItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  unblockBtn: {
    textDecorationLine: 'underline',
    textDecorationColor: '#1b1464',
    cursor: 'pointer',
  },
}));

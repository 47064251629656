import { Box, makeStyles, Typography } from '@material-ui/core';
import { Notification } from 'types/notification';
import NotificationDetail from './NotificationDetail';

interface INotificationByDate {
  notifications: Notification[];
  label: string;
}

const NotificationByDate = ({ notifications, label }: INotificationByDate) => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Typography>{label}</Typography>
      <Box>
        {notifications.map((n) => (
          <NotificationDetail key={n.id} notification={n} />
        ))}
      </Box>
    </Box>
  );
};

export default NotificationByDate;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    main: {
      '&>p': {
        marginTop: 24,
        marginBottom: -8,
        color: isDarkMode ? theme.colors.white : theme.colors.black,
        opacity: 0.5,
        textAlign: 'center',
      },
    },
  };
});

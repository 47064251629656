import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function OtherMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 64 64"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#909090"
      />
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#909090"
      />
      <path
        d="M42.6713 18.8822C46.2762 21.723 48.6089 25.9062 49.0678 30.4507L42.9943 28.9404C41.892 25.2143 38.8597 22.1379 34.7607 21.1185C28.6446 19.5976 22.4696 23.2325 20.9685 29.2374C19.4674 35.2422 23.2086 41.3431 29.3247 42.864C33.4237 43.8833 37.5492 42.5868 40.2764 39.8131L46.3498 41.3234C43.8055 45.1211 39.7768 47.7224 35.2568 48.5427L33.5281 55.4579L27.991 54.081L29.364 48.5888C28.8983 48.5122 28.4319 48.4163 27.9658 48.3004C27.4996 48.1844 27.0426 48.0508 26.5954 47.9003L25.2225 53.3925L19.6854 52.0155L21.4141 45.1004C16.3397 41.1014 13.786 34.4425 15.4314 27.8604C17.0768 21.2783 22.4659 16.5946 28.8286 15.4398L30.5573 8.52459L36.0944 9.90154L34.7214 15.3937C35.1871 15.4703 35.6535 15.5662 36.1196 15.6822C36.5858 15.7981 37.0428 15.9317 37.49 16.0822L38.8629 10.59L44.4 11.967L42.6713 18.8822Z"
        fill="#909090"
      />
      <path
        d="M42.6713 18.8822C46.2762 21.723 48.6089 25.9062 49.0678 30.4507L42.9943 28.9404C41.892 25.2143 38.8597 22.1379 34.7607 21.1185C28.6446 19.5976 22.4696 23.2325 20.9685 29.2374C19.4674 35.2422 23.2086 41.3431 29.3247 42.864C33.4237 43.8833 37.5492 42.5868 40.2764 39.8131L46.3498 41.3234C43.8055 45.1211 39.7768 47.7224 35.2568 48.5427L33.5281 55.4579L27.991 54.081L29.364 48.5888C28.8983 48.5122 28.4319 48.4163 27.9658 48.3004C27.4996 48.1844 27.0426 48.0508 26.5954 47.9003L25.2225 53.3925L19.6854 52.0155L21.4141 45.1004C16.3397 41.1014 13.786 34.4425 15.4314 27.8604C17.0768 21.2783 22.4659 16.5946 28.8286 15.4398L30.5573 8.52459L36.0944 9.90154L34.7214 15.3937C35.1871 15.4703 35.6535 15.5662 36.1196 15.6822C36.5858 15.7981 37.0428 15.9317 37.49 16.0822L38.8629 10.59L44.4 11.967L42.6713 18.8822Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default memo(OtherMenuItem);

import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function ArbitrumMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 2500 2500"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g id="Layer_x0020_1">
        <g id="_2405588477232">
          <rect style={{ fill: 'none' }} width="2500" height="2500"></rect>
          <g>
            <g>
              <path
                style={{
                  fill: '#213147',
                }}
                d="M226,760v980c0,63,33,120,88,152l849,490c54,31,121,31,175,0l849-490c54-31,88-89,88-152V760      c0-63-33-120-88-152l-849-490c-54-31-121-31-175,0L314,608c-54,31-87,89-87,152H226z"
              ></path>
              <g>
                <g>
                  <g>
                    <path
                      style={{ fill: '#12AAFF' }}
                      d="M1435,1440l-121,332c-3,9-3,19,0,29l208,571l241-139l-289-793C1467,1422,1442,1422,1435,1440z"
                    ></path>
                  </g>
                  <g>
                    <path
                      style={{ fill: '#12AAFF' }}
                      d="M1678,882c-7-18-32-18-39,0l-121,332c-3,9-3,19,0,29l341,935l241-139L1678,883V882z"
                    ></path>
                  </g>
                </g>
              </g>
              <g>
                <path
                  style={{
                    fill: '#9DCCED',
                  }}
                  d="M1250,155c6,0,12,2,17,5l918,530c11,6,17,18,17,30v1060c0,12-7,24-17,30l-918,530c-5,3-11,5-17,5       s-12-2-17-5l-918-530c-11-6-17-18-17-30V719c0-12,7-24,17-30l918-530c5-3,11-5,17-5l0,0V155z M1250,0c-33,0-65,8-95,25L237,555       c-59,34-95,96-95,164v1060c0,68,36,130,95,164l918,530c29,17,62,25,95,25s65-8,95-25l918-530c59-34,95-96,95-164V719       c0-68-36-130-95-164L1344,25c-29-17-62-25-95-25l0,0H1250z"
                ></path>
              </g>
              <polygon
                style={{
                  fill: '#213147',
                }}
                points="642,2179 727,1947 897,2088 738,2234     "
              ></polygon>
              <g>
                <path
                  style={{
                    fill: '#FFFFFF',
                  }}
                  d="M1172,644H939c-17,0-33,11-39,27L401,2039l241,139l550-1507c5-14-5-28-19-28L1172,644z"
                ></path>
                <path
                  style={{
                    fill: '#FFFFFF',
                  }}
                  d="M1580,644h-233c-17,0-33,11-39,27L738,2233l241,139l620-1701c5-14-5-28-19-28V644z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default memo(ArbitrumMenuItem);

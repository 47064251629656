/* eslint-disable */
import { makeStyles } from '@material-ui/core';
import { hasMore } from 'common/helper';
import React, { useCallback, useEffect, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentSalesAction } from 'store/actions/recentSalesAction';
import { getRecentSales, getRecentSalesPagination } from 'store/selectors';
import RecentSaleItem from 'components/RecentSaleItem';

interface IData {}

const RecentSales = (props: IData) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const recentSales = useSelector(getRecentSales);
  const pagination = useSelector(getRecentSalesPagination);
  const hasMoreRecentSales = useMemo(() => {
    return hasMore(pagination);
  }, [pagination]);

  useEffect(() => {
    dispatch(
      getRecentSalesAction({
        pageNumber: 1,
        pageSize: 8,
      }),
    );
  }, []);

  const loadMoreRecentSales = useCallback(() => {
    dispatch(
      getRecentSalesAction({
        pageNumber: pagination.pageNumber + 1,
        pageSize: pagination.pageSize,
      }),
    );
  }, [pagination]);

  return (
    <InfiniteScroll
      dataLength={recentSales.length}
      next={loadMoreRecentSales}
      hasMore={hasMoreRecentSales}
      className={classes.main}
      loader={<p>Loading...</p>}
    >
      {recentSales.map((item) => (
        <RecentSaleItem data={item} key={item.sales_id} />
      ))}
    </InfiniteScroll>
  );
};

export default RecentSales;

const useStyles = makeStyles((theme) => ({
  main: {},
}));

import { PaletteMode } from '@mui/material';
import { ModeActionTypeEnum } from 'enums/actions';
import { DispatchType } from 'types/store';

export const changeModeStateAction = (payload: PaletteMode) => ({
  type: ModeActionTypeEnum.CHANGE_MODE,
  payload,
});

export const changeModeTheme = (mode: PaletteMode) => {
  return (dispatch: DispatchType): void => {
    dispatch(changeModeStateAction(mode));
    localStorage.setItem('mode', mode);
  };
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, MenuItem, Select, Typography } from '@material-ui/core';
import TimePicker from 'components/TimePicker';
import { Orientations } from 'enums/orientations';
import CategoriyService, { GetDataCategories } from 'services/categories';
import CircularLoader, {
  CircularLoaderTypeEnum,
} from 'components/common/CircularLoader';
import { SortEnum } from 'enums/sortEnum';
import { DataCategories, DataBlockCategories } from 'types/categories';
import { useStyle } from './CategoriesStyle';
import clsx from 'clsx';

function CategoryPanel() {
  const classes = useStyle();

  const [allCategories, setAllCategories] = useState<DataCategories[]>([]);
  const [currentCategory, setCurrentCategory] = useState<string | undefined>(
    undefined,
  );
  const [orientation, setOrientation] = useState<Orientations>(
    Orientations.TWITTER_POST,
  );
  const [date, setDate] = React.useState<Date | null>(new Date());

  const [categoryInfo, setCategoryInfo] = useState<
    DataBlockCategories | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleGetCategoryInfo = useCallback(
    async (categoryName: string, date: Date | null) => {
      try {
        setLoading(true);

        const categoryInfo = await CategoriyService.GetCategoriesBlock({
          category: categoryName,
          includeTwitter: true,
          time: date == null ? '' : date.toISOString(),
          includeStatus: true,
        });

        setCategoryInfo(categoryInfo);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(true);
        console.log('error: ', error);
      }
    },
    [setLoading, setCategoryInfo, setError],
  );

  const getInitialData = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);
      const data = await GetDataCategories({
        sortBy: 'VolumeAll',
        sortType: SortEnum.Desc,
      });
      const categories = data.data;
      setAllCategories(categories);
      setCurrentCategory(categories[0].name);

      await handleGetCategoryInfo(categories[0].name, date);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log('error: ', error);
    }
  }, [
    date,
    setLoading,
    setAllCategories,
    setCurrentCategory,
    setError,
    handleGetCategoryInfo,
  ]);

  const handleChangeCategory = useCallback(
    async (event: React.ChangeEvent<{ value: any }>) => {
      setCurrentCategory(event.target.value);
      await handleGetCategoryInfo(event.target.value, date);
    },
    [date, setCurrentCategory, handleGetCategoryInfo],
  );

  const handleChangeOrientation = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setOrientation(event.target.value as Orientations);
    },
    [setOrientation],
  );

  const handleChangeTime = useCallback(
    async (date: Date | null) => {
      setDate(date);
      if (currentCategory) {
        await handleGetCategoryInfo(currentCategory, date);
      }
    },
    [currentCategory, handleGetCategoryInfo],
  );

  useEffect(() => {
    getInitialData();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = useMemo(() => {
    if (error) {
      return (
        <div className={clsx(classes.flexBox, classes.textError)}>
          There is error
        </div>
      );
    }

    if (categoryInfo && categoryInfo?.data.length > 0) {
      const originBlockImage = categoryInfo.data[12]?.imgUrl || '';
      const twitterBlockImage = categoryInfo.data[13]?.imgUrl || '';
      const imgUrl =
        orientation === Orientations.TWITTER_POST
          ? twitterBlockImage
          : originBlockImage;

      return (
        <>
          <Box className={classes.imageBlockContaner}>
            <img src={imgUrl} className={classes.imageBlock} alt="" />
          </Box>
          <Box className={classes.cateInfo}>
            <Box className={classes.cateItem}>
              <Typography>Total Owners</Typography>
              <Typography>
                {categoryInfo?.status?.totalOwner || '--'}
              </Typography>
            </Box>
            <Box className={classes.cateItem}>
              <Typography>Total Items</Typography>
              <Typography>{categoryInfo?.status?.totalItem || '--'}</Typography>
            </Box>
            <Box className={classes.cateItem}>
              <Typography>First Place</Typography>
              <Typography>
                {categoryInfo?.status?.firstPlace || '--'}
              </Typography>
            </Box>
            <Box className={classes.cateItem}>
              <Typography>Last Place</Typography>
              <Typography>{categoryInfo?.status?.lastPlace || '--'}</Typography>
            </Box>
            <Box className={classes.cateItem}>
              <Typography>All time volume</Typography>
              <Typography>
                {categoryInfo?.status?.volAllTime || '--'}
              </Typography>
            </Box>
          </Box>
        </>
      );
    }

    if (!error && !loading && categoryInfo && categoryInfo?.data.length === 0) {
      return (
        <div className={clsx(classes.flexBox, classes.textNoData)}>
          No data available
        </div>
      );
    }
  }, [orientation, categoryInfo, loading, error, classes]);

  return (
    <Box className={classes.container}>
      <Box className={classes.top}>
        {currentCategory && (
          <Select
            value={currentCategory}
            onChange={handleChangeCategory}
            classes={{
              root: classes.root,
            }}
            MenuProps={{
              classes: { paper: classes.dropdownStyle },
              variant: 'menu',
            }}
            style={{
              marginRight: 40,
            }}
            disableUnderline
          >
            {allCategories.map((cat) => (
              <MenuItem key={cat.name} value={cat.name}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        )}
        <Select
          value={orientation}
          onChange={handleChangeOrientation}
          classes={{
            root: classes.root,
          }}
          MenuProps={{
            classes: { paper: classes.dropdownStyle },
            variant: 'menu',
          }}
          disableUnderline
        >
          {Object.values(Orientations).map((v, i) => (
            <MenuItem key={i} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
        <div className={classes.time}>
          <TimePicker
            value={date}
            onChange={(value: Date | null) => handleChangeTime(value)}
          />
        </div>
      </Box>
      <Box className={classes.mainConent}>
        <CircularLoader
          loading={loading}
          type={CircularLoaderTypeEnum.FULL_CONTENT}
        />
        {renderContent}
      </Box>
    </Box>
  );
}

export default CategoryPanel;

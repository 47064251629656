import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    root: {
      position: 'relative',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
    },
    noDataMessage: {
      textAlign: 'center',
      fontStyle: 'italic',
    },
    cursorDefault: {
      cursor: 'default',
    },
    pagination: {
      backgroundColor: '#f5f5f5 !important',
    },
    insertedRow: {
      backgroundColor: '#f5f5f5',
      height: '32px',
    },
  };
});

import { PropsWithChildren } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from 'store';
import MaterialThemeProvider from 'material/provider';

const store = configureStore();
const Provider = ({ children }: PropsWithChildren) => {
  return (
    <ReduxProvider store={store}>
      <MaterialThemeProvider>{children}</MaterialThemeProvider>
    </ReduxProvider>
  );
};

export default Provider;

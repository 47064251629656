import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function SOLMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 12 12"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clip-path="url(#clip0_2246_30413)">
        <path
          d="M2.28715 1.76735C2.35629 1.70057 2.44756 1.66162 2.54159 1.66162H11.3197C11.4801 1.66162 11.5603 1.85638 11.4469 1.97045L9.71285 3.7149C9.64647 3.78168 9.55521 3.82063 9.45841 3.82063H0.680323C0.519917 3.82063 0.439714 3.62587 0.553104 3.5118L2.28715 1.76735Z"
          fill="url(#paint0_linear_2246_30413)"
        />
        <path
          d="M9.71285 5.02619C9.64647 4.95942 9.55521 4.92047 9.45841 4.92047H0.680323C0.519917 4.92047 0.439714 5.11523 0.553104 5.2293L2.28715 6.97375C2.35353 7.04052 2.44479 7.07948 2.54159 7.07948H11.3197C11.4801 7.07948 11.5603 6.88472 11.4469 6.77065L9.71285 5.02619Z"
          fill="url(#paint1_linear_2246_30413)"
        />
        <path
          d="M2.54159 8.17932C2.44479 8.17932 2.35353 8.21827 2.28715 8.28504L0.553104 10.0295C0.439714 10.1436 0.519917 10.3383 0.680323 10.3383H9.45841C9.55521 10.3383 9.64647 10.2994 9.71285 10.2326L11.4469 8.48814C11.5603 8.37407 11.4801 8.17932 11.3197 8.17932H2.54159Z"
          fill="url(#paint2_linear_2246_30413)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2246_30413"
          x1="10.4811"
          y1="0.624037"
          x2="4.34864"
          y2="12.3001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2246_30413"
          x1="10.4811"
          y1="0.624037"
          x2="4.34864"
          y2="12.3001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2246_30413"
          x1="10.4811"
          y1="0.624037"
          x2="4.34864"
          y2="12.3001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <clipPath id="clip0_2246_30413">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default memo(SOLMenuItem);

import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function MaticIcon({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 12 10"
      style={{ transform: 'scale(1)', width, height }}
      fill="none"
    >
      <path
        d="M8.63055 3.25074C8.44361 3.14392 8.20325 3.14392 7.9896 3.25074L6.49405 4.13205L5.47921 4.69288L4.01037 5.57418C3.82343 5.68101 3.58307 5.68101 3.36942 5.57418L2.22105 4.87982C2.03411 4.773 1.90058 4.55935 1.90058 4.31899V2.98368C1.90058 2.77003 2.0074 2.55638 2.22105 2.42285L3.36942 1.75519C3.55637 1.64837 3.79672 1.64837 4.01037 1.75519L5.15874 2.44955C5.34568 2.55638 5.47921 2.77003 5.47921 3.01039V3.89169L6.49405 3.30415V2.39614C6.49405 2.18249 6.38723 1.96884 6.17358 1.83531L4.03708 0.580119C3.85013 0.473294 3.60978 0.473294 3.39613 0.580119L1.20622 1.86202C0.992567 1.96884 0.885742 2.18249 0.885742 2.39614V4.90653C0.885742 5.12018 0.992567 5.33383 1.20622 5.46736L3.36942 6.72255C3.55637 6.82938 3.79672 6.82938 4.01037 6.72255L5.47921 5.86795L6.49405 5.28042L7.96289 4.42582C8.14984 4.31899 8.39019 4.31899 8.60384 4.42582L9.75221 5.09347C9.93915 5.2003 10.0727 5.41395 10.0727 5.6543V6.98961C10.0727 7.20326 9.96586 7.41691 9.75221 7.55044L8.63055 8.2181C8.44361 8.32493 8.20325 8.32493 7.9896 8.2181L6.84123 7.55044C6.65429 7.44362 6.52076 7.22997 6.52076 6.98961V6.13501L5.50592 6.72255V7.60386C5.50592 7.81751 5.61274 8.03116 5.8264 8.16469L7.9896 9.41988C8.17654 9.52671 8.4169 9.52671 8.63055 9.41988L10.7938 8.16469C10.9807 8.05786 11.1142 7.84421 11.1142 7.60386V5.06677C11.1142 4.85312 11.0074 4.63947 10.7938 4.50593L8.63055 3.25074Z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default memo(MaticIcon);

import { makeStyles } from '@material-ui/core';

interface IStyle {
  isFocused: boolean;
  isDeadZonePosition: boolean;
}

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      display: 'flex',
    },
    root: {
      paddingRight: 0,
      borderRadius: 0,
      width: 60,
      height: 36,
      '& input': {
        fontSize: 14,
        fontWeight: 700,
        textAlign: 'center',
        paddingRight: 8,
        paddingLeft: 8,
        color: isDarkMode ? theme.colors.white : theme.colors.black,
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
      },
      '& input::placeholder': {
        fontSize: 14,
        color: isDarkMode ? theme.colors.white : theme.colors.black,
        opacity: 0.2,
        fontWeight: 700,
      },
    },
    icon: {
      height: 36,
      width: 36,
    },
    upButton: {
      backgroundColor: ({ isDeadZonePosition, isFocused }: IStyle) => {
        if (isDeadZonePosition && isFocused) {
          return '#DBC026';
        }
        if (isDeadZonePosition) {
          return theme.colors.deadzone;
        }
        return isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight;
      },
      width: 36,
      height: 36,
      minWidth: 30,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: ({ isDeadZonePosition, isFocused }: IStyle) => {
        if (isDeadZonePosition && isFocused) {
          return '#DBC026 !important';
        }
        if (isDeadZonePosition) {
          return `${theme.colors.deadzone} !important`;
        }

        if (isFocused) {
          return isDarkMode
            ? `${theme.colors.secondaryDark} !important`
            : `${theme.colors.primaryLight} !important`;
        }
        return isDarkMode
          ? `${theme.colors.cultured} !important`
          : `${theme.colors.blackOlive} !important`;
      },
      color: theme.colors.secondaryLight,
      borderLeft: 'none !important',
    },
    downButton: {
      width: 36,
      height: 36,
      minWidth: 30,
      border: 'unset',
      color: ({ isDeadZonePosition, isFocused }: IStyle) => {
        if (isDeadZonePosition && isFocused) {
          return '#DBC026';
        }
        if (isDeadZonePosition) {
          return theme.colors.deadzone;
        }
        if (isFocused) {
          return isDarkMode
            ? `${theme.colors.secondaryDark} !important`
            : `${theme.colors.primaryLight} !important`;
        }
        return isDarkMode
          ? `${theme.colors.cultured} !important`
          : `${theme.colors.blackOlive} !important`;
      },
    },
    notchedOutline: {
      borderColor: ({ isDeadZonePosition, isFocused }: IStyle) => {
        if (isDeadZonePosition && isFocused) {
          return '#DBC026 !important';
        }
        if (isDeadZonePosition) {
          return `${theme.colors.deadzone} !important`;
        }
        if (isFocused) {
          return isDarkMode
            ? `${theme.colors.secondaryDark} !important`
            : `${theme.colors.primaryLight} !important`;
        }
        return isDarkMode
          ? `${theme.colors.cultured} !important`
          : `${theme.colors.blackOlive} !important`;
      },
    },
    focused: {
      borderColor: `unset`,
    },
    startAdornment: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: ({ isDeadZonePosition, isFocused }: IStyle) => {
        if (isDeadZonePosition && isFocused) {
          return '#DBC026 !important';
        }
        if (isDeadZonePosition) {
          return `${theme.colors.deadzone} !important`;
        }
        if (isFocused) {
          return isDarkMode
            ? `${theme.colors.secondaryDark} !important`
            : `${theme.colors.primaryLight} !important`;
        }
        return isDarkMode
          ? `${theme.colors.cultured} !important`
          : `${theme.colors.blackOlive} !important`;
      },
      borderRight: 'none !important',
    },
  };
});

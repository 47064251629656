import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    wrapInputSearch: {
      width: '70%',
      '& .MuiGrid-root .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root':
        {
          height: '60px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: isDarkMode ? theme.colors.white : theme.colors.black,
          borderRadius: '0px',
        },
      '& .MuiGrid-root .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root fieldset':
        {
          border: '0px',
        },
      '& .MuiGrid-root .MuiAutocomplete-root .MuiFormControl-root': {
        margin: '0px',
      },
    },
    wrapInputSearchActive: {
      width: '100%',
      '& .MuiGrid-root .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root':
        {
          height: '60px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: isDarkMode ? theme.colors.white : theme.colors.black,
          borderRadius: '0px',
        },
      '& .MuiGrid-root .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root fieldset':
        {
          border: '0px',
        },
      '& .MuiGrid-root .MuiAutocomplete-root .MuiFormControl-root': {
        margin: '0px',
      },
    },
    closeBtn: {
      color: isDarkMode ? theme.colors.cultured : theme.colors.secondaryLight,
    },
    itemMenu: {
      width: '100%',
      height: '58px',
      lineHeight: '58px',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontSize: '16px',
      fontWeight: 500,
      paddingLeft: '8px',
      '&:hover': {
        backgroundColor: isDarkMode
          ? theme.colors.blackOlive
          : theme.colors.cultured,
      },
    },

    itemMenuActive: {
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      fontWeight: 800,
      '&:hover': {
        backgroundColor: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
      },
    },
    icon: {
      display: 'flex',
      paddingLeft: '8px',
      '& a': {
        marginRight: '16px',
      },
    },
    iconLink: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },
    wrapModal: {
      position: 'fixed',
      left: '15%',
      width: '85%',
      height: '100%',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      padding: '16px',
      overflowY: 'auto',
      [theme.breakpoints.between('md', 1281)]: {
        width: '60%',
        left: 0,
      },
      '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        display: 'none',
        overflow: 'visible',
      },
      '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        width: '0px',
      },
    },
    wrapIconClose: {
      width: '100%',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
    },
    wrapMenuSearch: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    borderSearch: {
      height: '24px',
      width: '1px',
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
    },
    wrapMenu: {
      marginTop: '24px',
    },
    borderTopIcon: {
      width: '100%',
      height: '1px',
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
      margin: '24px 0px',
    },
    borderBottomIcon: {
      width: '100%',
      height: '1px',
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
      margin: '24px 0px 32px 0px',
    },
    wrapButtonConnect: {
      width: '100%',
      height: '60px',
      marginBottom: '32px',
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
    },
    textButton: {
      fontSize: '16px',
      fontWeight: 600,
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      marginRight: '8px',
    },
    avatar: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '16px',
      '& img': {
        objectFit: 'cover',
        borderRadius: '50%',
        outline: '4px solid rgba(255, 255, 255, 0.32)',
        outlineOffset: '-4px',
      },
    },
    wallet: {
      fonWeight: 400,
      fontSize: 12,
      textAlign: 'left',
      lineHeight: '140%',
    },
    addressWallet: {
      fontSize: '20px',
      fontWeight: 700,
      color: isDarkMode ? theme.colors.white : theme.colors.secondaryLight,
    },
    wrapInforUser: {
      display: 'flex',
      height: '64px',
      width: '100%',
      alignItems: 'center',
      marginBottom: '32px',
    },
    wrapDisConnect: {
      width: '100%',
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      marginBottom: '32px',
    },
    textDisConnect: {
      fontSize: '16px',
      fontWeight: 600,
      color: '#4fffa6',
      marginRight: '8px',
    },
    itemNotication: {
      display: 'flex',
      width: '100%',
      height: '60px',
      alignItems: 'center',
      paddingLeft: '8px',
      border: 'none',
      background: 'transparent',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      '&:disabled': {
        color: '#686771',
      },
    },
    textNotication: {
      marginLeft: '10px',
      fontSize: '14px',
      fontWeight: 400,
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontFamily: 'Poppins',
    },
    textNoticationDisabled: {
      color: '#9999A1',
    },
    avatarIcon: {
      width: 52,
      height: 52,
      border: '4px solid #ECEBEC',
    },
    btnMode: {
      height: 60,
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      marginLeft: 8,
      fontSize: '14px',
      fontWeight: 400,
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontFamily: 'Poppins',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
});

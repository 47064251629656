import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import miniumPriceService from 'services/miniumPrice';
import { MiniumPricePerDayResponse } from 'types/miniumPricePerDay';
import { isStringNumber } from 'utils/validateAddNFT';

function ChangePricePerDay() {
  const classes = useStyle();

  const [minPrice, setMinPrice] = useState<
    MiniumPricePerDayResponse | undefined
  >();
  const [newMinPrice, setNewMinPrice] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [success, setSucess] = useState<boolean>(false);

  const fetchData = async () => {
    try {
      const data = await miniumPriceService.GetMiniumPricePerDay({
        key: 'miniumPricePerday',
      });
      setMinPrice(data);
    } catch (error) {
      setMinPrice(undefined);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMinPrice(e.target.value);
    setValid(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !isStringNumber(newMinPrice) ||
      !minPrice ||
      !newMinPrice
      // || Number(newMinPrice) === 0
    ) {
      setValid(false);
      setSucess(false);
      return;
    }
    try {
      await miniumPriceService.UpdateMinPricePerDay({
        id: minPrice.id,
        newValue: newMinPrice,
      });
      fetchData();
      setSucess(true);
    } catch (error) {
      setMinPrice(undefined);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.contanier}>
      <span>Current minimum price per day: ${minPrice?.value}</span>
      <div className={classes.form}>
        <form onSubmit={handleSubmit}>
          <label htmlFor="pricePerDay"></label>
          <input
            className={classes.input}
            name="pricePerDay"
            onChange={handleChange}
          ></input>
          <button className={classes.button} type={'submit'}>
            Save
          </button>
          {!valid && (
            <div className={classes.warning}>Your input is not valid</div>
          )}
          {success && (
            <div className={classes.success}>Your change was apllied</div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ChangePricePerDay;

const useStyle = makeStyles(() => ({
  contanier: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    marginTop: 10,
  },
  input: {
    width: 80,
    marginRight: 3,
  },
  button: {
    cursor: 'pointer',
  },
  warning: {
    marginTop: 3,
    fontSize: 10,
    color: 'red',
  },
  success: {
    marginTop: 3,
    fontSize: 10,
  },
}));

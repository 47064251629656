import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
    label: {
      fontSize: 14,
      lineHeight: '19.6px',
      marginBottom: theme.spacing(1),
    },
    root: {
      paddingRight: 0,
      borderRadius: 0,
      width: 89,
      height: 60,
      '& input': {
        fontSize: 32,
        fontWeight: 500,
        textAlign: 'center',
        paddingRight: 8,
        paddingLeft: 8,
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
      },
      '& input::placeholder': {
        fontSize: 32,
        color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
        fontWeight: 500,
      },
    },
    groupButton: {
      height: 60,
      width: 30,
    },
    upButton: {
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      color: theme.colors.white,
      width: 30,
      height: 30,
      minWidth: 30,
      border: 'unset',
      boxShadow: `-1px 0px ${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.primaryLight
      }`,
    },
    downButton: {
      width: 30,
      height: 30,
      minWidth: 30,
      border: 'unset',
      boxShadow: `-1px 0px ${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.primaryLight
      }`,
      color: theme.colors.primaryLight,
    },
    notchedOutline: {
      borderColor: `${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.primaryLight
      } !important`,
    },
    focused: {
      borderColor: `unset`,
    },
  };
});

import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

const LinkBoldIcon = ({ width = 20, height = 10, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 20 10`}
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M5 1.4C3.01386 1.4 1.4 3.01386 1.4 5C1.4 6.98614 3.01386 8.6 5 8.6H8.5V9.5H5C2.51614 9.5 0.5 7.48386 0.5 5C0.5 2.51614 2.51614 0.5 5 0.5H8.5V1.4H5ZM13.5 4.5V5.5H6.5V4.5H13.5ZM15 9.5H11.5V8.6H15C16.9861 8.6 18.6 6.98614 18.6 5C18.6 3.01386 16.9861 1.4 15 1.4H11.5V0.5H15C17.4839 0.5 19.5 2.51614 19.5 5C19.5 7.48386 17.4839 9.5 15 9.5Z"
        stroke="#4FFFA6"
      />
    </SvgIcon>
  );
};

export default memo(LinkBoldIcon);

export const contents = [
  {
    title: 'Introduction',
    content: `
        <p>How do you sell an NFT for a fair price when thousands of potential buyers don&rsquo;t know your collection or can&rsquo;t see what your NFT looks like? Traditional art galleries and auction houses might take over 20% of your returns, leaving you with even less.</p>
        <p>Our founder once faced this challenge. He searched for a platform where NFTs could be linked and displayed with&nbsp;<strong>no commissions</strong>, ranked based on how much users were willing to pay per day. But nothing of the sort existed, so he started building.</p>
        <p>The purpose of&nbsp;<strong>1001 Squares of NFT</strong>&mdash;the largest ad space for NFTs, is to link the NFT community. Sellers can link NFTs easily in a commission-free environment, while buyers can explore a wide variety of opportunities in a community-led space.</p>  
        <img src="/images/1001_squares_of_nft_introduction.jpg" alt="1001_squares_of_nft_introduction" style="display: block;margin-left: auto; margin-right: auto; width: 100%"/>
    `,
  },
  {
    title: 'Why 1001 Squares of NFT',
    content: `
        <p>Our vision is to create the&nbsp;<strong>front page of NFT</strong>&mdash;the go-to platform where sellers and collections compete for the attention of the entire NFT community.</p>
        <p>NFTs can be linked and displayed across&nbsp;<strong>42 galleries</strong>&nbsp;of 1001 squares, such as&nbsp;<strong>/NFT</strong>,&nbsp;<strong>/SOL</strong>, and&nbsp;<strong>/BAYC</strong>, helping buyers discover NFTs that match their interests.</p>
        <p>We aim to build a home for all NFTs, regardless of blockchain, category, or marketplace. If it can be linked, it can be added to 1001 Squares of NFT.</p>
        <img src="/images/1001_squares_of_nft_galleries.jpg" alt="1001_squares_of_nft_galleries" style="display: block;margin-left: auto; margin-right: auto; width: 100%"/>
    `,
  },
  {
    title: 'Ecosystem',
    content: `
        <p>NFTs can be linked and displayed in each gallery based on their attributes. The main gallery, /NFT, includes all NFTs added, while galleries like /ETH, /AZUKI, or /FREE are automatically attributed to NFTs of specific chains, collections, or rules, at no additional cost.</p>
        <p>Users can choose how much they want to pay (minimum $0.00) and the number of days their NFT will be displayed (no maximum). This duration can be edited later.&nbsp;</p>
        <p>NFTs are ranked on 1001+ squares based on their&nbsp;<strong>ad price per day</strong>, calculated as the total amount paid divided by the number of days the NFT is displayed. You can add NFTs for free for up to&nbsp;<strong>90 days</strong>.</p>
        <p>The&nbsp;<strong>DeadZone</strong>, represented by the yellow rightmost block, contains all NFTs after the first 1001. There is no limit to how many NFTs the DeadZone can hold, but NFTs here cannot be filtered, hence the block&rsquo;s name.</p>
        <img src="/images/1001_squares_of_nft_layout.png" alt="1001_squares_of_nft_layout" style="display: block;margin-left: auto; margin-right: auto; width: 100%"/>
        `,
  },
  {
    title: '$DONE Coin',
    content: `
        <p><strong>$DONE</strong>, on Solana, is the native token of 1001 Squares of NFT. It can be used to add NFTs, alongside other payment methods: <strong>Solana, Ethereum, Avalanche, Polygon, Fantom</strong> and <strong>BNB Smart Chain</strong>.</p>
        <p>The purpose of $DONE is to reward users for adding NFTs. Each time an NFT is added and Solana is converted into $DONE to fulfill the reward, it leads to price appreciation over time, benefiting all $DONE holders.</p>
        <p>In the first month after launch, users will receive&nbsp;<strong>99%</strong>&nbsp;of their ad spend instantly back in $DONE when adding NFTs with Solana or $DONE. Afterwards, this rebate will be reduced to&nbsp;<strong>25%</strong>.</p>
        <img src="/images/done_token_rewards.png" alt="done_token_rewards" style="display: block;margin-left: auto; margin-right: auto; width: 100%"/>
    `,
  },
  {
    title: '$DONE Tokenomics',
    content: `
        <p>The total supply of $DONE is limited to&nbsp;<strong>1,001 tokens.</strong></p>
        <ul>
            <li>
                <p><strong>80%&nbsp;</strong>allocated to the&nbsp;<strong>Rewards Ecosystem</strong></p>
            </li>
            <li>
                <p><strong>20%&nbsp;</strong>allocated for&nbsp;<strong>Liquidity Provision</strong></p>
            </li>
        </ul>
        <p>All tokens will be unlocked from the start and distributed through a fair launch, with no presales or private sales. We are not backed by any VCs or angel investors&mdash;our founder is just one broke guy.</p>
        <img src="/images/done_token_distribution.jpg" alt="done_token_distribution" style="display: block;margin-left: auto; margin-right: auto; width: 100%"/>
      `,
  },
  {
    title: 'About Us',
    content: `
        <p>Inspired by the Million Dollar Homepage created in 2005 by Alex Tew, our mission is to deliver the <strong>#1 NFT space</strong> for the NFT community to link NFTs. We&rsquo;ve partnered with industry-leading auditors to secure our platform&apos;s payment systems because keeping our users and their assets safe is our top priority. (<a style="color: inherit;" href="https://hashlock.com/audits/1001-squares-of-nft">Rust audit</a>,&nbsp;<a style="color: inherit;" href="https://sourcehat.com/audits/1001SquaresofNFT/">Solidity audit</a>)</p>
        <p>Our list of partners (current and previous) includes&nbsp;<strong>Hashlock</strong>,&nbsp;<strong>Twendee</strong>,&nbsp;<strong>SourceHat</strong>,&nbsp;<strong>Omniscia</strong>, and&nbsp;<strong>4ireLabs</strong>.</p>
    `,
  },
  {
    title: 'Conclusion',
    content: `
        <p><strong>1001 Squares of NFT</strong>&mdash;the largest ad space for NFTs&mdash;is the community&rsquo;s gateway to linking, showcasing, and discovering NFTs in a fair and&nbsp;<strong>commission-free environment</strong>. Through our native token&nbsp;<strong>$DONE</strong>, we reward users for adding NFTs, allowing the community to benefit from a growing ecosystem that connects and empowers the entire NFT space.</p>
        <p><strong>Join us</strong>&nbsp;in building the front page of NFT.</p>
    `,
  },
];

import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function BlastMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 400 400"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g
        transform="translate(0,400) scale(0.1,-0.1)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1780 3989 c-438 -51 -850 -245 -1169 -553 -623 -602 -788 -1535
            -407 -2311 443 -902 1466 -1335 2421 -1024 419 137 801 430 1048 804 385 584
            432 1340 123 1970 -301 613 -881 1027 -1556 1110 -133 16 -338 18 -460 4z
            m1342 -1192 c40 -29 101 -73 135 -97 34 -25 63 -49 63 -54 0 -5 -32 -101 -72
            -214 l-72 -205 -156 -74 c-85 -40 -181 -86 -213 -101 l-58 -28 122 -99 c67
            -55 129 -105 138 -112 14 -10 8 -36 -58 -236 l-74 -224 -204 -101 -203 -102
            -795 0 c-437 0 -795 3 -795 8 0 13 412 1297 419 1304 4 5 269 -160 298 -186 1
            -1 -54 -182 -122 -401 -69 -220 -125 -401 -125 -402 0 -2 270 -3 599 -3 l599
            0 15 43 c8 23 42 120 76 215 l60 172 -434 0 c-342 0 -435 3 -435 13 1 6 16 66
            34 132 l34 120 450 3 451 2 11 38 c5 20 27 98 49 172 30 106 36 137 25 143 -7
            4 -497 6 -1088 5 -592 -2 -1076 0 -1076 4 0 3 24 23 53 44 28 21 86 63 127 93
            41 30 115 83 164 118 l89 63 948 0 948 0 73 -53z"
          fill="#f0fc41"
        />
      </g>
    </SvgIcon>
  );
}

export default memo(BlastMenuItem);

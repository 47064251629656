export const getFormData = (object: any) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] instanceof Array) {
      object[key].forEach((item: any) => {
        formData.append(`${key}[]`, item);
      });
    } else {
      formData.append(key, object[key]);
    }
  });

  return formData;
};

import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    direct: {
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: '800px',
      marginTop: 28,
      [theme.breakpoints.up('lg')]: {
        marginTop: 56,
        marginBottom: 20,
      },
    },
    head: {
      fontSize: 45,
      fontWeight: 700,
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
      marginBottom: 16,
      lineHeight: '109%',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('xl')]: {
        fontSize: 50,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 35,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 26,
      },
    },
    subHead: {
      fontSize: 24,
      fontWeight: 300,
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      textAlign: 'center',
      lineHeight: '170%',
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
    btn: {
      display: 'flex',
      marginTop: 52,
    },
    homeButton: {
      cursor: 'pointer',
      border: 'unset',
      background: 'unset',
      color: '#1b1464',
      fontSize: 16,
      fontWeight: 600,
      marginRight: 48,
      fontFamily: 'Poppins, sans-serif',
    },
    addButton: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 158,
      height: 60,
      background: '#4fffa6',
      border: 'unset',
      gap: 12,
      '&>p': {
        color: '#1b1464',
        fontSize: 16,
        fontWeight: 600,
      },
    },
  };
});

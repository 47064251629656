import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CommonDialog from 'components/common/CommonDialog';
import CloseIcon from 'icons/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import { getNFTEstimateAction } from 'store/actions/nftActions';
import { getDialogState } from 'store/selectors';

export default function WarningAddNftDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { props } = useSelector(getDialogState);
  const {
    receiveDone,
    // onClose,
    // onAccept,
  } = props;

  const onClose = () => {
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
  };

  const handleAccept = () => {
    dispatch(
      getNFTEstimateAction({
        days: '14',
        hours: '0',
        months: '0',
        changeUnit: false,
      }),
    );
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
  };

  const valueDone = receiveDone / 10 ** 18;

  return (
    <CommonDialog isOverridden>
      <Box className={classes.conatiner}>
        <Box className={classes.btnContainer}>
          <Button disableRipple className={classes.closeBtn} onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="h2" className={classes.title}>
          Max time for listings under $0.01 exceeded
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Items with a price under $0.01 can only be displayed for a max of 14
          days and you can receive{' '}
          {valueDone <= 0.0001 && valueDone > 0
            ? 0.0001
            : valueDone === 0
            ? 0
            : valueDone}{' '}
          DONE token.
        </Typography>
        <Box className={clsx(classes.wrapperBtn, 'center-root')}>
          <Button onClick={onClose}>
            <Typography style={{ color: '#1b1464' }}>BACK</Typography>
          </Button>
          <Button onClick={handleAccept}>
            <Typography style={{ color: '#1b1464' }}>ACCEPT</Typography>
          </Button>
        </Box>
      </Box>
    </CommonDialog>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 'unset',
  },
  conatiner: {
    width: '100%',
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'column',
  },
  btnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  closeBtn: {
    padding: 0,
    minWidth: 0,
  },
  title: {
    fontSize: 32,
    lineHeight: 1.4,
    fontWeight: 500,
    margin: '16px 21.5px',
    textAlign: 'center',
    color: theme.colors.secondaryLight,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },

  subtitle: {
    fontSize: 16,
    lineHeight: '22.4px',
    fontWeight: 400,
    textAlign: 'center',
    margin: '0px 0px 32px',
    color: theme.colors.secondaryLight,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },

  wrapperBtn: {
    width: '100%',
    marginBottom: 24,
    marginTop: 8,
    '& p': {
      fontSize: 16,
      fontWeight: 600,
    },
    '& button:nth-child(1)': {
      width: 112,
      height: 60,
      '& p': {
        color: '#4fffa6',
      },
    },
    '&>button:nth-child(2)': {
      width: 183,
      height: 60,
      backgroundColor: '#4fffa6',
      marginLeft: 24,
      '& p': {
        color: '#FFFFFF',
      },
    },
  },
}));

import secureStorageUtils from 'utils/secureStorage';
import { MarketActionTypes, MarketState } from 'types/marketState';
import { MarketActionTypeEnum } from './../../enums/actions';
import { SecureStorageEnum } from 'enums/auth';

const { market } = JSON.parse(
  secureStorageUtils.getItem(SecureStorageEnum.ACCOUNT) || '{}',
);

export const initialMarketState: MarketState = {
  name: market || null,
};

export const marketReducer = (
  state = initialMarketState,
  action: MarketActionTypes,
) => {
  switch (action.type) {
    case MarketActionTypeEnum.GET_MARKET: {
      return {
        ...state,
        name: action.payload,
      };
    }
    default:
      return state;
  }
};

import { Box, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  AdminChartFilterByCurrency,
  AdminChartFilterByDate,
} from 'enums/filter';
import BarChart from 'components/Chart';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionVolumeAction } from 'store/actions/transactionActions';
import { getTransactionVolume } from 'store/selectors';

interface IData {}

const Volume = (props: IData) => {
  const [time, setTime] = useState<AdminChartFilterByDate>(
    AdminChartFilterByDate.PAST_7_DAYS,
  );
  const [currency, setCurrency] = useState<AdminChartFilterByCurrency>(
    AdminChartFilterByCurrency.ALL_CURRENCIES,
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const volume = useSelector(getTransactionVolume);

  const handleChangeTime = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTime(event.target.value as AdminChartFilterByDate);
  };

  const handleChangeCurrency = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setCurrency(event.target.value as AdminChartFilterByCurrency);
  };

  useEffect(() => {
    dispatch(
      getTransactionVolumeAction({
        time,
        currency,
      }),
    );
  }, [time, currency, dispatch]);

  return (
    <Box>
      <Box>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            value={time}
            onChange={handleChangeTime}
            className={classes.root}
            MenuProps={{
              classes: { paper: classes.dropdownStyle },
              variant: 'menu',
            }}
          >
            {Object.values(AdminChartFilterByDate).map((a) => (
              <MenuItem value={a}>{a}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            value={currency}
            onChange={handleChangeCurrency}
            classes={{
              root: classes.root,
            }}
            className={classes.root}
            MenuProps={{
              classes: { paper: classes.dropdownStyle },
              variant: 'menu',
            }}
          >
            {Object.values(AdminChartFilterByCurrency).map((a) => (
              <MenuItem value={a}>{a}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box className={classes.chart}>
        <BarChart volume={volume} />
      </Box>
    </Box>
  );
};

export default Volume;

const useStyles = makeStyles((theme) => ({
  root: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid rgba(0,0,0,1)',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  dropdownStyle: {
    backgroundColor: '#FFFFFF',
  },
  chart: {
    width: '100%',
    height: 'fit-content',
    border: '1px solid #1b1464',
  },
}));

import { Avatar, Box, Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { renderShortAddress } from 'common/helper';
import LogoutIcon from 'icons/LogoutIcon';
import { isMobileFn } from 'common/helper';
import OpenApp from 'react-open-app';
import React, { useState } from 'react';
import { WalletEnum } from 'enums/wallet';
import nacl from 'tweetnacl';
import bs58 from 'bs58';
import { useWallet } from '@solana/wallet-adapter-react';
import { isIOS } from 'utils/browser';

interface IConnectMethod {
  data: {
    logoUrl: string;
    name: WalletEnum;
  };
  active: boolean;
  address?: string;
  disabled: boolean;
  onLogin: (name: WalletEnum) => void;
  onLogout: () => void;
  wallets: any;
}

const ConnectMethod = ({
  data,
  onLogin,
  active,
  onLogout,
  address,
  disabled,
}: IConnectMethod) => {
  const { select, wallets } = useWallet();
  const [dappKeyPair] = useState(nacl.box.keyPair());
  const classes = useStyles();
  const isMobile = isMobileFn();
  const { ethereum } = window;

  const PHANTOM_PARAMS = new URLSearchParams({
    publicKey: bs58.encode(dappKeyPair.publicKey),
    app_url: 'https://1001squaresofnft.com/',
    cluster: 'devnet',
  });

  const UNIVERSAL_LINKS = {
    MetaMask: 'https://metamask.app.link/dapp/mintedgem.com',
    Phantom: `https://phantom.app/ul/v1/connect?${PHANTOM_PARAMS.toString()}`,
  };

  const renderAddress = () => {
    return (active && address && renderShortAddress(address, 6, 17)) || '';
  };

  const onChangeWallet = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (isMobile && !ethereum && data.name === WalletEnum.META_MASK) {
      return;
    }
    e.stopPropagation();
    !disabled && onLogin(data.name);
  };

  const onRemoveLoginSession = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    onLogout();
  };

  return (
    <Box
      className={clsx(classes.main, {
        [classes.backgroundActive]: active,
        [classes.disabled]: disabled,
      })}
      onClick={onChangeWallet}
    >
      {isMobile && !ethereum && data.name === WalletEnum.META_MASK ? (
        <OpenApp
          style={{ textDecoration: 'none' }}
          href={UNIVERSAL_LINKS[data.name]}
        >
          <Box display="flex" alignItems="center">
            <Avatar src={data.logoUrl} className={classes.img} />
            <Box className={classes.wrapper}>
              <Typography>{data.name}</Typography>
              <Typography>{renderAddress()}</Typography>
            </Box>
          </Box>
        </OpenApp>
      ) : isMobile && !ethereum && data.name === WalletEnum.PHANTOM ? (
        <>
          {wallets.map((wallet) => {
            if (!isIOS() && wallet.adapter.name === 'Mobile Wallet Adapter') {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => select(wallet.adapter.name)}
                >
                  <Avatar
                    src={'/images/phantom-wallet.png'}
                    className={classes.img}
                  />
                  <Box className={classes.wrapper}>
                    <Typography>{WalletEnum.PHANTOM}</Typography>
                    <Typography>{renderAddress()}</Typography>
                  </Box>
                </Box>
              );
            }

            if (isIOS() && wallet.adapter.name === 'Phantom') {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => select(wallet.adapter.name)}
                >
                  <Avatar
                    src={'/images/phantom-wallet.png'}
                    className={classes.img}
                  />
                  <Box className={classes.wrapper}>
                    <Typography>{WalletEnum.PHANTOM}</Typography>
                    <Typography>{renderAddress()}</Typography>
                  </Box>
                </Box>
              );
            }

            return null;
          })}
        </>
      ) : (
        <Box display="flex" alignItems="center">
          <Avatar src={data.logoUrl} className={classes.img} />
          <Box className={classes.wrapper}>
            <Typography>{data.name}</Typography>
            <Typography>{renderAddress()}</Typography>
          </Box>
        </Box>
      )}
      {active && (
        <Button className={classes.logout} onClick={onRemoveLoginSession}>
          <LogoutIcon />
        </Button>
      )}
    </Box>
  );
};

export default ConnectMethod;

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
  },
  backgroundActive: {
    backgroundColor: 'rgba(111, 107, 197, 0.04)',
  },
  wrapper: {
    marginLeft: 10,
    '& p:first-child': {
      fontSize: 12,
      fontWeight: 600,
      textTransform: 'none',
      width: 'fit-content',
    },
    '& p:nth-child(2)': {
      fontSize: 11,
      fontWeight: 400,
      color: '#4fffa6',
    },
  },
  logout: {
    minWidth: 0,
    height: 'fit-content',
    '& svg': {
      width: 16,
      height: 16,
      color: '#4fffa6',
    },
  },
  disabled: {
    opacity: 0.7,
  },
  img: {
    width: 40,
    height: 40,
    // border: '2px solid rgba(111, 107, 197, 0.16)',
    '& img': {
      objectFit: 'contain',
    },
  },
}));

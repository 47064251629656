import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    noWrapContainer: {
      flexWrap: 'wrap',
    },
    expandedMenu: {
      marginBottom: 16,
    },
    defaultCatItem: {
      display: 'flex',
      width: 'auto',
      padding: '5px 4px',
      borderRadius: '16px',
      alignItems: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
    },
    notLastDefaultCateItem: {
      marginRight: 8,
    },
    avatar: {
      width: 24,
      height: 24,
      marginRight: 6,
    },
    defaultCatName: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '140%',
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      paddingRight: 6,
    },
    defaultCatNumberItem: {
      marginLeft: 'auto',
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
    },
    countRemainingCat: {
      display: 'flex',
      width: 'auto',
      padding: '5px 7px 4px 5px',
      borderRadius: '18px',
      alignItems: 'center',
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '140%',
      minWidth: 54,
      justifyContent: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      height: 36,
    },
    arrowDownIconOutnerContainer: {
      alignItems: 'center',
    },
    arrowDownIconInnnerContainer: {
      marginLeft: 'auto',
      cursor: 'pointer',
      '& svg': {
        marginLeft: 'auto',
      },
    },
    upArrow: {
      '& svg': {
        rotate: '180deg',
      },
    },
    catItem: {
      padding: '16px 8px',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T30}`,
      alignItems: 'center',
    },
    header: {
      padding: '16px 8px',
    },
    pathLabel: {
      width: 'calc(100% - 208px)',
    },
    positionLabel: {
      width: 208,
    },
    catAvatar: {
      width: 28,
    },
    avatarInList: {
      width: 24,
      height: 24,
      marginRight: 4,
    },
    catName: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '140%',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      textTransform: 'uppercase',
      width: 'calc(100% - 240px)',
    },
    position: {
      width: 140,
    },
    fontStyle: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '140%',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },
    closeIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 68,
    },
    closeIcon: {
      cursor: 'pointer',
    },
    label: {
      fontSize: 16,
      fontFamily: 'Poppins',
      fontWeight: 700,
      lineHeight: '140%',
      paddingBottom: 16,
    },
    tableMobile: {
      marginTop: 24,
      paddingTop: 24,
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T30}`,
    },
  };
});

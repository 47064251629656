import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function PrevIcon({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 60 60"
      style={{ transform: 'scale(1)', width, height, fill: 'none' }}
      fill="none"
    >
      <path
        d="M28.4761 29.6468L28.1237 30L28.4761 30.3532L32.7033 34.5896L32 35.2929L26.7071 30L32 24.7071L32.7033 25.4104L28.4761 29.6468Z"
        fill={color}
        stroke={color}
      />
      <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke={color} />
    </SvgIcon>
  );
}

export default memo(PrevIcon);

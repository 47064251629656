import { Box } from '@material-ui/core';
import CommonDialog from 'components/common/CommonDialog';
import CommonSnackBar from 'components/common/CommonSnack';
import React, { ElementType, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { getDialogState } from 'store/selectors';

export interface AdminLayoutProps extends RouteProps {
  RenderComponent: ElementType;
}

interface LayoutProps {
  routeProps: RouteComponentProps;
  RenderComponent: ElementType;
  path?: string | string[];
}

const Layout = (props: LayoutProps) => {
  const { routeProps, RenderComponent } = props;
  const dialog = useSelector(getDialogState);

  return (
    <Box>
      <RenderComponent {...routeProps} />
      <CommonSnackBar />
      <CommonDialog>
        <dialog.component />
      </CommonDialog>
    </Box>
  );
};

const AdminLayout = ({ RenderComponent, ...rest }: AdminLayoutProps) => {
  const render = useCallback(
    (routeProps: RouteComponentProps) => {
      return (
        <Layout
          routeProps={routeProps}
          RenderComponent={RenderComponent}
          path={rest.path as any}
        />
      );
    },
    [RenderComponent, rest.path],
  );

  return <Route {...rest} render={render} />;
};

export default AdminLayout;

import { SvgIcon } from '@material-ui/core';
import { IIconProperty } from 'common/type';
import { memo } from 'react';

const UploadIcon = ({ width, height, color }: IIconProperty) => {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox={`0 0 40 40`}
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        d="M36.668 21.667C36.2259 21.667 35.802 21.8426 35.4895 22.1551C35.1769 22.4677 35.0013 22.8916 35.0013 23.3337V30.3553C35 31.5868 34.5102 32.7676 33.6394 33.6384C32.7685 34.5092 31.5878 34.999 30.3563 35.0003H9.6463C8.41478 34.999 7.23407 34.5092 6.36325 33.6384C5.49243 32.7676 5.00263 31.5868 5.0013 30.3553V23.3337C5.0013 22.8916 4.82571 22.4677 4.51315 22.1551C4.20059 21.8426 3.77666 21.667 3.33464 21.667C2.89261 21.667 2.46868 21.8426 2.15612 22.1551C1.84356 22.4677 1.66797 22.8916 1.66797 23.3337V30.3553C1.67017 32.4706 2.51146 34.4987 4.00721 35.9944C5.50296 37.4902 7.53099 38.3315 9.6463 38.3337H30.3563C32.4716 38.3315 34.4997 37.4902 35.9954 35.9944C37.4911 34.4987 38.3324 32.4706 38.3346 30.3553V23.3337C38.3346 22.8916 38.159 22.4677 37.8465 22.1551C37.5339 21.8426 37.11 21.667 36.668 21.667Z"
        fill="currentColor"
      />
      <path
        d="M11.18 14.5116L18.335 7.35664V28.3333C18.335 28.7753 18.5106 29.1993 18.8231 29.5118C19.1357 29.8244 19.5596 30 20.0017 30C20.4437 30 20.8676 29.8244 21.1802 29.5118C21.4927 29.1993 21.6683 28.7753 21.6683 28.3333V7.35664L28.8233 14.5116C29.1377 14.8152 29.5587 14.9832 29.9957 14.9794C30.4327 14.9756 30.8507 14.8003 31.1597 14.4913C31.4687 14.1823 31.644 13.7643 31.6478 13.3273C31.6516 12.8903 31.4836 12.4693 31.18 12.155L21.18 2.15497C20.8674 1.84252 20.4436 1.66699 20.0017 1.66699C19.5597 1.66699 19.1359 1.84252 18.8233 2.15497L8.82332 12.155C8.51973 12.4693 8.35173 12.8903 8.35553 13.3273C8.35933 13.7643 8.53461 14.1823 8.84362 14.4913C9.15264 14.8003 9.57066 14.9756 10.0077 14.9794C10.4446 14.9832 10.8657 14.8152 11.18 14.5116Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default memo(UploadIcon);

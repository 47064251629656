import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      paddingBottom: 32,
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,

      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    valueRow: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 24,
      },
    },
    ethValueBox: {
      paddingLeft: 16,
      width: 180,
      height: 60,
      backgroundColor: '#4fffa614',
      border: '1px solid #4fffa6',
      '& > svg': {
        marginRight: 8,
      },
    },
    priceValueBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
      paddingRight: 16,
      width: 133,
      height: 60,
      backgroundColor: isDarkMode
        ? `${theme.colors.secondaryDark}${TRANSPARENCY.T10}`
        : `${theme.colors.primaryLight}${TRANSPARENCY.T10}`,
    },
    priceValueWrapper: {
      marginLeft: 16,
    },
    priceValueBoxPrice: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '32px',
      color: '#4fffa6',
    },
    priceValueBoxPricePerDay: {
      fontWeight: 400,
      fontSize: '8px',
      lineHeight: '140%',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontFamily: 'Roboto',
    },
    lastTimeBox: {
      marginRight: 52,
    },
    hourTimeBox: {
      width: 'auto',
      minWidth: 60,
      paddingLeft: 10,
      paddingRight: 10,
    },
    value: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: '#4fffa6',
      fontSize: 20,
      fontWeight: 700,
    },
  };
});

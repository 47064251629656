import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {},
  item: {
    marginRight: 32,
    [theme.breakpoints.between(744, 1440)]: {
      marginRight: 37,
    },
    '& input': {
      fontWeight: 700,
    },
  },
}));

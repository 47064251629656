import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function Settings({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 25"
      style={{ transform: 'scale(1)', width, height }}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M19.1401 13.4404C19.1801 13.1404 19.2001 12.8304 19.2001 12.5004C19.2001 12.1804 19.1801 11.8604 19.1301 11.5604L21.1601 9.98039C21.3401 9.84039 21.3901 9.57039 21.2801 9.37039L19.3601 6.05039C19.2401 5.83039 18.9901 5.76039 18.7701 5.83039L16.3801 6.79039C15.8801 6.41039 15.3501 6.09039 14.7601 5.85039L14.4001 3.31039C14.3601 3.07039 14.1601 2.90039 13.9201 2.90039H10.0801C9.84011 2.90039 9.65011 3.07039 9.61011 3.31039L9.25011 5.85039C8.66011 6.09039 8.12011 6.42039 7.63011 6.79039L5.24011 5.83039C5.02011 5.75039 4.77011 5.83039 4.65011 6.05039L2.74011 9.37039C2.62011 9.58039 2.66011 9.84039 2.86011 9.98039L4.89011 11.5604C4.84011 11.8604 4.80011 12.1904 4.80011 12.5004C4.80011 12.8104 4.82011 13.1404 4.87011 13.4404L2.84011 15.0204C2.66011 15.1604 2.61011 15.4304 2.72011 15.6304L4.64011 18.9504C4.76011 19.1704 5.01011 19.2404 5.23011 19.1704L7.62011 18.2104C8.12011 18.5904 8.65011 18.9104 9.24011 19.1504L9.60011 21.6904C9.65011 21.9304 9.84011 22.1004 10.0801 22.1004H13.9201C14.1601 22.1004 14.3601 21.9304 14.3901 21.6904L14.7501 19.1504C15.3401 18.9104 15.8801 18.5904 16.3701 18.2104L18.7601 19.1704C18.9801 19.2504 19.2301 19.1704 19.3501 18.9504L21.2701 15.6304C21.3901 15.4104 21.3401 15.1604 21.1501 15.0204L19.1401 13.4404ZM12.0001 16.1004C10.0201 16.1004 8.40011 14.4804 8.40011 12.5004C8.40011 10.5204 10.0201 8.90039 12.0001 8.90039C13.9801 8.90039 15.6001 10.5204 15.6001 12.5004C15.6001 14.4804 13.9801 16.1004 12.0001 16.1004Z"
        fill={color}
      />
    </SvgIcon>
  );
}

export default memo(Settings);

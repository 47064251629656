import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    logoApp: {
      height: 40,
      marginTop: 10,
      marginBottom: 10,
    },
    container: {
      height: 211.25,
      position: 'relative',
      backgroundColor: isDarkMode ? theme.colors.blackOlive : '#F6F6F9',
      [theme.breakpoints.down('sm')]: {
        height: 466.25,
      },
    },
    topContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftItem: {
      width: 250,
      height: 108.25,
      marginTop: 32,
      marginLeft: 32,
      marginRight: 16,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 24,
        marginRight: 24,
        width: 'unset',
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 132,
      },
      '&>svg': {
        marginBottom: 24,
      },
    },
    midleItem: {
      marginTop: 44.25,
      width: 212,
      borderLeft: '1px solid',
      borderColor: isDarkMode
        ? `${theme.colors.cultured}${TRANSPARENCY.T30}`
        : `${theme.colors.blackOlive}${TRANSPARENCY.T30}`,

      paddingLeft: 30,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '0 24px',
        marginTop: 16,
        borderLeft: 'unset',
      },
      '&>a': {
        textDecoration: 'none',
      },
      '&>a>p': {
        width: 'fit-content',
        padding: '10px',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: '12px',
        '&:hover': {
          fontWeight: 700,
          color: isDarkMode
            ? theme.colors.secondaryDark
            : theme.colors.primaryLight,
        },
        [theme.breakpoints.down('sm')]: {
          padding: '16px',
          fontSize: 16,
          paddingLeft: 10,
        },
      },
    },
    rightItem: {
      width: 190,
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      '&>p': {
        fontWeight: 400,
        fontSize: 11,
        lineHeight: '140%',
        color: isDarkMode ? theme.colors.white : theme.colors.black,
      },
      '& svg': {
        cursor: 'pointer',
      },
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        top: 105.25,
        right: 32,
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignSelf: 'center',
        marginTop: 58,
      },
    },
    socialIcon: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 18,
      },
    },
    textFooter: {
      fontWeight: 400,
      fontSize: 11,
      lineHeight: '140%',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },
    copyRight: {
      width: '100%',
      height: 31,
      backgroundColor: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      [theme.breakpoints.down('sm')]: {
        height: 47,
      },
      '& > p': {
        fontWeight: 400,
        fontSize: 11,
        lineHeight: '140%',
        color: ' rgba(255, 255, 255, 0.8)',
      },
    },
    iconLinkWrapper: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& a': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
    iconWrapSecond: {
      margin: '0 16px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 24px',
      },
    },
    linkRouter: {
      color: 'unset',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    link: {
      fontWeight: 700,
      marginLeft: 5,
      '&:hover': {
        textDecoration: 'underline solid white 1px',
      },
    },

    dot: {
      fontSize: 13,
      marginLeft: 5,
    },
    iconLink: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
    },

    linkActive: {
      '&>p': {
        fontWeight: '700 !important',
        color: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight + ' !important',
      },
    },
  };
});

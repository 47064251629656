import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';
export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      marginRight: 24,
    },
    label: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      marginBottom: 8,
    },
    value: {
      display: 'grid',
      '& > div': {
        margin: 'auto',
        color: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
      },
      width: 60,
      height: 60,
      backgroundColor: isDarkMode
        ? `${theme.colors.secondaryDark}${TRANSPARENCY.T10}`
        : `${theme.colors.primaryLight}${TRANSPARENCY.T10}`,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '140%',
    },
  };
});

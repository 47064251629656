import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
    label: {
      fontSize: 14,
      lineHeight: '19.6px',
      marginBottom: theme.spacing(1),
    },
    root: {
      height: 60,
      paddingRight: 0,
      borderRadius: 0,
      position: 'relative',
      '& input': {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '140%',
        paddingTop: 16,
        paddingBottom: 16,
        [theme.breakpoints.down('sm')]: {
          height: 45,
          fontSize: 18,
          paddingTop: 7.5,
          paddingBottom: 7.5,
        },
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
      },
      '& input::placeholder': {
        color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '140%',
        paddingTop: 16,
        paddingBottom: 16,
        [theme.breakpoints.down('sm')]: {
          height: 45,
          fontSize: 18,
          paddingTop: 7.5,
          paddingBottom: 7.5,
        },
      },
      '& input:focus::placeholder': {
        color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
      },
    },
    rootDeskop: {
      width: 286.84,
      '& input': {
        width: 163,
      },
    },
    rootTablet: {
      width: 286.84,
      '& input': {
        width: 163,
      },
    },
    rootMoblie: {
      width: 181,
      '& input': {
        width: 92,
      },
    },
    notchedOutline: {
      borderColor: `${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.primaryLight
      } !important`,
    },
    focused: {
      borderColor: `unset`,
    },
    endAdornment: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      height: '100%',
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      fontWeight: 800,
      cursor: 'grabbing',
      position: 'absolute',
      right: 0,
    },
    endAdornmentDeskop: {
      fontSize: 16,
      width: 124.84,
    },
    endAdornmentTablet: {
      fontSize: 12,
      width: 124.84,
    },
    endAdornmentMoblie: {
      fontSize: 12,
      width: 89,
    },
    endAdornmentLabel: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    unitDeskop: {
      textTransform: 'uppercase',
      paddingLeft: 8,
      paddingRight: 22,
    },
    unitTabletAndMoblie: {
      textTransform: 'uppercase',
      paddingLeft: 8,
      paddingRight: 12,
    },
    currencyIcon: {
      marginRight: theme.spacing(1),
    },
    arrowDownIcon: {
      marginLeft: 22,
    },
    menu: {
      position: 'absolute',
      top: 60,
      right: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : theme.colors.white,
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      padding: '8px',
    },
    menuItem: {
      height: 48,
      boxSizing: 'border-box',
      display: 'flex',
      width: '100%',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: isDarkMode
        ? `${theme.colors.secondaryDark}${TRANSPARENCY.T15}`
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
      fontSize: 14,
      letterSpacing: 0.015,
      fontWeight: 400,
      paddingLeft: 8,
      '&:hover': {
        backgroundColor: isDarkMode
          ? `${theme.colors.secondaryDark}${TRANSPARENCY.T50}`
          : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
      },
    },

    unitItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative',
      top: 4,
    },
    unit: {
      marginLeft: 12,
    },
    unitTooltip: {
      marginLeft: 12,
      fontWeight: 400,
      fontSize: 10,
      color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
      opacity: 0.5,
      position: 'relative',
      top: -4,
    },
    iconBox: {
      width: '10px',
      height: '10px',
      backgroundColor: '#13B5EC',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    ftmIcon: {
      display: 'inline-flex',
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: '#13B5EC',
      alignItems: 'center',
      justifyContent: 'center',
    },
    valueEqual0: {
      color: '#B3AEB4',
    },
  };
});

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    main: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0px 16px',
      padding: '0px 8px',
      height: 41,
      width: 'auto',
      background: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      '& p': {
        fontWeight: 600,
        fontSize: 12,
        color: isDarkMode ? theme.colors.white : theme.colors.black,
        textTransform: 'uppercase',
      },
      borderBottom: '0px !important',
    },
    wrapClose: {
      display: 'flex',
      width: '12%',
      [theme.breakpoints.between('md', 'lg')]: {
        width: '16%',
      },
      '& button': {
        padding: 0,
        minWidth: 20,
        marginRight: 8,
      },
      '&>button svg:last-child': {
        height: 16,
        color: isDarkMode ? theme.colors.white : theme.colors.black,
      },
    },
    textItem: {
      color: '#4fffa6',
      fontWeight: 600,
      fontSize: 12,
      textTransform: 'uppercase',
    },
    selectedBackgroundColor: {
      backgroundColor: isDarkMode
        ? theme.colors.primaryLight50
        : theme.colors.secondaryLight30,
    },
  };
});

import React, { useState, useCallback } from 'react';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import CommonInput from 'components/common/CommonInput';
import CommonUploadFile from 'components/common/CommonUploadFileInput/CommonUploadFileInput';
import { SponsorCreate } from 'types/sponsor';
import SponsorService from 'services/sponsorAds';
import { useStyles } from '../SponsorAdsStyle';
import PositionInput from 'components/common/PositionInput';
import { useDispatch } from 'react-redux';
import { updateAppStateAction } from 'store/actions/appActions';

interface ICreateSponsorAd {
  openDialog: boolean;
  handleCloseModal: () => void;
  reloadSponsorAds: () => void;
}

const defaultSponsorAd = {
  file: '',
  displayLink: '',
  rate: '',
  description: '',
  trueLink: '',
  positions: '',
};

const CreateSponsorAd = (props: ICreateSponsorAd) => {
  const classes = useStyles();
  const { openDialog, handleCloseModal, reloadSponsorAds } = props;
  const dispatch = useDispatch();
  const [data, setData] = useState<SponsorCreate[]>([defaultSponsorAd]);

  const handleClose = useCallback(() => {
    setData([
      {
        file: '',
        displayLink: '',
        rate: '',
        description: '',
        trueLink: '',
        positions: '',
      },
    ]);
    handleCloseModal();
  }, [handleCloseModal, setData]);

  const handleChangeFile = useCallback(
    (event: React.ChangeEvent<any>, index: number) => {
      const file = URL.createObjectURL(event.target.files[0]);
      const newData = [...data];
      newData[index].file = file;
      setData(newData);
    },
    [data, setData],
  );

  const handleDeleteFile = useCallback(
    (index: number) => {
      const newData = [...data];
      newData[index].file = '';
      setData(newData);
    },
    [data, setData],
  );

  const handleChangeTextField = useCallback(
    (field: keyof SponsorCreate) =>
      (event: React.ChangeEvent<any>, index: number) => {
        const newData = [...data];
        newData[index][field] =
          field === 'positions' ? event : event.target.value;
        setData(newData);
      },
    [data, setData],
  );

  const handleAddPosition = useCallback(
    (position: string, index: number) => {
      const newData = [...data];
      newData[index].positions = position;
      setData(newData);
    },
    [data, setData],
  );

  const handleAddRow = useCallback(() => {
    const newData = [
      ...data,
      {
        file: '',
        displayLink: '',
        rate: '',
        description: '',
        trueLink: '',
        positions: '',
      },
    ];
    setData(newData);
  }, [data, setData]);

  const handleCreatSponsorAds = useCallback(() => {
    let bodyFormData = new FormData();
    let objData = data.map((o, i) => {
      return {
        displayLink: o.displayLink,
        description: o.description,
        trueLink: o.trueLink,
        rate: +o.rate || 10,
        positions: o.positions,
      };
    });

    bodyFormData.append('data', JSON.stringify(objData));

    for (let i = 0; i < data.length; i++) {
      fetch(data[i].file)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], `image${i}.png`, {
            type: blob.type,
          });
          bodyFormData.append('files', file);
          if (i === data.length - 1) {
            SponsorService.CreateListSponsorAds(bodyFormData)
              .then(() => {
                handleClose();
                reloadSponsorAds();
                dispatch(
                  updateAppStateAction({
                    isShowSnack: true,
                    snackContent: 'Created successfully',
                    type: 'success',
                    snackLabel: 'Success',
                  }),
                );
              })
              .catch((err) =>
                dispatch(
                  updateAppStateAction({
                    isShowSnack: true,
                    snackContent: err?.message || 'Something went wrong',
                    type: 'error',
                    snackLabel: 'Error',
                  }),
                ),
              );
          }
        });
    }
  }, [data, handleClose, reloadSponsorAds, dispatch]);

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="lg"
      className={classes.wrapperDialog}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Typography>File</Typography>
          <Typography>Website name</Typography>
          <Typography>Website link</Typography>
          <Typography>Description</Typography>
          <Typography>Show time %</Typography>
          <Typography>Position</Typography>
        </Box>
        {data.map((item, i) => {
          return (
            <Box display="flex" key={i} className={classes.wrapperItem}>
              <Box className={classes.item}>
                <CommonUploadFile
                  label="File Images"
                  file={data[i].file}
                  onChange={(event) => handleChangeFile(event, i)}
                  onDeleteImage={() => handleDeleteFile(i)}
                />
              </Box>
              <Box className={classes.item}>
                <CommonInput
                  value={data[i].displayLink}
                  onChange={(event) =>
                    handleChangeTextField('displayLink')(event, i)
                  }
                />
              </Box>
              <Box className={classes.item}>
                <CommonInput
                  value={data[i].trueLink}
                  onChange={(event) =>
                    handleChangeTextField('trueLink')(event, i)
                  }
                />
              </Box>
              <Box className={classes.item}>
                <CommonInput
                  value={data[i].description}
                  onChange={(event) =>
                    handleChangeTextField('description')(event, i)
                  }
                />
              </Box>
              <Box className={classes.item}>
                <CommonInput
                  value={data[i].rate}
                  onChange={(event) => handleChangeTextField('rate')(event, i)}
                />
              </Box>
              <Box className={classes.item}>
                <PositionInput
                  position={data[i].positions}
                  onAdd={(posArr) => handleAddPosition(posArr, i)}
                  type="number"
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Box className={classes.wrapperAddRow}>
        <Button className={classes.addRowButton} onClick={handleAddRow}>
          + Add row
        </Button>
      </Box>
      <Box className={classes.confirmButton}>
        <Button onClick={handleCreatSponsorAds}>Create advertisement</Button>
      </Box>
    </Dialog>
  );
};

export default CreateSponsorAd;

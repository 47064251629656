export const contents = [
  {
    tit: 'Display NFTs from $0.00 a day in /ENS.',
    text: 'Add NFT links from OpenSea, LooksRare & Magic Eden.',
    imgURL: '/images/about-img/about_1.jpeg',
    isNewline: true,
  },
  {
    tit: 'Rank NFTs in 42+ /categories.',
    text: 'Place NFTs first based on square price per day.',
    imgURL: '/images/about-img/about_2.JPG',
    isNewline: true,
  },
  {
    tit: '​​​​Display NFTs for any time.',
    text: 'Pay with ETH, BNB, POLYGON, AVAX or FTM.',
    imgURL: '/images/about-img/about_3.jpeg',
    isNewline: true,
  },
];

/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { hasMore } from 'common/helper';
import IncomingTransactionItem from 'components/IncomingTransactionItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { getTransactionAction } from 'store/actions/transactionActions';
import { getTransactionPagination, getTransactions } from 'store/selectors';
import nftService from 'services/nfts';
import { NFTPriceResponseType } from 'types/nft';
import { getTransactionSearchParam } from 'store/selectors';
import TransactionFilter from 'components/TransactionFilter';
import { TransactionActionEnum } from 'enums/actions';
import { sTaskStatus } from 'store/selectors';
import SearchIcon from 'icons/SearchIcon';

interface IData {}

const IncomingTransaction = (props: IData) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const transactions = useSelector(getTransactions);
  const pagination = useSelector(getTransactionPagination);
  const tranSearchParam = useSelector(getTransactionSearchParam);
  const loading = useSelector(
    sTaskStatus(TransactionActionEnum.GET_ALL_TRANSACTIONS),
  )?.processing;

  const [currency, setCurrency] = useState<NFTPriceResponseType | undefined>(
    [],
  );

  const searchParam = useMemo(() => {
    Object.keys(tranSearchParam).forEach((key) => {
      if ((tranSearchParam as any)[key] === undefined) {
        delete (tranSearchParam as any)[key];
      }
    });
    return tranSearchParam;
  }, [tranSearchParam]);

  const fetchData = async () => {
    try {
      const data = await nftService.getNFTPriceBaseOnDollar();
      setCurrency(data);
    } catch (error) {
      setCurrency(undefined);
    }
  };

  const hasMoreTransaction = useMemo(() => {
    return hasMore(pagination);
  }, [pagination]);

  useEffect(() => {
    dispatch(
      getTransactionAction({
        pageNumber: 1,
        pageSize: 20,
      }),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const loadMoreTransactions = useCallback(() => {
    dispatch(
      getTransactionAction({
        pageNumber: pagination.pageNumber + 1,
        pageSize: pagination.pageSize,
        ...searchParam,
      }),
    );
  }, [pagination, searchParam]);
  return (
    <>
      <TransactionFilter />
      {transactions.length === 0 && !loading ? (
        <div className={classes.notFound}>
          <SearchIcon width={50} height={50} />
          <span>Not found !</span>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={transactions.length}
          next={loadMoreTransactions}
          hasMore={hasMoreTransaction}
          className={classes.main}
          loader={<p>Loading...</p>}
        >
          {transactions.map((t) => (
            <IncomingTransactionItem key={t.id} data={t} currency={currency} />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default IncomingTransaction;

const useStyles = makeStyles((theme) => ({
  main: {},
  searchArea: {
    position: 'fixed',
    top: 104,
    right: 30,
  },
  filterWrapper: {
    display: 'flex',
  },
  filterName: {
    marginRight: 15,
  },
  titleSearch: {
    fontWeight: 700,
  },
  notFound: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 20,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    '& >span': {
      marginLeft: 10,
    },
  },
}));

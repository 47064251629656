import { makeStyles, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';
import { TRANSPARENCY } from 'material/colors';
import React from 'react';

interface IInput {
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder?: string;
  name?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
}
const CommonInput = ({
  value,
  onChange,
  className,
  name,
  multiline,
  rows,
  placeholder,
  type,
}: IInput) => {
  const classes = useStyles();

  return (
    <OutlinedInput
      value={value}
      onChange={onChange}
      name={name}
      multiline={multiline}
      rows={rows}
      type={type}
      classes={{
        root: classes.root,
        focused: classes.focused,
        notchedOutline: classes.notchedOutline,
      }}
      className={clsx(className, classes.root)}
      placeholder={placeholder}
    />
  );
};

export default CommonInput;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    root: {
      width: '100%',
      borderRadius: 0,
      '& input': {
        height: 0,
        fontSize: 14,
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.secondaryLight,
      },
      '& input::placeholder': {
        fontSize: 14,
        color: '',
      },
    },
    notchedOutline: {
      borderColor: isDarkMode
        ? `${theme.colors.cultured}${TRANSPARENCY.T40}`
        : `${theme.colors.blackOlive}${TRANSPARENCY.T40}`,
    },
    focused: {},
  };
});

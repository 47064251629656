import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function EthMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 12 12"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M8.63153 4.25074C8.44458 4.14392 8.20423 4.14392 7.99058 4.25074L6.49503 5.13205L5.48019 5.69288L4.01135 6.57418C3.8244 6.68101 3.58405 6.68101 3.3704 6.57418L2.22203 5.87982C2.03509 5.773 1.90156 5.55935 1.90156 5.31899V3.98368C1.90156 3.77003 2.00838 3.55638 2.22203 3.42285L3.3704 2.75519C3.55734 2.64837 3.7977 2.64837 4.01135 2.75519L5.15972 3.44955C5.34666 3.55638 5.48019 3.77003 5.48019 4.01039V4.89169L6.49503 4.30415V3.39614C6.49503 3.18249 6.3882 2.96884 6.17455 2.83531L4.03805 1.58012C3.85111 1.47329 3.61075 1.47329 3.3971 1.58012L1.20719 2.86202C0.993544 2.96884 0.886719 3.18249 0.886719 3.39614V5.90653C0.886719 6.12018 0.993544 6.33383 1.20719 6.46736L3.3704 7.72255C3.55734 7.82938 3.7977 7.82938 4.01135 7.72255L5.48019 6.86795L6.49503 6.28042L7.96387 5.42582C8.15081 5.31899 8.39117 5.31899 8.60482 5.42582L9.75319 6.09347C9.94013 6.2003 10.0737 6.41395 10.0737 6.6543V7.98961C10.0737 8.20326 9.96684 8.41691 9.75319 8.55044L8.63153 9.2181C8.44458 9.32493 8.20423 9.32493 7.99058 9.2181L6.84221 8.55044C6.65526 8.44362 6.52173 8.22997 6.52173 7.98961V7.13501L5.5069 7.72255V8.60386C5.5069 8.81751 5.61372 9.03116 5.82737 9.16469L7.99058 10.4199C8.17752 10.5267 8.41788 10.5267 8.63153 10.4199L10.7947 9.16469C10.9817 9.05786 11.1152 8.84421 11.1152 8.60386V6.06677C11.1152 5.85312 11.0084 5.63947 10.7947 5.50593L8.63153 4.25074Z"
        fill="#8247E5"
      />
    </SvgIcon>
  );
}

export default memo(EthMenuItem);

import { ModeActionTypeEnum } from 'enums/actions';
import { PaletteMode } from '@mui/material';
import { ModeActionTypes } from 'types/mode';

export const initModeState: PaletteMode =
  (localStorage.getItem('mode') as PaletteMode) || 'light';

export const modeReducer = (state = initModeState, action: ModeActionTypes) => {
  switch (action.type) {
    case ModeActionTypeEnum.CHANGE_MODE: {
      return action.payload;
    }
    default:
      return state;
  }
};

export enum TRANSPARENCY {
  T100 = 'FF',
  T99 = 'FC',
  T98 = 'FA',
  T97 = 'F7',
  T96 = 'F5',
  T95 = 'F2',
  T94 = 'F0',
  T93 = 'ED',
  T92 = 'EB',
  T91 = 'E8',
  T90 = 'E6',
  T89 = 'E3',
  T88 = 'E0',
  T87 = 'DE',
  T86 = 'DB',
  T85 = 'D9',
  T84 = 'D6',
  T83 = 'D4',
  T82 = 'D1',
  T81 = 'CF',
  T80 = 'CC',
  T79 = 'C9',
  T78 = 'C7',
  T77 = 'C4',
  T76 = 'C2',
  T75 = 'BF',
  T74 = 'BD',
  T73 = 'BA',
  T72 = 'B8',
  T71 = 'B5',
  T70 = 'B3',
  T69 = 'B0',
  T68 = 'AD',
  T67 = 'AB',
  T66 = 'A8',
  T65 = 'A6',
  T64 = 'A3',
  T63 = 'A1',
  T62 = '9E',
  T61 = '9C',
  T60 = '99',
  T59 = '96',
  T58 = '94',
  T57 = '91',
  T56 = '8F',
  T55 = '8C',
  T54 = '8A',
  T53 = '87',
  T52 = '85',
  T51 = '82',
  T50 = '80',
  T49 = '7D',
  T48 = '7A',
  T47 = '78',
  T46 = '75',
  T45 = '73',
  T44 = '70',
  T43 = '6E',
  T42 = '6B',
  T41 = '69',
  T40 = '66',
  T39 = '63',
  T38 = '61',
  T37 = '5E',
  T36 = '5C',
  T35 = '59',
  T34 = '57',
  T33 = '54',
  T32 = '52',
  T31 = '4F',
  T30 = '4D',
  T29 = '4A',
  T28 = '47',
  T27 = '45',
  T26 = '42',
  T25 = '40',
  T24 = '3D',
  T23 = '3B',
  T22 = '38',
  T21 = '36',
  T20 = '33',
  T19 = '30',
  T18 = '2E',
  T17 = '2B',
  T16 = '29',
  T15 = '26',
  T14 = '24',
  T13 = '21',
  T12 = '1F',
  T11 = '1C',
  T10 = '1A',
  T9 = '17',
  T8 = '14',
  T7 = '12',
  T6 = '0F',
  T5 = '0D',
  T4 = '0A',
  T3 = '08',
  T2 = '05',
  T1 = '03',
  T0 = '00',
}

export enum COLOR {
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  MEDIUM_AQUAMARINE = '#4FFFA6',
  MEDIUM_AQUAMARINE_50 = 'rgba(79, 255, 166, 0.5)',
  SPACE_CADET = '#1b1464',
  SPACE_CADET_30 = 'rgba(27, 20, 100, 0.3)',
  MEDIUM_PERSIAN_BLUE = '#0069AB',
  EERIE_BLACK = '#1C1C1C',
  SILVER_SAND = '#c6c6c6',
  MAXIMUM_GREEN_YELLOW = '#DDE542',
  CULTURED = '#F5F5F6',
  BLACK_OLIVE = '#3e3e3e',
  CRIMSON = '#df1212',
}

const colors = {
  black3: '#1b1464',
  purple1: '#4fffa6',
  yellow1: '#DDE542',
  primary: '#4FFFA6',
  secondary: '#1B1464',
  secondaryOffset: '#0069AB',

  white: COLOR.WHITE,
  black: COLOR.BLACK,
  deadzone: COLOR.MAXIMUM_GREEN_YELLOW,
  cultured: COLOR.CULTURED,
  blackOlive: COLOR.BLACK_OLIVE,
  crimson: COLOR.CRIMSON,
  // Light
  backgroundLight: COLOR.WHITE,
  primaryLight: COLOR.MEDIUM_AQUAMARINE,
  primaryLight50: COLOR.MEDIUM_AQUAMARINE_50,
  secondaryLight: COLOR.SPACE_CADET,
  secondaryLight30: COLOR.SPACE_CADET_30,
  secondaryOffsetLight: COLOR.MEDIUM_PERSIAN_BLUE,
  borderLight: COLOR.SPACE_CADET,
  // Dark
  backgroundDark: COLOR.EERIE_BLACK,
  primaryDark: COLOR.SPACE_CADET,
  secondaryDark: COLOR.MEDIUM_AQUAMARINE,
  secondaryOffsetDark: COLOR.MEDIUM_PERSIAN_BLUE,
  borderDark: COLOR.SILVER_SAND,
};

export default colors;

import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function NextIcon({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 60 60"
      style={{ transform: 'scale(1)', width, height, fill: 'none' }}
    >
      <path
        d="M27.2966 25.4104L27.9998 24.7071L33.2927 30L27.9998 35.2929L27.2966 34.5896L31.5238 30.3532L31.8762 30L31.5238 29.6468L27.2966 25.4104Z"
        fill={color}
        stroke={color}
      />
      <rect x="0.5" y="0.5" width="59" height="59" rx="29.5" stroke={color} />
    </SvgIcon>
  );
}

export default memo(NextIcon);

import React, { useState, useCallback, useEffect } from 'react';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import CommonInput from 'components/common/CommonInput';
import CommonUploadFile from 'components/common/CommonUploadFileInput/CommonUploadFileInput';
import SponsorService from 'services/sponsorAds';
import { useStyles } from '../SponsorAdsStyle';
import { Sponsor } from 'types/sponsor';
import PositionInput from 'components/common/PositionInput';
import { useDispatch } from 'react-redux';
import { updateAppStateAction } from 'store/actions/appActions';

interface IEditSponsorAd {
  openDialog: boolean;
  sponsor: Sponsor;
  handleCloseModal: () => void;
  reloadSponsorAds: () => void;
}

type Target = keyof Pick<
  Sponsor,
  'displayLink' | 'description' | 'rate' | 'trueLink'
>;

const EditSponsorAd = (props: IEditSponsorAd) => {
  const { openDialog, sponsor, handleCloseModal, reloadSponsorAds } = props;
  const [data, setData] = useState<Sponsor>(sponsor);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [file, setFile] = useState<File>();
  const [imgPreview, setImgPreview] = useState<string>();

  const handleClose = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  const handleChange = useCallback(
    (target: Target) => (e: React.ChangeEvent<HTMLInputElement>) =>
      setData((prev) => ({ ...prev, [target]: e.target.value })),
    [],
  );

  const handleAddPosition = useCallback(
    (position: string) => {
      const newData = { ...data };
      newData.positions = position.split(',').map((pos) => +pos);
      setData(newData);
    },
    [data],
  );

  const handleChangeFile = (e: React.ChangeEvent<any>) => {
    setFile(e.target.files?.[0]);
    setImgPreview(URL.createObjectURL(e.target.files?.[0]));
  };

  const submit = async (id: number) => {
    try {
      const formData = new FormData();
      if (data.displayLink) formData.append('displayLink', data.displayLink);
      if (data.trueLink) formData.append('trueLink', data.trueLink);
      if (data.description) formData.append('description', data.description);
      if (data.rate) formData.append('rate', String(data.rate));
      if (data.positions)
        formData.append('positions', data.positions.join(','));
      if (file) formData.append('file', file);

      await SponsorService.updateSponsor(id, formData);
      reloadSponsorAds();
      handleCloseModal();
      dispatch(
        updateAppStateAction({
          isShowSnack: true,
          snackContent: 'Edit successfully',
          type: 'success',
          snackLabel: 'Success',
        }),
      );
    } catch (error) {
      handleCloseModal();
      dispatch(
        updateAppStateAction({
          isShowSnack: true,
          snackContent: 'Something went wrong',
          type: 'error',
          snackLabel: 'Error',
        }),
      );
    }
  };

  useEffect(
    () => () => {
      if (imgPreview) URL.revokeObjectURL(imgPreview);
    },
    [imgPreview],
  );

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      maxWidth="lg"
      className={classes.wrapperDialog}
    >
      <Box className={classes.wrapper}>
        <Box className={classes.header}>
          <Typography>File</Typography>
          <Typography>Website name</Typography>
          <Typography>Website link</Typography>
          <Typography>Description</Typography>
          <Typography>Show time %</Typography>
          <Typography>Position</Typography>
        </Box>

        <Box display="flex" className={classes.wrapperItem}>
          <Box
            className={classes.item}
            style={{
              backgroundImage: `url(${imgPreview || data?.imageUrl})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          >
            <CommonUploadFile label="File Images" onChange={handleChangeFile} />
          </Box>
          <Box className={classes.item}>
            <CommonInput
              value={data?.displayLink || ''}
              onChange={handleChange('displayLink')}
            />
          </Box>
          <Box className={classes.item}>
            <CommonInput
              value={data?.trueLink || ''}
              onChange={handleChange('trueLink')}
            />
          </Box>
          <Box className={classes.item}>
            <CommonInput
              value={data?.description || ''}
              onChange={handleChange('description')}
            />
          </Box>
          <Box className={classes.item}>
            <CommonInput
              value={data?.rate.toString() || ''}
              onChange={handleChange('rate')}
            />
          </Box>
          <Box className={classes.item}>
            <PositionInput
              position={data?.positions.join(',')}
              onAdd={(posArr) => handleAddPosition(posArr)}
              type="number"
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.confirmButton}>
        <Button onClick={() => submit(data.id)}>Save</Button>
      </Box>
    </Dialog>
  );
};

export default EditSponsorAd;

import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function DarkModeFillIcon({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      style={{ transform: 'scale(1.1)', width, height }}
    >
      <path
        style={{ color: '#ffffff' }}
        d="M21,11.3c0-0.2,0-0.4-0.1-0.7c-0.5,0.7-1.1,1.2-1.9,1.6c-0.8,0.4-1.6,0.6-2.5,0.6c-1.5,0-2.8-0.5-3.8-1.6
        S11.1,9,11.1,7.5c0-0.9,0.2-1.7,0.6-2.5c0.4-0.8,1-1.4,1.6-1.9c-0.2,0-0.4-0.1-0.7-0.1S12.2,3,12,3C9.5,3,7.4,3.9,5.6,5.6
        S3,9.5,3,12s0.9,4.6,2.6,6.4S9.5,21,12,21s4.6-0.9,6.4-2.6S21,14.5,21,12C21,11.8,21,11.5,21,11.3z"
      />
    </SvgIcon>
  );
}

export default memo(DarkModeFillIcon);

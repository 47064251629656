import React from 'react';
import { useStyle } from './styles';

interface AboutContentProps {
  className?: string;
  imgURL?: string;
  tit: string;
  text: string;
  isNewline?: boolean;
}

function AboutContent(props: AboutContentProps) {
  const classes = useStyle();
  const { imgURL, tit, text, isNewline } = props;
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <span className={classes.tit}>{tit}</span>
        {isNewline && <br />}
        <span>{text}</span>
      </div>
      <div className={classes.img}>
        <img src={imgURL} alt=""></img>
      </div>
    </div>
  );
}

export default AboutContent;

import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

interface UseStyleProps {
  paymentStatus?: boolean;
  priceStatus?: boolean;
}

export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container_1: {
      padding: '160px 32px 0px 32px',
      [theme.breakpoints.down('md')]: {
        padding: '160px 24px 0px 24px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '160px 16px 0px 16px',
      },
    },
    linkTitle: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
    },
    parseInput: {
      marginTop: 16,
      marginBottom: 40,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
      backgroundColor: isDarkMode
        ? `${theme.colors.secondaryDark}${TRANSPARENCY.T10}`
        : `${theme.colors.primaryLight}${TRANSPARENCY.T10}`,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 32,
      },
      '&>input': {
        color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
        fontWeight: 500,
        fontSize: 14,
        border: 'unset',
      },
    },
    container: {
      [theme.breakpoints.up('lg')]: {
        marginTop: 63,
      },
      marginTop: 40,
    },
    left: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: 97,
      },
      [theme.breakpoints.between(744, 1440)]: {
        position: 'relative',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderColor: isDarkMode
          ? theme.colors.blackOlive
          : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
        paddingBottom: '40px',
      },
    },
    right: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        paddingRight: 176,
      },
    },
    confirm: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      gap: 32,
      marginTop: 32,
      marginBottom: 100,
      [theme.breakpoints.up('lg')]: {
        marginTop: 36,
        marginBottom: 72,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 16,
      },
    },
    position: {
      width: 140,
      [theme.breakpoints.between(744, 1440)]: {
        width: 162,
      },
      height: 60,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 32,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 32,
      },
    },
    contentFirst: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 800,
      lineHeight: '140%',
      fontSize: '32px',
      [theme.breakpoints.between(744, 1440)]: {
        position: 'absolute',
        top: '128.5px',
        right: '30px',
        width: '251px',
        height: '84px',
        fontWeight: 800,
        fontSize: '20px',
      },
    },
    contentSecond: {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
    },
    wrapper: {
      marginBottom: 24,
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '&:first-child': {
          marginBottom: 58,
        },
      },
    },
    estimateWrapper: {
      marginTop: 16,
      [theme.breakpoints.up('lg')]: {
        marginTop: 8,
      },
    },
    dateSelect: {
      display: 'flex',
      marginTop: 24,
      [theme.breakpoints.up('lg')]: {
        marginTop: 36,
        marginBottom: 24,
      },
      [theme.breakpoints.between(744, 1440)]: {
        marginBottom: 32,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: 32,
      },
    },
    dateBox: {
      [theme.breakpoints.between(744, 1440)]: {
        marginRight: 78,
      },
    },
    title: {
      marginBottom: 24,
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '140%',
      [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        left: '-99px',
        width: '90px',
        transform: 'rotate(-90deg)',
      },
    },
    currentTitle: {
      color: '#4fffa6',
      bottom: 44,
    },
    tipTitle: {
      [theme.breakpoints.up('lg')]: {
        bottom: 12,
      },
    },
    settingsTitle: {
      [theme.breakpoints.up('lg')]: {
        bottom: 10,
      },
    },
    estimateTitle: {
      [theme.breakpoints.up('lg')]: {
        bottom: 47,
      },
    },
    priceDiv: {
      display: 'flex',
    },
    valueBox: {
      width: 204,
      [theme.breakpoints.up('lg')]: {
        width: 286.84,
      },
      [theme.breakpoints.down('sm')]: {
        width: 204,
      },
    },
    label: {
      fontSize: 14,
      lineHeight: '19.6px',
      marginBottom: theme.spacing(1),
    },
    priceBox: {
      backgroundColor: ({ priceStatus }: UseStyleProps) => {
        if (priceStatus) return `${theme.colors.crimson}${TRANSPARENCY.T18}`;
        if (isDarkMode)
          return `${theme.colors.secondaryDark}${TRANSPARENCY.T10}`;
        if (!isDarkMode)
          return `${theme.colors.primaryLight}${TRANSPARENCY.T10}`;
      },
      height: 60,
      width: 162,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        width: 134,
        alignItems: 'flex-end',
        '& > * ': {
          paddingRight: 17.5,
        },
      },
      [theme.breakpoints.down('sm')]: {
        width: 136,
      },
    },
    price: {
      fontSize: 20,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'right',
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
        textAlign: 'center',
      },
      color: ({ priceStatus }: UseStyleProps) =>
        priceStatus ? '#ED5050' : '#4fffa6',
    },
    miniumPrice: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontWeight: 400,
      fontSize: '8px',
      fontFamily: 'Roboto',
      [theme.breakpoints.down('sm')]: {
        position: 'relative',
        bottom: 3,
      },
    },
    estimateDeskop: {
      position: 'relative',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
      padding: '24px 0px',
      marginTop: 24,
      [theme.breakpoints.up('lg')]: {
        padding: '24px 0px',
      },
    },
    positionBlock: {
      position: 'relative',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderColor: isDarkMode
        ? theme.colors.blackOlive
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
      padding: '24px 0 40px 0',
      [theme.breakpoints.up('lg')]: {
        padding: '43px 0px 37px',
      },
    },
    service: {
      position: 'absolute',
      bottom: -30,
      right: 0,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '140%',
      [theme.breakpoints.down('sm')]: {
        bottom: -20,
        fontSize: 8,
      },
    },
    serviceLink: {
      textDecoration: `underline solid ${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.primaryLight
      } 1px`,
      fontWeight: 700,
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
    },
    estimateTablet: {
      width: '70%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    cancelButton: {
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      '& p': {
        fontSize: 16,
        fontWeight: 600,
        marginLeft: 8,
        color: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
      },
    },
    confirmButton: {
      padding: '24px 16px',
      height: 60,
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      [theme.breakpoints.up('lg')]: {
        width: 234,
      },
      '& p': {
        fontSize: 16,
        fontWeight: 600,
        marginLeft: 8,
        color: isDarkMode
          ? theme.colors.primaryDark
          : theme.colors.secondaryLight,
      },
    },
    confirmButtonDisabled: {
      color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
      opacity: 0.4,
    },
  };
});

import { CurrencyUnitEnum } from 'enums/addNft';
import { MintedgemIDL } from 'types/idl';
export const walletAddress = [
  {
    id: 250,
    address: process.env.REACT_APP_FTM_CONTRACT_ADDRESS,
    name: 'opera',
    chainName: CurrencyUnitEnum.FTM,
  },
  {
    id: 137,
    address: process.env.REACT_APP_POLYGON_MUMBAI_CONTRACT_ADDRESS,
    name: 'polygonMainnet',
    chainName: CurrencyUnitEnum.MATIC,
  },
  {
    id: 43114,
    address: process.env.REACT_APP_AVAX_CONTRACT_ADDRESS,
    name: 'avalanche',
    chainName: CurrencyUnitEnum.AVAX,
  },
  {
    id: 56,
    address: process.env.REACT_APP_BSC_CONTRACT_ADDRESS,
    name: 'bsc',
    chainName: CurrencyUnitEnum.BNB,
  },
  {
    id: 1,
    address: process.env.REACT_APP_ETH_CONTRACT_ADDRESS,
    name: 'mainnet',
    chainName: CurrencyUnitEnum.ETH,
  },
  {
    id: 84532,
    address: process.env.REACT_APP_BASE_TESTNET_CONTRACT_ADDRESS,
    name: 'BaseTestnet',
    chainName: CurrencyUnitEnum.DONE,
  },
  {
    id: 84532,
    address: process.env.REACT_APP_BASE_TESTNET_CONTRACT_ADDRESS,
    name: 'BaseTestnet',
    chainName: CurrencyUnitEnum.SOL,
  },
] as const;

export const networks = {
  1: {
    chainName: 'Mainnet',
    rpcUrls: ['https://mainnet.infura.io/v3/84842078b09946638c03157f83405213'],
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    chainId: `0x${Number(1).toString(16)}`,
  },
  56: {
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    nativeCurrency: {
      name: 'Binance',
      symbol: 'BNB',
      decimals: 18,
    },
    chainId: `0x${Number(56).toString(16)}`,
    blockExplorerUrls: ['https://bscscan.com'],
    chainName: 'Binance Smart Chain',
  },

  137: {
    rpcUrls: [
      'https://polygon-mainnet.g.alchemy.com/v2/VjtzrgjDdzYw2Dn2M3FPVrjtA0jSVN49',
    ],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    chainId: `0x${Number(137).toString(16)}`,
    chainName: 'Polygon',
  },
  250: {
    chainName: 'Fantom',
    rpcUrls: [
      'https://morning-morning-putty.fantom.discover.quiknode.pro/9bf35ce6be611753b351cf7f47b948c308e52b9e/',
    ],
    nativeCurrency: {
      name: 'Fantom',
      symbol: 'FTM',
      decimals: 18,
    },
    chainId: `0x${Number(250).toString(16)}`,
  },
  43114: {
    chainName: 'AVAX',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    chainId: `0x${Number(43114).toString(16)}`,
  },
};

export const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'itemId',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'PaymentCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'itemId',
        type: 'uint256',
      },
    ],
    name: 'TransferBack',
    type: 'event',
  },
  {
    inputs: [{ internalType: 'uint256', name: '_itemId', type: 'uint256' }],
    name: 'createPayment',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_token', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'emergencyWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'itemId', type: 'uint256' }],
    name: 'getItemPayment',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'amount', type: 'uint256' },
          { internalType: 'address', name: 'creator', type: 'address' },
        ],
        internalType: 'struct MintedgemPayment.ItemPayment',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: '', type: 'address' }],
    name: 'transactionVolume',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

export const abiDone = [
  {
    inputs: [
      { internalType: 'string', name: '_name', type: 'string' },
      { internalType: 'string', name: '_symbol', type: 'string' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'from', type: 'address' },
      { indexed: true, internalType: 'address', name: 'to', type: 'address' },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      { internalType: 'address', name: 'owner', type: 'address' },
      { internalType: 'address', name: 'spender', type: 'address' },
    ],
    name: 'allowance',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'approve',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint256', name: 'amount', type: 'uint256' }],
    name: 'burn',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'burnFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'subtractedValue', type: 'uint256' },
    ],
    name: 'decreaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'spender', type: 'address' },
      { internalType: 'uint256', name: 'addedValue', type: 'uint256' },
    ],
    name: 'increaseAllowance',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_to', type: 'address' },
      { internalType: 'uint256', name: '_amount', type: 'uint256' },
    ],
    name: 'mint',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transferFrom',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export const mintedgemIDL: MintedgemIDL = {
  version: '0.1.0',
  name: 'mintedgem',
  instructions: [
    {
      name: 'initialize',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'percent',
          type: 'u64',
        },
      ],
    },
    {
      name: 'initVaultSol',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultSol',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'initVaultDoneToken',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mintOfTokenBeingSent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenAccountOwnerPda',
          isMut: true,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'vaultToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'depositSol',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultSol',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'depositDoneToken',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mintOfTokenBeingSent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenAccountOwnerPda',
          isMut: true,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'vaultToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'senderTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amount',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawSol',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultSol',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amountSol',
          type: 'u64',
        },
      ],
    },
    {
      name: 'withdrawDoneToken',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mintOfTokenBeingSent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenAccountOwnerPda',
          isMut: true,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'vaultToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'senderTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'amountDone',
          type: 'u64',
        },
      ],
    },
    {
      name: 'setPercent',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'percent',
          type: 'u64',
        },
      ],
    },
    {
      name: 'createPayment',
      accounts: [
        {
          name: 'master',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'itemPayment',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'transactionSolVolume',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'vaultSol',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mintOfTokenBeingSent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenAccountOwnerPda',
          isMut: true,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'vaultToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'senderTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'itemId',
          type: 'u64',
        },
        {
          name: 'amountSol',
          type: 'u64',
        },
      ],
    },
    {
      name: 'createPaymentByDone',
      accounts: [
        {
          name: 'itemPayment',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'transactionDoneTokenVolume',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'mintOfTokenBeingSent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenAccountOwnerPda',
          isMut: true,
          isSigner: false,
          docs: ['CHECK'],
        },
        {
          name: 'vaultToken',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'senderTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'rent',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [
        {
          name: 'itemId',
          type: 'u64',
        },
        {
          name: 'amountDone',
          type: 'u64',
        },
      ],
    },
    {
      name: 'initTxSolVolume',
      accounts: [
        {
          name: 'transactionSolVolume',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'initTxDoneTokenVolume',
      accounts: [
        {
          name: 'transactionDoneTokenVolume',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'initSenderAta',
      accounts: [
        {
          name: 'mintOfTokenBeingSent',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'senderTokenAccount',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'signer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
        },
        {
          name: 'associatedTokenProgram',
          isMut: false,
          isSigner: false,
        },
      ],
      args: [],
    },
    {
      name: 'hello',
      accounts: [],
      args: [],
    },
  ],
  accounts: [
    {
      name: 'itemPayment',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'creator',
            type: 'publicKey',
          },
          {
            name: 'amount',
            type: 'u64',
          },
          {
            name: 'amountDone',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'master',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'isInitialized',
            type: 'bool',
          },
          {
            name: 'isVaultSolInitialized',
            type: 'bool',
          },
          {
            name: 'isVaultDoneTokenInitialized',
            type: 'bool',
          },
          {
            name: 'owner',
            type: 'publicKey',
          },
          {
            name: 'percent',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'transactionDoneTokenVolume',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'creator',
            type: 'publicKey',
          },
          {
            name: 'amount',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'transctionSolVolume',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'creator',
            type: 'publicKey',
          },
          {
            name: 'amount',
            type: 'u64',
          },
        ],
      },
    },
    {
      name: 'vaultSol',
      type: {
        kind: 'struct',
        fields: [],
      },
    },
  ],
  events: [
    {
      name: 'CreatePaymentByDoneEvent',
      fields: [
        {
          name: 'signer',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'itemId',
          type: 'u64',
          index: false,
        },
        {
          name: 'amountDone',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'CreatePaymentEvent',
      fields: [
        {
          name: 'signer',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'itemId',
          type: 'u64',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'DepositDoneTokenEvent',
      fields: [
        {
          name: 'depositor',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amountDone',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'DepositSolEvent',
      fields: [
        {
          name: 'depositor',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'Hello',
      fields: [
        {
          name: 'msg',
          type: 'string',
          index: false,
        },
      ],
    },
    {
      name: 'InitSenderAta',
      fields: [
        {
          name: 'signer',
          type: 'publicKey',
          index: false,
        },
      ],
    },
    {
      name: 'InitTxDoneTokenVolume',
      fields: [
        {
          name: 'signer',
          type: 'publicKey',
          index: false,
        },
      ],
    },
    {
      name: 'InitTxSolVolume',
      fields: [
        {
          name: 'signer',
          type: 'publicKey',
          index: false,
        },
      ],
    },
    {
      name: 'OwnerInitialized',
      fields: [],
    },
    {
      name: 'SetPercent',
      fields: [
        {
          name: 'percent',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'VaultDoneTokenInitialized',
      fields: [],
    },
    {
      name: 'VaultSolInitialized',
      fields: [],
    },
    {
      name: 'WithdrawDoneTokenEvent',
      fields: [
        {
          name: 'to',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amountDone',
          type: 'u64',
          index: false,
        },
      ],
    },
    {
      name: 'WithdrawSolEvent',
      fields: [
        {
          name: 'to',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'TransferFailed',
      msg: 'Mintedgem: Transfer failed',
    },
    {
      code: 6001,
      name: 'Unauthorized',
      msg: 'Mintedgem: You are not authorized to perform this action',
    },
    {
      code: 6002,
      name: 'MasterAccountAlreadyInitialized',
      msg: 'Mintedgem: The master account is already initialized',
    },
    {
      code: 6003,
      name: 'InsufficientAmount',
      msg: 'Mintedgem: Insufficient amount',
    },
    {
      code: 6004,
      name: 'NotOwner',
      msg: 'Mintedgem: Only owner can call this function!',
    },
    {
      code: 6005,
      name: 'VaultSolAlreadyInitialized',
      msg: 'Mintedgem: Vault SOL is already initialized',
    },
    {
      code: 6006,
      name: 'VaultDoneTokenAlreadyInitialized',
      msg: 'Mintedgem: Vault DONE token is already initialized',
    },
    {
      code: 6007,
      name: 'InvalidAmount',
      msg: 'Mintedgem: Amount must be greater than 0',
    },
    {
      code: 6008,
      name: 'InvalidPercent',
      msg: 'Mintedgem: Percent must be greater thean or equal 0 and less than or equal 100',
    },
    {
      code: 6009,
      name: 'InvalidCreator',
      msg: 'Mintedgem: Invalid creator',
    },
  ],
};

import {
  Box,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import SearchIcon from 'icons/SearchIcon';
import React from 'react';

const HelpCenter = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.search}>
        <Paper square={true} className={classes.searchBox}>
          <Box className={classes.icon}>
            <SearchIcon color={'#1b1464'} />
          </Box>
          <TextField
            fullWidth
            placeholder="Search"
            InputProps={{ disableUnderline: true }}
          />
        </Paper>
      </Box>
      <Box className={classes.main}>
        <Box className={classes.line1}>
          <Box className={classes.item}>
            <Typography className={classes.title}>Getting started</Typography>
            <Typography className={classes.description}>
              Learn how to create an account and set up your wallet.
            </Typography>
          </Box>
          <Box className={classes.item}>
            <Typography className={classes.title}>Getting started</Typography>
            <Typography className={classes.description}>
              Learn how to create an account and set up your wallet.
            </Typography>
          </Box>
          <Box className={classes.item}>
            <Typography className={classes.title}>Getting started</Typography>
            <Typography className={classes.description}>
              Learn how to create an account and set up your wallet.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.line2}>
          <Box className={classes.item}>
            <Typography className={classes.title}>Getting started</Typography>
            <Typography className={classes.description}>
              Learn how to create an account and set up your wallet.
            </Typography>
          </Box>
          <Box className={classes.item}>
            <Typography className={classes.title}>Getting started</Typography>
            <Typography className={classes.description}>
              Learn how to create an account and set up your wallet.
            </Typography>
          </Box>
          <Box className={classes.item}>
            <Typography className={classes.title}>Getting started</Typography>
            <Typography className={classes.description}>
              Learn how to create an account and set up your wallet.
            </Typography>
          </Box>
        </Box>
      </Box>
      <div className={classes.footer}></div>
    </Box>
  );
};

export default HelpCenter;

const useStyles = makeStyles((theme) => ({
  container: {
    color: '#1b1464',
    padding: '160px 0px 0px 0px',
  },
  search: {
    background: 'rgba(111, 107, 197, 0.08)',
    height: 250,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchBox: {
    backgroundColor: '#FFFFFF',
    height: 60,
    width: 500,
    border: '1px solid #1b1464',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 60,
  },
  line1: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  line2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  item: {
    width: 332,
    height: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
    marginRight: 8,
    border: '1px solid rgba(111, 107, 197, 0.16)',
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '22.4px',
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '19.6px',
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  footer: {
    backgroundColor: '#4fffa6',
    position: 'absolute',
    top: '800px',
    bottom: 0,
    width: '100%',
  },
  icon: {
    padding: 20,
  },
}));

import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  container: {
    marginTop: 32,
  },
  title: {
    fontWeight: 500,
    fontSize: 28,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  content: {
    fontWeight: 300,
    fontSize: 24,
    '& ul': {
      marginBottom: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));

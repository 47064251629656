import { makeStyles } from '@material-ui/core';
import { TRANSPARENCY } from 'material/colors';

export const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    root: {
      position: 'relative',
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      color: theme.colors.purple1,
      fontWeight: 600,
      cursor: 'grabbing',
      fontSize: '16px',
      lineHeight: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.015em',
      '& svg': {
        marginLeft: 8,
      },
    },
    menu: {
      position: 'absolute',
      top: 20,
      right: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.white,
      color: theme.colors.secondaryLight,
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
      paddingBottom: 4,
    },
    menuItem: {
      padding: '8px 8px',
      boxSizing: 'border-box',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: isDarkMode
        ? theme.colors.blackOlive
        : theme.colors.secondaryLight,
      fontSize: 14,
      letterSpacing: 0.015,
      fontWeight: 400,
      color: isDarkMode ? theme.colors.white : theme.colors.secondaryLight,
    },
    selectedItem: {
      backgroundColor: isDarkMode
        ? `${theme.colors.secondaryDark}${TRANSPARENCY.T50}`
        : theme.colors.primaryLight,
      fontWeight: 500,
    },
  };
});

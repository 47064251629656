import { makeStyles } from '@material-ui/core';
import { pad } from 'lodash';
import { TRANSPARENCY } from 'material/colors';

export const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    addBtn: {
      width: '100%',
      border: `1px solid ${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.primaryLight
      }`,
      height: '50px',
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      fontSize: '16px',
      fontWeight: 600,
    },
    dialogPaper: {
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : theme.colors.cultured,
      minWidth: '800px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '340px',
      },
    },
    dialogTitleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dialogTitle: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontSize: '28px',
      fontWeight: 600,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    dialogContent: {
      padding: '12px',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      columnGap: '32px',
      rowGap: '0px',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        rowGap: '16px',
        columnGap: '0px',
      },
    },

    dialogCloseBtn: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      '&:hover': {
        opacity: 0.8,
        cursor: 'pointer',
      },
    },
    uploadWrapperDesktop: {
      height: '100%',
      width: '100%',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      border: `2px dashed ${
        isDarkMode ? theme.colors.white : theme.colors.black
      }`,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    uploadWrapperMobile: {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: `1px solid ${
        isDarkMode ? theme.colors.white : theme.colors.black
      }`,
      padding: '10px 14px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
    uploadWrapperError: {
      border: `2px dashed ${theme.colors.crimson}`,
    },
    uploadDescription: {
      textAlign: 'center',
      fontWeight: 300,
    },
    uploadBtn: {
      backgroundColor: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      height: '28px',
      fontSize: '12px',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
      },
    },
    nftImgWrapper: {
      position: 'relative',
      height: '100%',
      width: '100%',
      '&:hover': {
        '& $nftImgOverlay': {
          opacity: 1,
          visibility: 'visible',
        },
        '& $removeNftImgBtn': {
          opacity: 1,
          visibility: 'visible',
        },
      },
    },
    nftImg: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '4px',
    },
    nftImgOverlay: {
      opacity: 0,
      visibility: 'hidden',
      position: 'absolute',
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      transition: 'all 0.3s ease',
    },
    removeNftImgBtn: {
      opacity: 0,
      visibility: 'hidden',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      border: 0,
      padding: 0,
      backgroundColor: 'transparent',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    formWrapper: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '26px',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
        rowGap: '26px',
        columnGap: '0px',
      },
    },

    startAdornmentLinkIcon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '8px',
      '& svg': {
        color: isDarkMode
          ? theme.colors.secondaryDark
          : theme.colors.primaryLight,
        strokeWidth: 5,
        fontWeight: 800,
      },
    },

    autocompleteNFTField: {
      gridColumn: 'span 2 / span 2',
      border: `1px solid ${
        isDarkMode ? theme.colors.white : theme.colors.black
      }`,
      borderRadius: '0px',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontWeight: 500,
      height: '44px',
    },

    autocompleteNFTItem: {
      backgroundColor: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.backgroundLight,
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      padding: '16px 8px',
      borderBottom: `1px solid ${
        isDarkMode ? theme.colors.blackOlive : theme.colors.secondaryLight
      }${TRANSPARENCY.T30}`,
      '&:hover': {
        backgroundColor: `${
          isDarkMode ? theme.colors.white : theme.colors.primaryDark
        }${TRANSPARENCY.T10} !important`,
        cursor: 'pointer',
      },
    },
    autocompleteNFTFieldError: {
      border: `2px solid #f44336`,
    },

    autocompleteNFTPaper: {
      backgroundColor: `${
        isDarkMode ? theme.colors.primaryDark : theme.colors.backgroundLight
      } !important`,
      boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25) !important',
      '& .MuiAutocomplete-noOptions': {
        color: `${
          isDarkMode ? theme.colors.white : theme.colors.black
        } !important`,
      },
    },

    autocompleteNFTPopupIndicator: {
      color: `${
        isDarkMode ? theme.colors.secondaryDark : theme.colors.blackOlive
      } !important`,
    },

    autocompleteNFTEndAdornment: {
      display: 'none !important',
    },

    inputNFTField: {
      display: 'flex',
      flexDirection: 'column',
      gridColumn: 'span 2 / span 2',
      border: `1px solid ${
        isDarkMode ? theme.colors.white : theme.colors.black
      }`,
      borderRadius: '0px',
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      fontWeight: 800,
      height: '44px',
      '&:has(.Mui-error)': {
        border: `2px solid #f44336`,
      },
      '& input': {
        color: isDarkMode ? theme.colors.white : theme.colors.black,
        fontWeight: 500,
      },
      '& fieldset': { border: 'none' },
      '& .MuiFormHelperText-root.Mui-error': {
        color: '#f44336',
        fontWeight: 700,
        marginTop: '6px',
        marginInline: 0,
      },
    },
    inputNFTCollectionNameField: {
      height: '100%',
      '& .MuiAutocomplete-inputRoot': {
        height: '100%',
        width: '100%',
        border: 'none',
        padding: '0 10px !important',
        '& input': {
          color: isDarkMode ? theme.colors.white : theme.colors.black,
          width: '70%',
          fontWeight: 500,
        },
        '& fieldset': { border: 'none' },
      },
    },

    inputNFTPriceField: {
      gridColumn: 'span 1 / span 1',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 2 / span 2',
      },
    },
    inputSelectChainField: {
      gridColumn: 'span 1 / span 1',
      '&.Mui-error': {
        border: `2px solid #f44336`,
      },
      '& .MuiOutlinedInput-input': {
        padding: '12px !important',
        boxSizing: 'border-box',
      },

      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 2 / span 2',
      },
    },
    inputSelectChainPlaceholder: {
      position: 'absolute',
      top: '22px',
      left: '12px',
      transform: 'translateY(-50%)',
      opacity: 0.5,
      fontWeight: 500,
    },
    inputSelectChainIconWrapper: {
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    inputSelectChainIcon: {
      color: isDarkMode ? theme.colors.secondaryDark : theme.colors.blackOlive,
    },
    inputSelectChainMenuItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontWeight: 400,
    },

    inputNFTSelectField: {
      backgroundColor: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.white,
      padding: '16px 8px',
    },
    inputNFTSelectItem: {
      color: isDarkMode ? theme.colors.white : theme.colors.black,
      height: '48px',
      padding: '16px 8px',
      borderBottom: `1px solid ${
        isDarkMode ? theme.colors.blackOlive : theme.colors.secondaryLight
      }${TRANSPARENCY.T30}`,
      fontWeight: 300,
      '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
        backgroundColor: `${
          isDarkMode ? theme.colors.white : theme.colors.secondaryLight
        }${TRANSPARENCY.T10}`,
        '& span': {
          fontWeight: 700,
        },
      },
      '&:hover': {
        backgroundColor: `${
          isDarkMode ? theme.colors.white : theme.colors.secondaryLight
        }${TRANSPARENCY.T10}`,
      },
    },
    errorMessageInput: {
      color: '#f44336',
      fontWeight: 700,
      marginTop: '6px',
      marginInline: 0,
      fontSize: '12px',
    },

    submitBtn: {
      gridColumn: 'span 2 / span 2',
      backgroundColor: theme.colors.primary,
      color: isDarkMode
        ? theme.colors.primaryDark
        : theme.colors.secondaryLight,
      height: '50px',
      fontSize: '16px',
      fontWeight: 600,
      '&:hover': {
        backgroundColor: theme.colors.primary,
      },
    },
  };
});

import { PaletteMode } from '@mui/material';
import { getPalette } from './palette';

export const getTypography = (mode: PaletteMode) => ({
  fontFamily: 'Poppins, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  lineHeight: '140% !important',
  allVariants: {
    color: getPalette(mode)?.text?.primary,
  },
  h1: {
    fontWeight: 400,
  },
  h4: {
    fontWeight: 700,
  },
  h3: {
    fontWeight: 600,
  },
  h5: {
    fontWeight: 600,
  },
  h6: {
    fontWeight: 600,
  },
  body: {
    fontWeight: 400,
  },
  body2: {
    fontWeight: 400,
  },
  subtitle1: {
    fontWeight: 600,
  },
  subtitle2: {
    fontWeight: 600,
  },
  caption: {
    fontWeight: 400,
  },
});

import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import CommonDialog from 'components/common/CommonDialog';
import CloseIcon from 'icons/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import { useStyles } from './styles';
import { getMarket } from 'store/selectors';
import { WalletEnum } from 'enums/wallet';
import { useWeb3React } from '@web3-react/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { logoutUserAction } from 'store/actions/userActions';
import { updateMarketStateAction } from 'store/actions/marketAction';

export default function ChangeWalletDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const market = useSelector(getMarket);
  const { deactivate } = useWeb3React();
  const { disconnect } = useWallet();

  const deactivateWallet = useCallback(() => {
    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      disconnect();
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      deactivate();
    }
  }, [deactivate, disconnect, market]);

  const onLogout = () => {
    deactivateWallet();
    dispatch(logoutUserAction());
    dispatch(updateMarketStateAction(null));
    onClose();
  };

  const onCloseAndReturnProfile = () => {
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
  };

  const onClose = () => {
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
  };

  return (
    <CommonDialog isOverridden>
      <Box className={classes.conatiner}>
        <Box className={classes.btnContainer}>
          <Button
            disableRipple
            className={classes.closeBtn}
            onClick={onCloseAndReturnProfile}
          >
            <CloseIcon />
          </Button>
        </Box>
        <Typography variant="h2" className={classes.title}>
          Transaction failed
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          Change wallet
        </Typography>
        <Box className={clsx(classes.wrapperBtn, 'center-root')}>
          <Button onClick={onClose}>
            <Typography style={{ color: '#1b1464' }}>BACK</Typography>
          </Button>
          <Button onClick={onLogout}>
            <Typography style={{ color: '#1b1464' }}>LOGOUT</Typography>
          </Button>
        </Box>
      </Box>
    </CommonDialog>
  );
}

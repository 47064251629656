import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function LightModeFillIcon({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      style={{ transform: 'scale(1)', width, height }}
    >
      <g>
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="3.8,18.7 5.3,20.1 7.8,17.6 6.4,16.3 	"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="7.7,6.3 5.3,3.8 3.9,5.3 6.4,7.8 	"
        />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="20.2,5.3 18.7,3.9 16.2,6.4 17.7,7.7 	"
        />
        <rect x="11" y="1" style={{ fill: '#FFFFFF' }} width="2" height="4" />
        <rect x="1" y="11" style={{ fill: '#FFFFFF' }} width="4" height="2" />
        <rect x="19" y="11" style={{ fill: '#FFFFFF' }} width="4" height="2" />
        <rect x="11" y="19" style={{ fill: '#FFFFFF' }} width="2" height="4" />
        <polygon
          style={{ fill: '#FFFFFF' }}
          points="16.3,17.6 18.7,20.2 20.1,18.7 17.6,16.2 	"
        />
        <path
          style={{ fill: '#FFFFFF' }}
          d="M15.5,8.5c-1-1-2.2-1.5-3.5-1.5S9.4,7.5,8.5,8.5C7.5,9.4,7,10.6,7,12s0.5,2.6,1.5,3.5c1,1,2.2,1.5,3.5,1.5
		s2.6-0.5,3.5-1.5c1-1,1.5-2.2,1.5-3.5S16.5,9.4,15.5,8.5z"
        />
      </g>
    </SvgIcon>
  );
}

export default memo(LightModeFillIcon);

import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  container: {
    marginBottom: 120,
  },
  text: {
    fontSize: 32,
    marginBottom: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  tit: {
    fontWeight: 600,
  },
  img: {
    '&>img': {
      width: '100%',
      objectFit: 'cover',
    },
  },
}));

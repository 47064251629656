import { useEffect, useState, useCallback } from 'react';
import { Box, Button, CardMedia, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Sponsor } from 'types/sponsor';
import SponsorService from 'services/sponsorAds';
import { useStyles } from './SponsorAdsStyle';
import DeleteIcon from 'icons/DeleteIcon';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import WarningDialog from 'components/Dialog/WarningDialog';
import EditIcon from 'icons/EditIcon';
import CreateSponsorAd from './CreateSponsorAd';
import EditSponsorAd from './EditSponsorAd';
import { updateAppStateAction } from 'store/actions/appActions';

const SponsorAds = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [sponsorAdsList, setSponsorAdsList] = useState<Sponsor[]>();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedSponsorAd, setselectedSponsorAd] = useState<
    Sponsor | undefined
  >();

  const handleCloseCreateModal = useCallback(() => {
    setOpenCreateModal(false);
  }, [setOpenCreateModal]);

  const handleOpenCreateModal = useCallback(() => {
    setOpenCreateModal(true);
  }, [setOpenCreateModal]);

  const handleCloseEditModal = useCallback(() => {
    setOpenEditModal(false);
  }, [setOpenEditModal]);

  const handleOpenEditModal = useCallback(
    (sponsor: Sponsor) => {
      setOpenEditModal(true);
      setselectedSponsorAd(sponsor);
    },
    [setOpenEditModal, setselectedSponsorAd],
  );

  const getAllSponsorAds = useCallback(async () => {
    const data = await SponsorService.GetAllSponsorAds();
    setSponsorAdsList(data);
  }, [setSponsorAdsList]);

  const handleCloseNotification = useCallback(() => {
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
  }, [dispatch]);

  const handleDeleteSponsorAd = useCallback(
    async (id: number) => {
      try {
        await SponsorService.deleteSponsorAds(id);
        const newSponsorAdList = sponsorAdsList?.filter(
          (item) => item.id !== id,
        );
        setSponsorAdsList(newSponsorAdList);
        handleCloseNotification();
        dispatch(
          updateAppStateAction({
            isShowSnack: true,
            snackContent: 'Delete successfully',
            type: 'success',
            snackLabel: 'Success',
          }),
        );
      } catch (error) {
        dispatch(
          updateAppStateAction({
            isShowSnack: true,
            snackContent: 'Something went wrong',
            type: 'error',
            snackLabel: 'Error',
          }),
        );
      }
    },
    [sponsorAdsList, setSponsorAdsList, handleCloseNotification, dispatch],
  );

  const handleClickDeleteIcon = useCallback(
    (id: number) => {
      dispatch(
        updateDialogStateAction({
          open: true,
          component: WarningDialog,
          props: {
            headerTitle: 'Warning',
            contentTitle:
              'Are you sure you want to delete this sponsored advertisement?',
            textCloseButton: 'Cancel',
            textAcceptButton: 'Delete',
            onClose: handleCloseNotification,
            onAccept: () => handleDeleteSponsorAd(id),
          },
        }),
      );
    },
    [dispatch, handleCloseNotification, handleDeleteSponsorAd],
  );

  useEffect(() => {
    getAllSponsorAds();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.main}>
      <Box className={classes.top}>
        <Button className={classes.buttonAdd} onClick={handleOpenCreateModal}>
          + Add Sponsored advertisement
        </Button>
      </Box>
      <Box className={classes.wrapper}>
        <Box className={classes.headerList}>
          <Typography>File</Typography>
          <Typography>Website name</Typography>
          <Typography>Website link</Typography>
          <Typography>Description</Typography>
          <Typography>Show time %</Typography>
          <Typography>Positions</Typography>
          <Typography>Displayed times</Typography>
          <Typography>Clicked times</Typography>
          <Typography></Typography>
        </Box>
        {sponsorAdsList &&
          sponsorAdsList?.length > 0 &&
          sponsorAdsList.map((item, i) => {
            return (
              <Box
                display="flex"
                key={item.id}
                className={classes.wrapperItemList}
              >
                <Box className={classes.itemList}>
                  <CardMedia
                    image={sponsorAdsList[i].imageUrl}
                    className={classes.imgFile}
                  />
                </Box>
                <p className={classes.itemList}>
                  {sponsorAdsList[i].displayLink}
                </p>
                <p className={classes.itemList}>{sponsorAdsList[i].trueLink}</p>
                <p className={classes.itemList}>
                  {sponsorAdsList[i].description}
                </p>
                <p className={classes.itemList}>
                  {sponsorAdsList[i].rate.toString()}
                </p>
                <p className={classes.itemList}>
                  {sponsorAdsList[i].positions.join(',')}
                </p>
                <p className={classes.itemList}>
                  {sponsorAdsList[i].displayCount.toString()}
                </p>
                <p className={classes.itemList}>
                  {sponsorAdsList[i].clickCount.toString()}
                </p>
                <p className={classes.itemList}>
                  <span
                    className={classes.deleteIcon}
                    onClick={() => handleClickDeleteIcon(item.id)}
                  >
                    <DeleteIcon color="darkgrey" />
                  </span>
                  <span
                    className={classes.editIcon}
                    onClick={() => handleOpenEditModal(item)}
                  >
                    <EditIcon color="darkgrey" />
                  </span>
                </p>
              </Box>
            );
          })}
      </Box>
      <CreateSponsorAd
        openDialog={openCreateModal}
        handleCloseModal={handleCloseCreateModal}
        reloadSponsorAds={getAllSponsorAds}
      />
      {openEditModal && (
        <EditSponsorAd
          openDialog={openEditModal}
          handleCloseModal={handleCloseEditModal}
          reloadSponsorAds={getAllSponsorAds}
          sponsor={selectedSponsorAd!}
        />
      )}
    </Box>
  );
};

export default SponsorAds;

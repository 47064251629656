import { makeStyles } from '@material-ui/core';

export const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  top: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    width: 220,
    border: '1px solid #1b1464',
    borderRadius: 5,
    padding: 8,
  },
  dropdownStyle: {
    backgroundColor: '#FFFFFF',
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 100,
    '& button': {
      marginLeft: 20,
    },
  },
  mainConent: {
    display: 'flex',
    marginTop: 40,
    flexWrap: 'wrap',
  },
  imageBlockContaner: {
    width: '60%',
  },
  imageBlock: {
    width: '100%',
    height: 'auto',
  },
  noData: {
    marginLeft: '80px',
    width: '-webkit-fill-available',
    height: 'auto',
    backgroundSize: 'contain',
    backgroundPosition: 'unset',
  },
  imageGeneral: {
    width: '-webkit-fill-available',
    height: 'auto',
    backgroundSize: 'contain',
    backgroundPosition: 'unset',
  },
  cateInfo: {
    paddingLeft: 58,
    width: '40%',
  },
  cateItem: {
    '&>p:first-child': {
      fontSize: 20,
    },
    '&>p:nth-child(2)': {
      fontSize: 32,
      fontWeight: 600,
    },
    marginBottom: 4,
  },
  flexBox: {
    width: '100%',
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textNoData: {
    fontSize: 32,
    fontWeight: 600,
    color: 'darkgrey',
  },
  textError: {
    fontSize: 32,
    fontWeight: 600,
    color: 'red',
  },
}));

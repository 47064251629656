/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, makeStyles, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import clsx from 'clsx';
import LinkIcon from 'icons/LinkIcon';
import ArrowForward from 'icons/ArrowForward';
import RefreshIcon from 'icons/RefreshIcon';
import {
  getNFTPreviewAction,
  resetNFTPreviewAction,
} from 'store/actions/nftActions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import CheckCircle from 'icons/CheckCircle';
import CloseIcon from 'icons/CloseIcon';
import { modeTheme } from 'store/selectors';

interface IParseNFTInput {
  value: string;
  className?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  error?: boolean;
  disabled?: boolean;
  noChange?: boolean;
  setLink?: (value: string) => void;
  refresh?: boolean;
  borderNone?: boolean;
  pasreLinkPress?: (press: boolean) => void;
  setIsManual?: (value: boolean) => void;
}

const ParseNFTInput = ({
  value,
  onChange,
  className,
  placeholder,
  error,
  disabled,
  noChange,
  setLink,
  refresh,
  borderNone,
  pasreLinkPress,
  setIsManual,
}: IParseNFTInput) => {
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const mode = useSelector(modeTheme);
  const theme = useTheme();

  const onParseNFTLink = useCallback(() => {
    dispatch(getNFTPreviewAction({ link: value }));
    setIsRefresh(true);
    setIsManual?.(false);

    if (pasreLinkPress) pasreLinkPress(true);
  }, [dispatch, value]);

  const handlePressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        dispatch(getNFTPreviewAction({ link: value }));
        setIsRefresh(true);
        setIsManual?.(false);
        if (pasreLinkPress) pasreLinkPress(true);
      }
    },
    [dispatch, value],
  );

  const onRefresh = useCallback(() => {
    dispatch(getNFTPreviewAction({ link: value }));
  }, [dispatch]);

  useEffect(() => {
    setIsRefresh(false);
  }, [value]);

  const renderStartAdornment = useMemo(() => {
    if (_.isUndefined(error))
      return (
        <Box className={clsx(classes.startAdornment, 'center-root')}>
          <LinkIcon
            color={
              mode === 'dark'
                ? theme.colors.cultured
                : theme.colors.secondaryLight
            }
          />
        </Box>
      );
    if (!error)
      return (
        <Box className={clsx(classes.startAdornment, 'center-root')}>
          <CheckCircle color={theme.colors.primaryLight} />
        </Box>
      );
    return;
  }, [error, classes]);

  const clearForm = useCallback(() => {
    setLink?.('');
    dispatch(resetNFTPreviewAction());
    setIsRefresh(false);
    if (pasreLinkPress) pasreLinkPress(false);
  }, []);

  const renderEndAdornment = useMemo(
    () => (
      <>
        {error && (
          <Box
            mr={2}
            onClick={clearForm}
            style={{ cursor: 'pointer', color: '#ED5050' }}
          >
            <CloseIcon color="#ED5050" />
          </Box>
        )}
        <Button
          className={clsx('center-root', classes.endAdornment)}
          onClick={refresh ? onRefresh : onParseNFTLink}
        >
          {refresh ? (
            <RefreshIcon
              color={
                mode === 'dark' ? theme.colors.primaryDark : theme.colors.white
              }
            />
          ) : isRefresh ? (
            <RefreshIcon
              color={
                mode === 'dark' ? theme.colors.primaryDark : theme.colors.white
              }
            />
          ) : (
            <ArrowForward
              color={
                mode === 'dark' ? theme.colors.primaryDark : theme.colors.white
              }
            />
          )}
        </Button>
      </>
    ),
    [error, classes, isRefresh, onParseNFTLink, clearForm],
  );

  return (
    <OutlinedInput
      value={value}
      onChange={onChange}
      className={clsx(classes.root, className)}
      startAdornment={renderStartAdornment}
      endAdornment={noChange ? null : renderEndAdornment}
      classes={{
        root: classes.root,
        notchedOutline: borderNone
          ? classes.noneBorder
          : classes.notchedOutline,
        focused: classes.focused,
      }}
      placeholder={placeholder}
      disabled={disabled}
      onKeyDown={handlePressEnter}
    />
  );
};

export default ParseNFTInput;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    root: {
      height: 60,
      padding: 0,
      width: '100%',
      borderRadius: 0,
      marginBottom: 36,
      '& input': {
        height: 0,
        fontSize: 14,
      },
      '&$focused $notchedOutline': {
        borderWidth: 1,
        borderColor: isDarkMode ? theme.colors.primaryDark : theme.colors.white,
      },
      '& input::placeholder': {
        fontSize: 14,
        color: isDarkMode ? theme.colors.cultured : theme.colors.blackOlive,
        fontWeight: 500,
      },
    },
    startAdornment: {
      width: 76,
      height: '100%',
    },
    endAdornment: {
      width: 60,
      height: '100%',
      backgroundColor: isDarkMode
        ? theme.colors.white
        : theme.colors.secondaryLight,
      borderRadius: 0,
    },
    notchedOutline: {
      borderColor: `${
        isDarkMode ? theme.colors.borderDark : theme.colors.borderLight
      } !important`,
    },
    noneBorder: {
      border: 'none',
      outline: 'none',
    },
    focused: {
      borderColor: `yellow`,
    },
  };
});

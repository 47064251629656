import { createRoot } from 'react-dom/client';
import App from 'containers/App';
import Provider from './provider';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider>
    <App />
  </Provider>,
);

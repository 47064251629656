import { ReactElement, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Tooltip,
  ClickAwayListener,
} from '@material-ui/core';
import DateSelectComponent from 'components/common/DateSelectComponent';
import AccountBalanceWallet from 'icons/AccountBalanceWallet';
import InputNFTValue from 'components/AddNFT/InputNFTValue';
import EstimateInfo from 'components/AddNFT/EstimateInfo';
import { getMarket, getNFTEstimate } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetNftEstimateParams } from 'types/addNft';
import {
  deleteNFT,
  getNFTEstimateAction,
  resetNFTEstimateAciton,
  deleteCategoryInEstimateNFT,
} from 'store/actions/nftActions';
import { isNil } from 'lodash';
import {
  caculateTotalDays,
  convertNumberToOrdinal,
  getEnableConnectWallet,
  isStringNumber,
} from 'utils/validateAddNFT';
import { sTaskStatus, getUserState, getNFTPreview } from 'store/selectors';
import { AddNftActionTypeEnum } from 'enums/actions';
import { DEAD_ZONE_BLOCK_NUMBER } from 'common/constant';
import { numberWithCommas } from 'utils/formatNumber';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import ConnectWalletDialog from 'components/ConnectWallet';
import { useStyle } from './style';
import TextFieldMessageDialog from 'components/Dialog/TextFieldMessageDialog';
import TextLoadingMessageDialog from 'components/Dialog/TextLoadingMessageDialog';
import TextMessageDialog from 'components/Dialog/TextMessageDialog';
import TransactionFailedDialog from 'components/Dialog/TransactionFailedDialog';
import nftService from 'services/nfts';
import { ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { abi, walletAddress, mintedgemIDL } from 'services/contract';
import { CurrencyUnitEnum, InputEstimateParamsEnum } from 'enums/addNft';
import {
  getWalletAddress,
  getNFTValueBaseOnEth,
  convertTimeLeftToSecond,
  switchNetwork,
} from 'utils/wallet';
import Position from '../Position';

import miniumPriceService from 'services/miniumPrice';
import { MiniumPricePerDayResponse } from 'types/miniumPricePerDay';
import WarningAddNftDialog from 'components/Dialog/WarningAddNftDialog';
import WarningDialog from 'components/Dialog/WarningDialog';
import { MAX_NOT_DEAD_ZONE_POSITION, MIN_PRICE_PER_DAY } from 'common/constant';
import {
  useAnchorWallet,
  useConnection,
  useWallet,
} from '@solana/wallet-adapter-react';
import { WalletEnum } from 'enums/wallet';
import {
  AnchorProvider,
  BN,
  Program,
  Provider,
  setProvider,
  web3,
} from '@coral-xyz/anchor';
import { PublicKey, Transaction } from '@solana/web3.js';
import * as spl from '@solana/spl-token';
import { MintedgemIDL } from 'types/idl';
import ChangeWalletDialog from 'components/Dialog/ChangeWalletDialog';

interface EstimateProps {
  children: ReactElement;
  setEstimate: () => void;
  className?: string;
  isManual: boolean;
}

function Estimate(props: EstimateProps) {
  const { children, setEstimate, isManual } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md')) && !isDesktop;

  // Selectors
  const { user } = useSelector(getUserState);
  const market = useSelector(getMarket);
  const estimateStatus = useSelector(
    sTaskStatus(AddNftActionTypeEnum.GET_ESTIMATE),
  );
  const nftEstimate = useSelector(getNFTEstimate);
  const nftAddEstimate = useSelector(getNFTEstimate);
  const nftAdd = useSelector(getNFTPreview);

  //State
  const [tooltipMb, setTooltipMb] = useState<boolean>(false);
  const [minPrice, setMinPrice] = useState<
    MiniumPricePerDayResponse | undefined
  >();
  const [isCorrectNetwork, setCorrectNetwork] = useState<boolean>(false);
  const [isCorrectWallet, setCorrectWallet] = useState<boolean>(false);
  const [isLowPricePerDay, setIsLowPricePerDay] = useState<boolean>(false);
  const [program, setProgram] = useState<Program<MintedgemIDL>>();

  // Hooks
  const { active, library, chainId } = useWeb3React();
  const { publicKey, connected } = useWallet();
  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  // Memo

  const activeWallet = useMemo(() => {
    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      return connected;
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      return active;
    }
    return false;
  }, [active, connected, market]);

  const isLowerTotalDays = useMemo(() => {
    const { months, days, hours } = nftEstimate.params;
    return caculateTotalDays(Number(months), Number(days), Number(hours)) <
      0.2 / 24
      ? true
      : false;
  }, [nftEstimate]);

  const isLowerMinimumPrice = useMemo(() => {
    const { squarePrice } = nftEstimate.params;
    if (isNil(squarePrice)) {
      return false;
    } else if (squarePrice >= Number(minPrice?.value || 0.01)) {
      return false;
    } else {
      return true;
    }
  }, [nftEstimate, minPrice]);

  const enableConnectWallet = useMemo(() => {
    // Adding nft by FREE
    if (Number(minPrice?.value) === 0 && !isLowerTotalDays) return true; // check minPrice and isLowerTotalDays
    if (Number(minPrice?.value) < 0.1) {
      return true;
    }
    if (isLowerMinimumPrice) {
      return false;
    } else if (isLowPricePerDay) {
      return false;
    } else if (isLowerTotalDays) {
      return false;
    } else {
      return getEnableConnectWallet(nftEstimate) && !estimateStatus?.processing;
    }
  }, [
    nftEstimate,
    estimateStatus?.processing,
    isLowerMinimumPrice,
    minPrice?.value,
    isLowerTotalDays,
    isLowPricePerDay,
  ]);

  const pricePerDay = useMemo(() => {
    if (!isNil(nftEstimate.params.squarePrice)) {
      return `$${numberWithCommas(nftEstimate.params.squarePrice.toFixed(2))}`;
    }
    return '--';
  }, [nftEstimate]);

  const estimateInfo = useMemo(() => {
    const { percentile, blockNumber, positionWithinBlock, avgTime } =
      nftEstimate.estimateInfo;

    let percentileValue: string;
    let percentileDisplayValue: string;
    if (isNil(percentile)) {
      percentileValue = '--';
      percentileDisplayValue = '--';
    } else {
      percentileValue = `${percentile.toFixed(2)}%`;
      if (percentile < 1) percentileDisplayValue = '<1%';
      else if (percentile > 999) percentileDisplayValue = '>999%';
      else percentileDisplayValue = `${percentile.toFixed()}%`;
    }

    let isDeadZone = false;
    let blockNumberValue: string;
    if (isNil(blockNumber)) {
      blockNumberValue = '--';
    } else {
      if (blockNumber === DEAD_ZONE_BLOCK_NUMBER) {
        isDeadZone = true;
        blockNumberValue = 'DeadZone';
      } else {
        blockNumberValue = `${blockNumber}`;
      }
    }

    const positionWithinBlockValue = positionWithinBlock
      ? `${convertNumberToOrdinal(positionWithinBlock)}`
      : '--';

    let avgTimeDisplayValue: string;
    let avgTimeValue: string;
    if (isNil(avgTime)) {
      avgTimeValue = '--';
      avgTimeDisplayValue = '--';
    } else {
      avgTimeValue = `${avgTime.toFixed(2)}%`;
      if (avgTime > 999) avgTimeDisplayValue = '>999%';
      else if (avgTime < 1) avgTimeDisplayValue = '<1%';
      else avgTimeDisplayValue = `${avgTime.toFixed()}%`;
    }

    return [
      {
        label: 'Percentile',
        value: percentileValue,
        tooltip: 'Item can be found on the top x% of the 1001',
        displayValue: percentileDisplayValue,
      },
      {
        label: 'Block number',
        value: blockNumberValue,
        isDeadZone,
        tooltip: 'Item can be found in block number x/12',
      },
      {
        label: 'Block position',
        value: positionWithinBlockValue,
        tooltip: 'Item can be found in this position inside its block',
      },
      {
        label: 'Time vs Average',
        value: avgTimeValue,
        tooltip: "Item's initial time is x% of average active time",
        displayValue: avgTimeDisplayValue,
      },
    ];
  }, [nftEstimate]);

  const classes = useStyle({
    paymentStatus: enableConnectWallet,
    priceStatus: isLowPricePerDay,
  });

  const Estimate = useMemo(() => {
    if (isDesktop) {
      return (
        <div className={classes.estimateDeskop}>
          <EstimateInfo informations={estimateInfo} />
        </div>
      );
    }
    return null;
  }, [isDesktop, estimateInfo, classes]);

  // Fn callback

  const fetchMiniumPrice = async () => {
    try {
      const data = await miniumPriceService.GetMiniumPricePerDay({
        key: 'miniumPricePerday',
      });
      setMinPrice(data);
    } catch (error) {
      setMinPrice(undefined);
    }
  };

  const handleChangeTime = useCallback(
    (params: GetNftEstimateParams) => {
      dispatch(
        getNFTEstimateAction({
          ...params,
          changeUnit: false,
        }),
      );
      setIsLowPricePerDay(false);
    },
    [dispatch],
  );

  const handleChangePosition = useCallback(
    (category: string, position?: string) => {
      // let resPositionCategories = positionCategories;
      // const currentItem = positionCategories.find(cate => cate.name === category);
      // if(currentItem === undefined){
      //   resPositionCategories.push({name: category, position: position});
      // }
      // else{
      //   resPositionCategories = positionCategories.map(cate => cate.name === category ? {...cate, position: position} : cate);
      // }

      // setPositonCategories(resPositionCategories);

      dispatch(
        getNFTEstimateAction({
          category,
          position,
          lastEditInput: InputEstimateParamsEnum.POSITION,
          changeUnit: false,
          // positionCategories: resPositionCategories
        }),
      );
      setIsLowPricePerDay(false);
    },
    [dispatch],
  );

  const handleDelete = useCallback(
    (name: string) => {
      dispatch(deleteCategoryInEstimateNFT(name));
    },
    [dispatch],
  );

  const handleChangeNftValue = useCallback(
    (params: GetNftEstimateParams) => {
      dispatch(getNFTEstimateAction(params));
      setIsLowPricePerDay(false);
    },
    [dispatch],
  );

  const handleCloseNotification = useCallback(() => {
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
  }, [dispatch]);

  const openConnectWallet = useCallback(() => {
    dispatch(
      updateDialogStateAction({
        open: true,
        component: ConnectWalletDialog,
        props: {
          payment: true,
          isAdded: true,
        },
      }),
    );
  }, [dispatch]);

  const processPaymentEth = async (id: string, isCloneFromUser?: boolean) => {
    try {
      const value = ethers.utils.parseEther(
        getNFTValueBaseOnEth(nftAddEstimate),
      ); // string to wei

      const mintedGemcontract = new ethers.Contract(
        getWalletAddress(chainId) as any,
        abi as any,
        library.getSigner(),
      );
      await mintedGemcontract.createPayment(`${id}`, {
        value,
        gasLimit: 2100000,
        gasPrice: 8000000000,
      });

      await nftService.UpdateNFT(`${id}`, {
        isProcessPayment: false,
      });
    } catch (error) {
      console.log('e process: ', error);

      dispatch(
        deleteNFT(`${id}`, () => {
          console.log('delete nft id: ', id);
        }),
      );
      throw error;
    }
  };

  const processPaymentSol = async (
    id: string,
    amount: number,
    type: string,
  ) => {
    if (!program || !wallet || !publicKey) return;

    const programId = new PublicKey(
      process.env.REACT_APP_BASE_TESTNET_CONTRACT_ADDRESS as string,
    );

    const tokenMint = new PublicKey(
      process.env.REACT_APP_BASE_TOKEN_MINT as string,
    );

    const doneTokenMint = await spl.getMint(
      connection,
      new web3.PublicKey(tokenMint), // 9
    );

    const [senderTokenAccount] = web3.PublicKey.findProgramAddressSync(
      [
        wallet.publicKey.toBuffer(),
        spl.TOKEN_PROGRAM_ID.toBuffer(),
        doneTokenMint.address.toBuffer(),
      ],
      spl.ASSOCIATED_TOKEN_PROGRAM_ID,
    );

    const [transactionSolVolume] = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('transaction_sol_volume'), wallet.publicKey.toBuffer()],
      programId,
    );

    const [master] = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('master')],
      programId,
    );

    const [vaultSol] = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('vault_sol')],
      programId,
    );

    const [tokenAccountOwner] = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('token_account_owner')],
      programId,
    );

    const [vaultToken] = web3.PublicKey.findProgramAddressSync(
      [Buffer.from('vault_token'), doneTokenMint.address.toBuffer()],
      programId,
    );

    const [transactionDoneTokenVolume] = web3.PublicKey.findProgramAddressSync(
      [
        Buffer.from('transaction_done_token_volume'),
        wallet.publicKey.toBuffer(),
      ],
      programId,
    );

    const { blockhash, lastValidBlockHeight } =
      await program.provider.connection.getLatestBlockhash('confirmed');

    const transaction = new Transaction({
      blockhash,
      lastValidBlockHeight,
      feePayer: publicKey,
    });

    try {
      const initSenderAta = await program.methods
        .initSenderAta()
        .accounts({
          senderTokenAccount,
          signer: wallet.publicKey,
          mintOfTokenBeingSent: doneTokenMint.address,
          systemProgram: web3.SystemProgram.programId,
          tokenProgram: spl.TOKEN_PROGRAM_ID,
          associatedTokenProgram: spl.ASSOCIATED_TOKEN_PROGRAM_ID,
        })
        .instruction();

      const itemId = new BN(id);

      if (type === CurrencyUnitEnum.SOL) {
        // ===== 9.0 Init tx sol volume
        const initTxSolVolumeIx = await program.methods
          .initTxSolVolume()
          .accounts({
            transactionSolVolume,
            signer: wallet.publicKey,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();
        // ===== 9.1 Create payment by Sol
        const amountSolCreatePayment = new BN(amount * web3.LAMPORTS_PER_SOL);

        const [itemPayment] = web3.PublicKey.findProgramAddressSync(
          [Buffer.from('item_payment'), itemId.toArrayLike(Buffer, 'le', 8)],
          programId,
        );

        const createPaymentBySolIx = await program.methods
          .createPayment(itemId, amountSolCreatePayment)
          .accounts({
            master,
            itemPayment,
            transactionSolVolume,
            vaultSol,
            mintOfTokenBeingSent: doneTokenMint.address,
            tokenAccountOwnerPda: tokenAccountOwner,
            vaultToken,
            senderTokenAccount,
            signer: wallet.publicKey,
            systemProgram: web3.SystemProgram.programId,
            tokenProgram: spl.TOKEN_PROGRAM_ID,
            rent: web3.SYSVAR_RENT_PUBKEY,
          })
          .instruction();

        transaction.add(initSenderAta, initTxSolVolumeIx, createPaymentBySolIx);
      }

      if (type === CurrencyUnitEnum.DONE) {
        const initTxDoneTokenvolumeIx = await program.methods
          .initTxDoneTokenVolume()
          .accounts({
            transactionDoneTokenVolume,
            signer: wallet.publicKey,
            systemProgram: web3.SystemProgram.programId,
          })
          .instruction();
        // ===== 10.1 Create payment by Done Token
        const amountDoneTokenCreatePayment = new BN(
          amount * 10 ** doneTokenMint.decimals,
        );
        const [itemPaymentByDone] = web3.PublicKey.findProgramAddressSync(
          [
            Buffer.from('item_payment_by_done'),
            itemId.toArrayLike(Buffer, 'le', 8),
          ],
          programId,
        );
        const createPaymentByDoneTokenIx = await program.methods
          .createPaymentByDone(itemId, amountDoneTokenCreatePayment)
          .accounts({
            itemPayment: itemPaymentByDone,
            transactionDoneTokenVolume,
            mintOfTokenBeingSent: doneTokenMint.address,
            tokenAccountOwnerPda: tokenAccountOwner,
            vaultToken,
            senderTokenAccount,
            signer: wallet.publicKey,
            systemProgram: web3.SystemProgram.programId,
            tokenProgram: spl.TOKEN_PROGRAM_ID,
            rent: web3.SYSVAR_RENT_PUBKEY,
          })
          .instruction();

        transaction.add(
          initSenderAta,
          initTxDoneTokenvolumeIx,
          createPaymentByDoneTokenIx,
        );
      }

      const res = await program?.provider?.sendAndConfirm?.(transaction, [], {
        commitment: 'confirmed',
      });

      await nftService.UpdateNFT(`${id}`, {
        isProcessPayment: false,
      });

      console.log('=> tx: ', res);
    } catch (error) {
      console.log('e process: ', error);

      dispatch(
        deleteNFT(`${id}`, () => {
          console.log('delete nft id: ', id);
        }),
      );
      throw new Error();
    }
  };

  const onConfirmPayment = async () => {
    const { months, days, hours, nftValue, squarePrice } = nftEstimate.params;
    const categories = nftEstimate.categories;
    const isInDeadZonePosition = categories.some(
      (cat) => (cat?.position || 0) > MAX_NOT_DEAD_ZONE_POSITION,
    );

    const value: any = ethers.utils.parseEther(
      getNFTValueBaseOnEth(nftAddEstimate),
    );
    const receiveDone: any = (value * 99 * 100) / 10000; // receive 99% Done token

    const createNFT = async () => {
      try {
        if (!isCorrectWallet) {
          dispatch(
            updateDialogStateAction({
              open: true,
              component: ChangeWalletDialog,
            }),
          );
          return;
        }
        if (
          !isCorrectNetwork &&
          market &&
          ![WalletEnum.PHANTOM, WalletEnum.TIPLINK].includes(market)
        ) {
          await switchNetwork(nftAddEstimate, library);
          return;
        }
        const nftPreview = nftAdd;

        if (!nftPreview) return;
        dispatch(
          updateDialogStateAction({
            open: true,
            component: TextLoadingMessageDialog,
          }),
        );
        let data: any = {};
        if (!nftPreview.id) {
          // add new NFT
          const categoriesOnNft = nftAddEstimate.categories.map(
            (cat) => cat.name,
          );
          if (!isManual) {
            data = await nftService.AddNewNFT({
              tokenId: nftPreview.tokenId,
              tokenAddress: nftPreview.tokenAddress,
              chain: nftPreview.chain,
              marketplace: nftPreview.marketplace,
              isProcessPayment: true,
              time: convertTimeLeftToSecond(nftAddEstimate),
              categoriesOnNft,
            });
          }

          if (isManual) {
            data = await nftService.AddNewNFTManual({
              tokenId: nftPreview.tokenId,
              tokenAddress: nftPreview.tokenAddress,
              chain: nftPreview.chain,
              marketplace: nftPreview.marketplace,
              isProcessPayment: true,
              time: convertTimeLeftToSecond(nftAddEstimate),
              categoriesOnNft,
              amount: nftValue ?? '',
              symbol: nftPreview.chain,
              collectionName: nftPreview.collection.name,
              nftName: nftPreview.nftName,
              originalURL: nftPreview.originalUrl,
              price: nftPreview.price?.toString(),
              file: nftPreview.imageFile,
            });
          }
        } else {
          await nftService.UpdateNFT(`${nftPreview.id}`, {
            isProcessPayment: true,
            time: convertTimeLeftToSecond(nftAddEstimate),
          });
        }
        if (
          market === WalletEnum.META_MASK ||
          market === WalletEnum.COIN_BASE ||
          market === WalletEnum.WALLET_CONNECT
        ) {
          await processPaymentEth(
            data.id || nftPreview.id,
            data.isCloneFromUser || nftPreview.isCloneFromUser,
          );
        }

        if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
          await processPaymentSol(
            data.id || nftPreview.id,
            Number(nftValue),
            nftEstimate.params.nftUnit,
          );
        }
        dispatch(
          updateDialogStateAction({
            open: true,
            component: TextFieldMessageDialog,
            props: {
              imageUrl: nftPreview.imageUrl,
              metadata: nftPreview.metadata,
              isAdded: true,
              receiveDone: receiveDone,
            },
          }),
        );
      } catch (e: any) {
        console.log(e);

        if (e.response && e.response.data.key === 'CLONE_LIMIT_EXCEEDS') {
          console.log('this error if');

          dispatch(
            updateDialogStateAction({
              open: true,
              component: TextMessageDialog,
            }),
          );
        } else {
          console.log('this error else');
          dispatch(
            updateDialogStateAction({
              open: true,
              component: TransactionFailedDialog,
            }),
          );
        }
      }
    };

    const handleAcceptNotification = () => {
      dispatch(
        updateDialogStateAction({
          open: false,
        }),
      );
      return createNFT();
    };

    const handleAcceptWarning = () => {
      dispatch(
        getNFTEstimateAction({
          days: '14',
          hours: '0',
          months: '0',
          changeUnit: false,
        }),
      );
      dispatch(
        updateDialogStateAction({
          open: true,
          component: WarningDialog,
          props: {
            headerTitle: 'Warning: Square price per day too low',
            contentTitle:
              'Choose a square price per day of $0.00 or $0.01 and above.',
            textCloseButton: 'BACK',
            textAcceptButton: 'ACCEPT',
            onClose: handleCloseNotification,
            onAccept: handleAcceptWarningLowPricePerDay,
          },
        }),
      );
      return createNFT();
    };

    // show notification when square price per day is greater than $0 and less than $0.1
    if (
      !isNil(squarePrice) &&
      0 < squarePrice &&
      squarePrice < MIN_PRICE_PER_DAY
    ) {
      if (!estimateStatus?.processing) {
        dispatch(
          updateDialogStateAction({
            open: true,
            component: WarningDialog,
            props: {
              headerTitle: 'Warning: Square price per day too low',
              contentTitle:
                'Choose a square price per day of $0.00 or $0.01 and above.',
              textCloseButton: 'BACK',
              textAcceptButton: 'ACCEPT',
              onClose: handleCloseNotification,
              onAccept: handleAcceptWarning,
            },
          }),
        );
      }
      return;
    }

    // start process create NFT if minPrice < 0.01 and nftValue < 0.001
    if (
      (Number(nftValue) === 0 || Number(squarePrice) < 0.01) &&
      caculateTotalDays(Number(months), Number(days), Number(hours)) !== 14 // check estimate time > 14 days
    ) {
      dispatch(
        updateDialogStateAction({
          open: true,
          component: WarningAddNftDialog,
          props: {
            onClose: handleCloseNotification,
            // onAccept: handleAcceptWarning,
            receiveDone: receiveDone,
          },
        }),
      );
      return;
    } else if (isInDeadZonePosition) {
      dispatch(
        updateDialogStateAction({
          open: true,
          component: WarningDialog,
          props: {
            headerTitle: 'Warning: Cannot filter NFT in one or more categories',
            contentTitle:
              'Your NFT will not be filtered in each category while after 1001.',
            textCloseButton: 'BACK',
            textAcceptButton: 'ACCEPT & CONTINUE',
            onClose: handleCloseNotification,
            onAccept: handleAcceptNotification,
          },
        }),
      );
      return;
    } else {
      return createNFT();
    }
  };

  const handleAcceptWarningLowPricePerDay = useCallback(() => {
    // close notification and change nft value to ensure square price day is $0.1
    const { months, days, hours, nftUnit } = nftEstimate.params;
    const rate = nftEstimate.priceNFT[nftUnit];
    if (
      isStringNumber(months) &&
      isStringNumber(days) &&
      isStringNumber(hours) &&
      !isNil(rate)
    ) {
      const totalDays = caculateTotalDays(
        Number(months!),
        Number(days!),
        Number(hours!),
      );
      const expectedSquarePricePerDay = 0.01;
      const nftValue = Number(
        ((expectedSquarePricePerDay * totalDays) / rate + 0.000001).toFixed(6),
      );
      dispatch(
        getNFTEstimateAction({
          nftValue: nftValue.toString(),
          lastEditInput: InputEstimateParamsEnum.NFT_VALUE,
          changeUnit: false,
        }),
      );
      setIsLowPricePerDay(false);
    }

    handleCloseNotification();
  }, [
    nftEstimate.params,
    nftEstimate.priceNFT,
    dispatch,
    handleCloseNotification,
  ]);

  // Effect
  useEffect(() => {
    const nftEstimate = nftAddEstimate;
    const wallet = walletAddress.find(
      (i) => i.chainName === nftEstimate.params.nftUnit,
    );
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      const checkWallet = [
        CurrencyUnitEnum.ETH,
        CurrencyUnitEnum.BNB,
        CurrencyUnitEnum.AVAX,
        CurrencyUnitEnum.FTM,
        CurrencyUnitEnum.MATIC,
      ].includes(nftEstimate.params.nftUnit);
      setCorrectWallet(checkWallet);
    }

    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      const checkWallet = [
        CurrencyUnitEnum.SOL,
        CurrencyUnitEnum.DONE,
      ].includes(nftEstimate.params.nftUnit);
      setCorrectWallet(checkWallet);
    }

    if (wallet?.id !== chainId) {
      setCorrectNetwork(false);
    } else {
      setCorrectNetwork(true);
    }
  }, [nftAddEstimate, chainId, market]);

  useEffect(() => {
    fetchMiniumPrice();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetNFTEstimateAciton());
    };
  }, [dispatch]);

  useEffect(() => {
    const { squarePrice } = nftEstimate.params;
    if (
      !isNil(squarePrice) &&
      0 < squarePrice &&
      squarePrice < MIN_PRICE_PER_DAY
    )
      setIsLowPricePerDay(true);
  }, [nftEstimate.params]);

  useEffect(() => {
    let provider: Provider;
    if (!wallet) return;

    provider = new AnchorProvider(connection, wallet, {});
    setProvider(provider);
    const contractAddress = walletAddress.find(
      (item) => item.chainName === CurrencyUnitEnum.DONE,
    );
    if (!contractAddress?.address) return;
    const programId = new PublicKey(contractAddress?.address);

    const program = new Program<MintedgemIDL>(
      mintedgemIDL,
      programId,
      provider,
    );

    setProgram(program);
  }, [connection, wallet]);

  return (
    <>
      <Grid container xs={12} className={classes.container}>
        <Grid xs={12} lg={5} item className={classes.left}>
          {children}
          {Estimate}
          {isTablet && (
            <>
              <Typography
                className={clsx(classes.contentFirst, {
                  [classes.contentFirstManual]: isManual,
                })}
              >
                Choose your display time and amount to pay
              </Typography>
              <Typography
                className={clsx(classes.titleTablet, classes.tipTitle, {
                  [classes.titleTabletManual]: isManual,
                })}
              >
                Useful tips
              </Typography>
              <Typography
                className={clsx(classes.contentSecond, {
                  [classes.contentSecondManual]: isManual,
                })}
              >
                Add NFTs for $0.00 a day for up to 90 days.
                <br />
                Place first in galleries based on ad price per day.
                <br />
                NFTs after 1001 behave the same but cannot be filtered.
              </Typography>
            </>
          )}
        </Grid>
        <Grid xs={12} lg={7} item className={classes.right} container>
          {isDesktop && (
            <Grid item className={classes.wrapper}>
              <Typography className={classes.contentFirst}>
                Choose your display time and amount to pay
              </Typography>
            </Grid>
          )}
          <Grid item className={classes.tipWrapper}>
            <Typography className={clsx(classes.title, classes.tipTitle)}>
              Useful tips
            </Typography>
            {!isDesktop && (
              <Typography className={classes.contentFirst}>
                {/* Choose the time and starting position for your square. */}
                Choose your display time and amount to pay
              </Typography>
            )}
            <Typography className={classes.contentSecond}>
              Add NFTs for $0.00 a day for up to 90 days.
              <br />
              Place first in galleries based on ad price per day.
              <br />
              NFTs after 1001 behave the same but cannot be filtered.
            </Typography>
          </Grid>
          <Grid item>
            {!isDesktop && (
              <Typography
                className={clsx(classes.title, classes.settingsTitle)}
              >
                Settings
              </Typography>
            )}
            <Box className={classes.dateSelect}>
              <DateSelectComponent
                months={nftEstimate.params.months}
                days={nftEstimate.params.days}
                hours={nftEstimate.params.hours}
                onChange={handleChangeTime}
                className={classes.dateBox}
              />
            </Box>
          </Grid>
          <Grid item className={classes.wrapper}>
            {isDesktop && (
              <Typography
                className={clsx(classes.title, classes.settingsTitle)}
              >
                Settings
              </Typography>
            )}

            <Box className={classes.priceDiv}>
              <InputNFTValue
                label="Amount"
                value={nftEstimate.params.nftValue}
                placeholder="0"
                unit={nftEstimate.params.nftUnit}
                onChange={handleChangeNftValue}
                className={classes.inputnftvalue}
              />
              <Box className={classes.wrapPricePerday}>
                <Typography className={classes.label}>Price per day</Typography>
                {isDesktop ? (
                  <Box className={classes.priceBox}>
                    <Tooltip
                      title={
                        nftEstimate.params.squarePrice
                          ? `$${nftEstimate.params.squarePrice?.toFixed(3)}`
                          : '--'
                      }
                    >
                      <Typography className={classes.price}>
                        {pricePerDay}
                      </Typography>
                    </Tooltip>
                    <Typography className={classes.miniumPrice}>
                      Minimum day price $
                      {Number(minPrice?.value)?.toFixed(2) || '0.01'}
                    </Typography>
                  </Box>
                ) : (
                  <ClickAwayListener onClickAway={() => setTooltipMb(false)}>
                    <Box
                      className={classes.priceBox}
                      onClick={() => setTooltipMb(!tooltipMb)}
                    >
                      <Tooltip
                        title={
                          nftEstimate.params.squarePrice
                            ? `$${nftEstimate.params.squarePrice?.toFixed(3)}`
                            : '--'
                        }
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        open={tooltipMb}
                      >
                        <Typography className={classes.price}>
                          {pricePerDay}
                        </Typography>
                      </Tooltip>
                      <Typography className={classes.miniumPrice}>
                        Minimum day price $
                        {Number(minPrice?.value)?.toFixed(2) || '0.01'}
                      </Typography>
                    </Box>
                  </ClickAwayListener>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid className={clsx(classes.wrapper, classes.estimateWrapper)} item>
            {isDesktop && (
              <Typography
                className={clsx(classes.title, classes.estimateTitle)}
              >
                Position
              </Typography>
            )}
            <Grid container className={classes.estimateBlock}>
              {!isDesktop && (
                <div className={classes.estimateTablet}>
                  <Typography className={classes.title}>Estimates</Typography>
                  <EstimateInfo informations={estimateInfo} />
                </div>
              )}
              {nftAddEstimate.categories &&
                nftAddEstimate.categories.length > 0 && (
                  <Position
                    categories={nftAddEstimate.categories}
                    onChange={handleChangePosition}
                    onDelete={handleDelete}
                  />
                )}
              <Typography className={classes.service}>
                By CONFIRMING PAYMENT I agree to 1001 Squares of NFT{' '}
                <a href="/terms-of-service" target="_blank">
                  <span className={classes.serviceLink}>Terms of Service.</span>
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.confirm} item>
            <Button className={classes.cancelButton} onClick={setEstimate}>
              <Typography>CANCEL</Typography>
            </Button>
            <Button
              disabled={!enableConnectWallet}
              onClick={user ? onConfirmPayment : openConnectWallet}
              className={clsx(classes.confirmButton, {
                [classes.confirmButtonDisabled]: !enableConnectWallet,
              })}
            >
              <AccountBalanceWallet />
              <Typography>
                {user && activeWallet ? 'CONFIRM PAYMENT' : 'CONNECT WALLET'}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Estimate;
